import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  Header,
  Dimmer,
  Divider,
  Loader,
  Card,
  List,
  Icon,
  Segment,
  Image
} from 'semantic-ui-react'
import { pickProtocol, getProtocol, getMyAnalyses } from '../services/Api'
import { withPolyglot } from 'polyglot-react'
import { isEmpty } from 'lodash'

import AppError from '../components/AppError'

class UserProtocol extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      protocol: {}
    }

    //		this.refreshAfterNewPerf=this.refreshAfterNewPerf.bind(this)
  }
  async componentDidMount() {
    var myAnalysis = await this.call_getMyAnalyses()
    var type = 'neutral'
    if (myAnalysis && myAnalysis.analysis && myAnalysis.analysis.focus) {
      type = myAnalysis.analysis.focus
    }

    this.call_pickProtocol(type)
  }
  async call_getMyAnalyses() {
    let response = await getMyAnalyses()
    if (response.ok) {
      return {
        analysis: response.data[0]
      }
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
    }
  }
  call_pickProtocol = async type => {
    const protocolId = this.props.match.params.id
    let response

    if (protocolId) {
      //protocol already chosen display it
      response = await getProtocol(protocolId)
    } else {
      //no defined protocol, pick one
      response = await pickProtocol(type)
    }

    if (response.ok && !isEmpty(response.data)) {
      let data = response.data
      this.props.history.replace('/userProtocol/' + data.id)
      this.setState({
        protocol: data,
        isLoading: false,
        hasError: false
      })
      // store the new state object in the component's state

      return false
    } else {
      // store the new state object in the component's state
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
      return false
    }
  }

  render() {
    const { polyglot } = this.props

    return (
      <Fragment>
        <Dimmer active={this.state.isLoading}>
          <Loader />
        </Dimmer>
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError && (
          <div className="page ">
            <Container text>
              <style>{`
							.next-class .item .content .header{
							border-bottom : 1px solid #636363;
						}
						.next-class .item .content .description{
							padding-left:5px;
						}
						.segments{
							background: rgba(0, 0, 0, 0.6) !important;
						}	
					.page	.segments .segment {
						color: #ababab;
						 border-top: 1px solid #3a3939 !important;
						 border-right: 1px solid #3a3939 !important;
						 border-left: 1px solid #3a3939 !important;
					 }
					 .page	.segments .segment a{
						color: #ababab;
					 }
					 			
					.page	.ui.avatar.image {
						width: 3.5em;
						height: 3.5em;
						margin-top: 0.2rem;
						margin-bottom: 0.2rem;
					}

					 .segments .segment:last-child 					{
						 border-bottom: 1px solid #3a3939 !important;
					 }
						`}</style>
              <Header
                as="h2"
                className="color-white center-text uppercase  top-10"
              >
                {polyglot.t('protocol.focus')} : {this.state.protocol.type}
              </Header>
              <Divider />
              <div className="next-class pad-bottom-10">
                <Card.Content className="bottom-20">
                  <Card.Meta
                    className=" pad-bottom-10"
                    style={{ clear: 'both' }}
                  >
                    <h4 style={{ textTransform: 'capitalize' }}>
                      {polyglot.t('protocol.warmup')}
                    </h4>
                  </Card.Meta>

                  <Card.Description style={{ textAlign: 'left' }}>
                    {this.state.protocol.warmupDrills && (
                      <Segment.Group>
                        <Segment basic>
                          <List inverted relaxed>
                            {this.state.protocol.warmupDrills.map(drill => (
                              <List.Item key={drill._id}>
                                <Link
                                  to={{
                                    pathname: '/drills/' + drill._id,
                                    state: {
                                      drills: this.state.protocol.warmupDrills,
                                      trainingMode: true
                                    }
                                  }}
                                >
                                  <div>
                                    <Icon
                                      name="chevron right"
                                      size="small"
                                      className="floated right color-grey top-20"
                                    />
                                    {drill.image ? (
                                      <Image
                                        src={'/drills/' + drill.image}
                                        avatar
                                      />
                                    ) : (
                                      <Image
                                        src={'/icons/icon-72x72.png'}
                                        avatar
                                      />
                                    )}

                                    {drill.name}
                                  </div>
                                </Link>
                              </List.Item>
                            ))}
                          </List>
                        </Segment>
                      </Segment.Group>
                    )}
                  </Card.Description>
                </Card.Content>
                <Card.Content className="bottom-20">
                  <Card.Meta
                    className=" pad-bottom-10"
                    style={{ clear: 'both' }}
                  >
                    <h4 style={{ textTransform: 'capitalize' }}>
                      {polyglot.t('protocol.drills')}
                    </h4>
                  </Card.Meta>

                  <Card.Description style={{ textAlign: 'left' }}>
                    {this.state.protocol.drills && (
                      <Segment.Group>
                        <Segment basic>
                          <List inverted relaxed>
                            {this.state.protocol.drills.map(drill => (
                              <List.Item key={drill._id}>
                                <Link
                                  to={{
                                    pathname: '/drills/' + drill._id,
                                    state: {
                                      drills: this.state.protocol.drills,
                                      trainingMode: true
                                    }
                                  }}
                                >
                                  <div>
                                    <Icon
                                      name="chevron right"
                                      size="small"
                                      className="floated right color-grey top-20"
                                    />
                                    {drill.image ? (
                                      <Image
                                        src={'/drills/' + drill.image}
                                        avatar
                                      />
                                    ) : (
                                      <Image
                                        src={'/icons/icon-72x72.png'}
                                        avatar
                                      />
                                    )}

                                    {drill.name}
                                  </div>
                                </Link>
                              </List.Item>
                            ))}
                          </List>
                        </Segment>
                      </Segment.Group>
                    )}
                  </Card.Description>
                </Card.Content>
              </div>
            </Container>
          </div>
        )}
      </Fragment>
    )
  }
}

UserProtocol = withPolyglot()(UserProtocol)
export default UserProtocol
