import React, { Component } from 'react'
import { Header, Container, Card, Divider } from 'semantic-ui-react'
import { withPolyglot } from 'polyglot-react'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import SwipeableViews from 'react-swipeable-views'
import UserAnalysis from '../components/UserAnalysis'

import AppError from '../components/AppError'
import { getMyAnalyses } from '../services/Api'

class UserAnalyses extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      analyses: [],
      hasError: false,
      error: false,
      isLoading: true,
      activeIndex: 0
    }
  }
  componentDidMount() {
    this.call_getMyAnalyses()
  }
  call_getMyAnalyses() {
    return getMyAnalyses()
      .then(response => {
        if (response.ok) {
          const newState = Object.assign({}, this.state, {
            analyses: response.data,
            hasError: false,
            isLoading: false
          })
          this.setState(newState)
        }
      })
      .catch(err => {
        const newState = Object.assign({}, this.state, {
          hasError: true,
          isLoading: false,
          error: err
        })
        // store the new state object in the component's state
        this.setState(newState)
      })
  }
  handleChangeIndex = activeIndex => {
    this.setState({
      activeIndex
    })
  }
  nextView = e => {
    var { activeIndex = 0 } = this.state
    this.setState({ activeIndex: activeIndex + 1 })
  }
  prevView = e => {
    var { activeIndex = 0 } = this.state
    this.setState({ activeIndex: activeIndex - 1 })
  }
  render() {
    const { polyglot } = this.props
    const { activeIndex } = this.state
    const analysesList = this.state ? this.state.analyses : []

    var swipeableElements = []

    var showLeftIcon = index => {
      return index !== 0
    }
    var showRightIcon = index => {
      return index < analysesList.length - 1
    }

    for (let index = 0; index < analysesList.length; index++) {
      const element = analysesList[index]

      swipeableElements.push(
        <UserAnalysis
          {...element}
          leftIcon={{ show: showLeftIcon(index), action: this.prevView }}
          rightIcon={{ show: showRightIcon(index), action: this.nextView }}
        />
      )
    }

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div className="page ">
        <Container text>
          <style>{`
							.next-class .item .content .header{
							border-bottom : 1px solid #636363;
						}
						`}</style>
          <Header as="h2" className="color-white center-text uppercase  top-10">
            {polyglot.t('home.technique.analysis')}
          </Header>
          <Divider />

          {Array.isArray(this.state.analyses) && this.state.analyses.length ? (
            <SwipeableViews
              enableMouseEvents={true}
              index={activeIndex}
              onChangeIndex={this.handleChangeIndex}
              disabled={analysesList.length === 0}
              children={swipeableElements}
            ></SwipeableViews>
          ) : (
            <Card className={'card-dark'} style={{ margin: 'auto' }}>
              <Card.Content>
                {polyglot.t('home.technique.analysis.none')}
              </Card.Content>
            </Card>
          )}
        </Container>
      </div>
    )
  }
}

UserAnalyses = withPolyglot()(UserAnalyses)
export default UserAnalyses
