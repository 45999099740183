import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import {
  Message,
  Feed,
  Label,
  Container,
  Icon,
  Segment,
  Placeholder,
  Header
} from 'semantic-ui-react'
import { times } from 'lodash'
import { withPolyglot } from 'polyglot-react'
import moment from 'moment'
import ReactGA from 'react-ga'
import { getMeCourses } from '../services/Api'
import SwipeableViews from 'react-swipeable-views'
import AppError from '../components/AppError'
import nearest from 'nearest-date'

class userTrainings extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      trainingsCount: 0,
      trainings: [],
      trainingsByMonth: [],
      openedId: false,
      activeIndex: 0
    }
  }
  componentDidMount() {
    this.call_getMeCourses()
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  groupByMonth(trainingList) {
    var byMonth = {}
    for (let i = 0; i < trainingList.length; i++) {
      const element = trainingList[i]
      var tdate = element.date
      var mstart = moment(tdate)
        .startOf('month')
        .format('YYYY-MM')
        .toString()
      if (byMonth[mstart]) {
        byMonth[mstart].push(element)
      } else {
        byMonth[mstart] = [element]
      }
    }
    return byMonth
  }

  async call_getMeCourses() {
    const response = await getMeCourses()
    if (response.ok) {
      const data = response.data.rows

      //sort by date
      data.sort((a, b) => new Date(a.date) - new Date(b.date))
      var byMonth = this.groupByMonth(data)
      var byMonthKeys = Object.keys(byMonth)
      var byMonthKeysDate = byMonthKeys.map(x => new Date(x))
      var index = nearest(byMonthKeysDate)

      this.setState({
        trainingsCount: response.data.count,
        trainings: data,
        trainingsByMonth: byMonth,
        isLoading: false,
        hasError: false,
        activeIndex: index
      })
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
    }
  }
  handleChangeIndex = activeIndex => {
    this.setState({
      activeIndex
    })
  }
  nextView = e => {
    var { activeIndex = 0 } = this.state
    this.setState({ activeIndex: activeIndex + 1 })
  }
  prevView = e => {
    var { activeIndex = 0 } = this.state
    this.setState({ activeIndex: activeIndex - 1 })
  }
  render() {
    const { polyglot } = this.props
    const { activeIndex } = this.state
    const trainingsByMonth = this.state ? this.state.trainingsByMonth : []

    var swipeableHeader = []
    var swipeableContent = []
    const iconClassLeft = 'floated left color-grey top-10'
    const iconClassRight = 'floated right color-grey top-10'
    const transparentClassLeft = 'floated left  top-10 transparent'
    const transparentClassRight = 'floated right  top-10 transparent'
    const slidesMaxIndex = Object.keys(trainingsByMonth).length
    var showLeftIcon = () => {
      return activeIndex !== 0
    }
    var showRightIcon = () => {
      return activeIndex < slidesMaxIndex - 1
    }

    for (let index = 0; index < Object.keys(trainingsByMonth).length; index++) {
      const monthStart = Object.keys(trainingsByMonth)[index]
      const element = trainingsByMonth[monthStart]

      let label = moment(monthStart).format('MMM YYYY')
      swipeableHeader.push(
        <div key={label}>
          <Icon
            name="chevron left"
            size="large"
            className={showLeftIcon() ? iconClassLeft : transparentClassLeft}
            onClick={showLeftIcon() ? this.prevView : () => {}}
          />

          <Icon
            name="chevron right"
            size="large"
            className={showRightIcon() ? iconClassRight : transparentClassRight}
            onClick={showRightIcon() ? this.nextView : () => {}}
          />
          <Header as="h3" textAlign="center" className="bottom-10">
            {label}
          </Header>
        </div>
      )

      swipeableContent.push(
        <Container style={{ padding: '0 5px', minWidth: '350px' }}>
          <Feed>
            {element.map(x => (
              <Feed.Event key={x.id} style={{ paddingBottom: '10px' }}>
                <Feed.Label>
                  <Label circular color="black">
                    {moment(x.date).format('ddd DD')}
                  </Label>
                </Feed.Label>
                <Feed.Content>
                  <Link to={'/userTraining/' + x.id}>{x.label}</Link>
                </Feed.Content>
              </Feed.Event>
            ))}
          </Feed>
        </Container>
      )
    }

    return (
      <Fragment>
        <style>{`
				.ui.circular.black.label{
					border:  2px solid grey !important;
						width: 3.5em;
						height: 3.5em;
				}
						`}</style>
        {this.state.isLoading && (
          <Container
            text
            style={{ marginTop: '130px', height: '100%', textAlign: 'center' }}
          >
            <Segment inverted>
              <Placeholder inverted fluid>
                {times(5, String).map((item, i) => {
                  return (
                    <Placeholder.Header image key={i}>
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder.Header>
                  )
                })}
              </Placeholder>
            </Segment>
          </Container>
        )}
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError && !this.state.isLoading && (
          <div className="user-trainings page">
            <Container text style={{ height: '100%', textAlign: 'left' }}>
              {this.state.trainingsCount ? (
                <Fragment>
                  <SwipeableViews
                    enableMouseEvents={true}
                    index={activeIndex}
                    onChangeIndex={this.handleChangeIndex}
                    disabled={trainingsByMonth.length === 0}
                    children={swipeableHeader}
                  ></SwipeableViews>

                  <SwipeableViews
                    enableMouseEvents={true}
                    index={activeIndex}
                    onChangeIndex={this.handleChangeIndex}
                    disabled={trainingsByMonth.length === 0}
                    children={swipeableContent}
                  ></SwipeableViews>
                </Fragment>
              ) : (
                <Fragment>
                  <Message color="black">
                    {polyglot.t('usertrainings.none')}
                  </Message>
                </Fragment>
              )}
            </Container>
          </div>
        )}
      </Fragment>
    )
  }
}

userTrainings = withPolyglot()(userTrainings)
export default userTrainings
