import React, { Component, Fragment } from 'react'
import {
  Icon,
  Modal,
  Divider,
  Embed,
  Container,
  Label,
  Transition
} from 'semantic-ui-react'
import { withPolyglot } from 'polyglot-react'
import { Bar } from 'react-chartjs-2'
import 'react-toastify/dist/ReactToastify.min.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
class ComputedAnalysis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAnimated: false,
      totalScore: 0,
      showAnalysis: false
    }
  }
  poseScore = () => {
    const maxScore = 3 + 3 + 3
    const minScore = 3

    let score =
      (((this.props.poseExecutionLanding
        ? parseInt(this.props.poseExecutionLanding)
        : 1) +
        (this.props.poseExecutionKnee
          ? parseInt(this.props.poseExecutionKnee)
          : 1) +
        (this.props.poseExecutionVertical
          ? parseInt(this.props.poseExecutionVertical)
          : 1) -
        minScore) *
        100) /
      (maxScore - minScore)

    score = score === 0 ? 5 : Math.round(score)
    return score
  }
  fallScore = () => {
    const maxScore = 3 /*+3 */ + 5
    const minScore = 1 //2
    const framesFallingScore = this.framesFallingScore()
    let score =
      (((this.props.fallExecutionAlignment
        ? parseInt(this.props.fallExecutionAlignment)
        : 1) +
        /*
        (this.props.fallExecutionTime
          ? parseInt(this.props.fallExecutionTime)
          : 1) +*/
        (framesFallingScore ? parseInt(framesFallingScore) : 0) -
        minScore) *
        100) /
      (maxScore - minScore)
    score = score === 0 ? 5 : Math.round(score) //juste pour ne pas avoir un score null
    return score
  }

  framesFallingScore = () => {
    let score = 0
    if (!this.props.framesFromPose && !this.props.framesFalling) return score
    let count = this.props.framesFromPose - this.props.framesFalling

    if (this.props.fps === 30) {
      count = count * 2
    }
    if (this.props.fps === 120) {
      count = count / 2
    }

    //for 60fps standard
    if (count === 0) {
      score = 5
    }
    if (count === 1) {
      score = 4
    }
    if (count === 2) {
      score = 3
    }
    if (count === 3) {
      score = 2
    }
    if (count === 4) {
      score = 1
    }
    if (count >= 5) {
      score = 0
    }

    return score
  }
  pullScore = () => {
    const maxScore = 3 + 3 + 3 + 5
    const minScore = 3

    const framesToPoseScore = this.framesToPoseScore()

    let score =
      (((this.props.pullExecutionTiming
        ? parseInt(this.props.pullExecutionTiming)
        : 1) +
        (this.props.pullExecutionLegBent
          ? parseInt(this.props.pullExecutionLegBent)
          : 1) +
        (this.props.pullExecutionDirection
          ? parseInt(this.props.pullExecutionDirection)
          : 1) +
        (framesToPoseScore ? parseInt(framesToPoseScore) : 0) -
        minScore) *
        100) /
      (maxScore - minScore)

    score = score === 0 ? 5 : Math.round(score)

    return score
  }
  framesToPoseScore = () => {
    let score = 0
    let count = this.props.framesToPose

    if (this.props.fps === 30) {
      count = count * 2
    }
    if (this.props.fps === 120) {
      count = count / 2
    }
    /*
    //for 60fps standard
    if (count >= 3) {
      score = 2
    }
    if (count >= 5) {
      score = 1
    }
    if (count >= 7) {
      score = 0
    }
*/
    //for 60fps standard
    if (count <= 2) {
      score = 5
    }
    if (count === 3) {
      score = 4
    }
    if (count === 4) {
      score = 3
    }
    if (count === 5) {
      score = 2
    }
    if (count === 6) {
      score = 1
    }
    if (count >= 7) {
      score = 0
    }

    return score
  }
  totalScore = () => {
    return (this.poseScore() + this.fallScore() + this.pullScore()) / 3
  }
  roundTo5 = score => Math.round(score / 5) * 5 //multiple de 5

  getColor = x => {
    var color = 'rgba(0, 128, 0, 0.7)' //'green'
    if (x <= 90) {
      color = 'rgba(64, 128, 0, 0.7)' //'green'
    }
    if (x <= 75) {
      color = 'rgba(128, 128, 0, 0.7)' //'olive'
      //color = 'rgba( 251, 189, 8, 0.7)' //'yellow'
    }
    if (x <= 50) {
      color = 'rgba(255, 165, 0, 0.7)' //'orange'
    }
    if (x <= 25) {
      color = 'rgba(255, 0, 0, 0.7)' //'red'
    }

    return color
  }

  frameCountColor = count => {
    var color
    if (this.props.fps === 60) {
      if (count >= 7) {
        color = 'red'
      } else if (count >= 5) {
        color = 'orange'
      } else if (count >= 3) {
        color = 'yellow'
      } else {
        color = 'green'
      }
      return color
    } else {
      return '#fff'
    }
  }
  animateTotalScore = () => {
    let score = this.totalScore()
    // window.setTimeout(() => this.setState({ totalScore: score }), 1 * 1000)
    this.setState({ totalScore: score })
  }
  componentDidMount() {}

  toggleDisplayAnalysis = () => {
    this.setState({ showAnalysis: !this.state.showAnalysis })
  }

  render() {
    const { polyglot, displayAnalysis } = this.props
    const totalScore = this.roundTo5(this.state.totalScore)
    const { leftIcon, rightIcon } = this.props
    const iconClassLeft = 'floated left color-grey top-30'
    const iconClassRight = 'floated right color-grey top-30'
    const transparentClassLeft = 'floated left  top-10 transparent'
    const transparentClassRight = 'floated right  top-10 transparent'
    const showLeftIcon = leftIcon ? leftIcon.show : false
    const showRightIcon = rightIcon ? rightIcon.show : false
    const prevView = () => {
      if (leftIcon) {
        leftIcon.action()
      } else {
        return false
      }
    }
    const nextView = () => {
      if (rightIcon) {
        rightIcon.action()
      } else {
        return false
      }
    }
    const frameCount = () => {
      return (
        <Fragment>
          <style>{`
	
						.framecount-color-red{
							color:red;
						}
						.framecount-color-orange{
							color:#f2711c
						}
						.framecount-color-yellow{
							color:#fbbd08;
						}
						.framecount-color-green{
							color:green;
						}
	
						`}</style>

          <div className={'color-white bottom-10'} style={{ fontSize: '2rem' }}>
            <span
              className={
                'framecount-color-' +
                this.frameCountColor(this.props.framesToPose)
              }
            >
              {this.props.framesToPose}{' '}
            </span>{' '}
            :{' '}
            <span
              className={
                'framecount-color-' +
                this.frameCountColor(this.props.framesFromPose)
              }
            >
              {this.props.framesFromPose}{' '}
            </span>
          </div>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <Icon
          name="chevron left"
          size="large"
          className={showLeftIcon ? iconClassLeft : transparentClassLeft}
          onClick={prevView}
        />
        <Icon
          name="chevron right"
          size="large"
          className={showRightIcon ? iconClassRight : transparentClassRight}
          onClick={nextView}
        />
        <style>{`
            .user-list{text-align:left }
            .riek {
              display: block;
              width: 100%;
              min-height: 12px;
            }
            textarea.riek {
              min-height: 200px;
            }
            .number{
              width:10px;
            }
            .fluid .ui.card { width:100% }
            .color1,.color0{
							color:red;
							float:right
            }
            .color2{
              color:#fbbd08;
							float:right
            }
            .color3{
              color:green;
							float:right
            }
            .execution-block
            {
              text-align:left;
							margin-left:15px;
							margin:0 auto;
							width:250px
						}
						.criteria{
							margin-top:5px;
						}
						.execution-block .label {
							display:inline-block;
							width:150px;
						color:grey
						}
						.ui.progress
							{background: #bcbdbd59 !important;}

						`}</style>

        <Transition
          visible={!this.state.showAnalysis}
          animation="fade down"
          duration={{ hide: 0, show: 300 }}
        >
          <Container>
            <div style={{ margin: '0 auto', width: '175px' }}>
              <CircularProgressbar
                value={totalScore}
                text={`${totalScore}%`}
                circleRatio={0.75}
                styles={buildStyles({
                  rotation: 1 / 2 + 1 / 8,
                  trailColor: 'rgba(75, 74, 74, 0.32)',
                  textColor: '#a1a1a1',
                  pathColor: this.getColor(totalScore)
                })}
              />
              <div
                style={{
                  color: 'grey',
                  textTransform: 'uppercase',
                  marginTop: '-10px'
                }}
              >
                {polyglot.t('analysis.technique.score')}
              </div>
            </div>
            <div className={'top-20'}>
              <span
                style={{
                  width: '33%',
                  display: 'inline-block',
                  color: 'grey'
                }}
              >
                {this.roundTo5(this.poseScore())} {'%'}
              </span>
              <span
                style={{
                  width: '33%',
                  display: 'inline-block',
                  color: 'grey'
                }}
              >
                {this.roundTo5(this.fallScore())}
                {'%'}
              </span>
              <span
                style={{
                  width: '33%',
                  display: 'inline-block',
                  color: 'grey'
                }}
              >
                {this.roundTo5(this.pullScore())} {'%'}
              </span>
            </div>
            <Bar
              data={{
                labels: ['Pose', 'Fall', 'Pull'],
                datasets: [
                  {
                    //label: 'Progress',

                    backgroundColor: [
                      this.getColor(this.poseScore()),
                      this.getColor(this.fallScore()),
                      this.getColor(this.pullScore())
                    ],

                    data: [
                      this.roundTo5(this.poseScore()),
                      this.roundTo5(this.fallScore()),
                      this.roundTo5(this.pullScore())
                    ]
                  }
                ]
              }}
              legend={{
                display: false
              }}
              options={{
                scales: {
                  xAxes: [
                    {
                      // display: false,
                      gridLines: {
                        // color: 'rgba(171,171,171,0.2)',
                        color: 'transparent',
                        tickMarkLength: 2,
                        zeroLineWidth: 0
                        // drawBorder: false
                      },
                      ticks: {
                        padding: 5,
                        fontSize: 16,
                        fontColor: '#fff'
                      }
                    }
                  ],
                  yAxes: [
                    {
                      display: false,

                      gridLines: {
                        color: 'rgba(171,171,171,0.2)',
                        // drawTicks: false,
                        tickMarkLength: 0,
                        drawBorder: false,
                        zeroLineWidth: 0
                      },

                      ticks: {
                        beginAtZero: true,
                        max: 100,
                        callback: function(value, index, values) {
                          return ''
                          /*
                          if (value % 20 === 0) {
                            return value + '%'
                          } else {
                            return ''
                          }*/
                        }
                      }
                    }
                  ]
                },
                tooltips: {
                  enabled: false
                  /*
              callbacks: {
                label: function(tooltipItem, data) {
                  var label =
                    data.datasets[tooltipItem.datasetIndex].label || ''

                  if (label) {
                    label += ': '
                  }
                  label += tooltipItem.yLabel + '%'
                  return label
								}
              }
								*/
                },
                animation: {
                  onComplete: animation => {
                    this.animateTotalScore()
                  }
                }
              }}
            />
          </Container>
        </Transition>

        <Container textAlign="center" className="top-10 bottom-10">
          <Label
            as="a"
            style={{
              backgroundImage:
                'linear-gradient(to bottom, #403e3e 0%, #0e0e0e 100%)',
              backgroundColor: 'transparent',
              color: '#fff'
            }}
            onClick={this.toggleDisplayAnalysis}
          >
            <Icon
              name={this.state.showAnalysis ? 'chart bar' : 'list ul'}
              size="big"
              inverted
            />
            <span style={{ fontWeight: 400 }}>
              {!this.state.showAnalysis &&
                polyglot.t('analysis.analysis.display')}
              {this.state.showAnalysis && polyglot.t('analysis.score.display')}
            </span>
          </Label>
        </Container>

        <Transition
          visible={this.state.showAnalysis}
          animation="fade up"
          duration={{ hide: 0, show: 300 }}
        >
          <Container>
            {displayAnalysis && (
              <Fragment>
                <Embed
                  id={this.props.urlCode}
                  placeholder={
                    'http://img.youtube.com/vi/' + this.props.urlCode + '/0.jpg'
                  }
                  brandedUI={true}
                  source="youtube"
                />
                {this.props.fps} {polyglot.t('analysis.fps')}
              </Fragment>
            )}
            <Modal
              trigger={
                <Icon
                  name="question circle outline"
                  color="grey"
                  size="large"
                  style={{ position: 'absolute', right: '60px' }}
                />
              }
              closeIcon
            >
              <Modal.Header>
                {' '}
                {polyglot.t('help.framecount.header')}
              </Modal.Header>
              <Modal.Content image scrolling>
                <Modal.Description style={{ color: 'rgba(0,0,0,.87)' }}>
                  <p> {polyglot.t('help.framecount.content')}</p>
                </Modal.Description>
              </Modal.Content>
            </Modal>
            {frameCount()}
            <div className="execution-block">
              <strong>{polyglot.t('analysis.execution.pose')} </strong>
              {this.props.poseExecutionLanding && (
                <div className="criteria">
                  <span className="label">
                    {polyglot.t('analysis.execution.poseExecutionLanding')}
                  </span>
                  <span
                    className={'color' + this.props.poseExecutionLanding}
                    style={{ textTransform: 'lowercase' }}
                  >
                    {/*
                {polyglot.t(
                  'analysis.execution.poseExecutionLanding.' +
                    this.props.poseExecutionLanding
								)}*/}{' '}
                    <Icon name="circle" />
                  </span>
                </div>
              )}
              {this.props.poseExecutionKnee && (
                <div className="criteria">
                  {' '}
                  <span className="label">
                    {polyglot.t('analysis.execution.poseExecutionKnee')}
                  </span>
                  <span
                    className={'color' + this.props.poseExecutionKnee}
                    style={{ textTransform: 'lowercase' }}
                  >
                    {/*
                {polyglot.t(
                  'analysis.execution.poseExecutionKnee.' +
                    this.props.poseExecutionKnee
								)}*/}{' '}
                    <Icon name="circle" />
                  </span>
                </div>
              )}
              {this.props.poseExecutionVertical && (
                <div className="criteria">
                  {' '}
                  <span className="label">
                    {polyglot.t('analysis.execution.poseExecutionVertical')}
                  </span>
                  <span
                    className={'color' + this.props.poseExecutionVertical}
                    style={{ textTransform: 'lowercase' }}
                  >
                    {/*
                {polyglot.t(
                  'analysis.execution.poseExecutionVertical.' +
                    this.props.poseExecutionVertical
								)}
								*/}{' '}
                    <Icon name="circle" />
                  </span>
                </div>
              )}
            </div>
            <br />
            <div className="execution-block">
              <strong>{polyglot.t('analysis.execution.fall')} </strong>
              {this.props.fallExecutionAlignment && (
                <div className="criteria">
                  {' '}
                  <span className="label">
                    {polyglot.t('analysis.execution.fallExecutionAlignment')}
                  </span>
                  <span
                    className={'color' + this.props.fallExecutionAlignment}
                    style={{ textTransform: 'lowercase' }}
                  >
                    {/*
                {polyglot.t(
                  'analysis.execution.fallExecutionAlignment.' +
                    this.props.fallExecutionAlignment
								)}*/}{' '}
                    <Icon name="circle" />
                  </span>
                </div>
              )}
              {this.props.fallExecutionTime && (
                <div className="criteria">
                  {' '}
                  <span className="label">
                    {polyglot.t('analysis.execution.fallExecutionTime')}
                  </span>
                  <span
                    className={'color' + this.props.fallExecutionTime}
                    style={{ textTransform: 'lowercase' }}
                  >
                    {/*   {polyglot.t(
                  'analysis.execution.fallExecutionTime.' +
                    this.props.fallExecutionTime
						)}*/}{' '}
                    <Icon name="circle" />
                  </span>
                </div>
              )}
            </div>
            <br />
            <div className="execution-block">
              <strong>{polyglot.t('analysis.execution.pull')} </strong>
              {this.props.pullExecutionTiming && (
                <div className="criteria">
                  {' '}
                  <span className="label">
                    {polyglot.t('analysis.execution.pullExecutionTiming')}
                  </span>
                  <span
                    className={'color' + this.props.pullExecutionTiming}
                    style={{ textTransform: 'lowercase' }}
                  >
                    {/*
                {polyglot.t(
                  'analysis.execution.pullExecutionTiming.' +
                    this.props.pullExecutionTiming
								)}*/}{' '}
                    <Icon name="circle" />
                  </span>
                </div>
              )}
              {this.props.pullExecutionLegBent && (
                <div className="criteria">
                  {' '}
                  <span className="label">
                    {polyglot.t('analysis.execution.pullExecutionLegBent')}
                  </span>
                  <span
                    className={'color' + this.props.pullExecutionLegBent}
                    style={{ textTransform: 'lowercase' }}
                  >
                    {/*
                {polyglot.t(
                  'analysis.execution.pullExecutionLegBent.' +
                    this.props.pullExecutionLegBent
								)} */}{' '}
                    <Icon name="circle" />
                  </span>
                </div>
              )}
              {this.props.pullExecutionDirection && (
                <div className="criteria">
                  {' '}
                  <span className="label">
                    {polyglot.t('analysis.execution.pullExecutionDirection')}
                  </span>
                  <span
                    className={'color' + this.props.pullExecutionDirection}
                    style={{ textTransform: 'lowercase' }}
                  >
                    {/* {polyglot.t(
                  'analysis.execution.pullExecutionDirection.' +
                    this.props.pullExecutionDirection
							 )}*/}{' '}
                    <Icon name="circle" />
                  </span>
                </div>
              )}
              {this.props.framesToPose && (
                <div className="criteria">
                  {' '}
                  <span className="label">
                    {polyglot.t('analysis.frameToPose')}
                  </span>
                  <span
                    className={'color' + this.framesToPoseScore()}
                    style={{ textTransform: 'lowercase' }}
                  >
                    {/* {polyglot.t(
                  'analysis.execution.pullExecutionDirection.' +
                    this.props.pullExecutionDirection
							 )}*/}{' '}
                    <Icon name="circle" />
                  </span>
                </div>
              )}

              <div className="criteria">
                {' '}
                <span className="label">
                  {polyglot.t('analysis.execution.pullBreakingAngle')}
                </span>
                <span
                  className={'color' + this.framesToPoseScore()}
                  style={{ textTransform: 'lowercase' }}
                >
                  {/* {polyglot.t(
                  'analysis.execution.pullExecutionDirection.' +
                    this.props.pullExecutionDirection
							 )}*/}{' '}
                  <Icon name="circle" />
                </span>
              </div>
            </div>
            <Divider />
            {this.props.displayAnalysis && (
              <div style={{ marginBottom: '10px', textAlign: 'left' }}>
                {this.props.analysis}
              </div>
            )}
          </Container>
        </Transition>
      </Fragment>
    )
  }
}

ComputedAnalysis = withPolyglot()(ComputedAnalysis)
export default ComputedAnalysis
