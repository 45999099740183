import React, { Component } from "react"
import { Grid, Message, Container, Button } from "semantic-ui-react"
import { getNotificationsSent } from "../services/Api"
import { withPolyglot } from "polyglot-react"
import moment from "moment"
import { Link } from "react-router-dom"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import BootstrapTable from "react-bootstrap-table-next"
class NotificationCenter extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tableColumns: [
				{
					dataField: "createdAt",
					text: "Date",
					formatter: (cell, row) => <span>{moment(cell).format(" ddd DD/MM/YYYY HH:mm:ss")}</span>
				},
				{
					dataField: "sendTo",
					text: "Send to",
					formatter: (cell, row) => (cell.name ? cell.name : "")
				},
				{
					dataField: "title",
					text: "Title"
					// formatter: (cell, row) => <a href={"/class/" + row.id} > {cell}  </a>
				},
				{
					dataField: "message",
					text: "Message"
				}
			],
			hasError: 0,
			error: false,
			isLoading: true
		}
	}

	async getData() {
		var response
		response = await getNotificationsSent()
		if (response.ok) {
			const data = response.data.rows
			console.log(data)
			const newState = Object.assign({}, this.state, {
				tableData: data,
				isLoading: false,
				hasError: false
			})
			// store the new state object in the component's state
			this.setState(newState)
			this.setState({ redirectToReferrer: true })
		} else {
			// Error
			const newState = Object.assign({}, this.state, {
				isLoading: false,
				hasError: true,
				error: response
			})
			// store the new state object in the component's state
			this.setState(newState)
		}
	}
	componentDidMount() {
		this.getData()
	}
	statusFormatter(cell, row, rowIndex, formatExtraData) {
		return (
			<i aria-hidden="true" className={formatExtraData[cell]} />
			//<i className={"glyphicon glyphicon-chevron-up"} />
		)
	}

	render() {
		//		const { polyglot } = this.props;
		return this.state.isLoading ? (
			<div className="ui active centered inline loader" />
		) : this.state.hasError ? (
			<div>
				<Message negative>
					<Message.Header>Error</Message.Header>
					<p> {this.state.error.problem}</p>
				</Message>
			</div>
		) : (
			<div>
				<Container>
					<style>{`
              .react-bootstrap-table{
               text-align:left;
               background-color:#fff
              }

             `}</style>
					<Grid padded>
						<Grid.Row>
							<Grid.Column>
								<Button circular icon="plus" as={Link} to="/notification" />

								<BootstrapTable
									keyField="id"
									data={this.state.tableData}
									columns={this.state.tableColumns}
									bordered={false}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</div>
		)
	}
}
NotificationCenter = withPolyglot()(NotificationCenter)
export default NotificationCenter
