import React, { Component } from 'react'
import { Icon, Placeholder, Segment } from 'semantic-ui-react'
import { Slider } from 'react-semantic-ui-range'
import ReactAvatarEditor from 'react-avatar-editor'
import { uploadProfilePic } from '../services/Api'
class ProfilePicEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image: this.props.currentPic,
      allowZoomOut: true,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      preview: null,
      width: 100,
      height: 100,
      loading: false
    }
  }

  componentDidMount = () => {
    this.setState({ image: this.props.currentPic })
  }

  handleFileUpload = event => {
    //this.setState({file: event.target.files});
    const newState = Object.assign({}, this.state, {
      isLoading: true
    })
    this.setState(newState)
    event.preventDefault()
    const formData = new FormData()

    /*
    this.editor.getImageScaledToCanvas().toBlob(blob => {
      console.log(this.state.image)
      console.log(blob)
      formData.append('file', blob)
    })
*/
    // formData.append('file', this.state.image)
    formData.append(
      'file',
      dataURLToBlob(this.editor.getImageScaledToCanvas().toDataURL())
    )

    formData.append('userId', localStorage.RWJUserId)

    uploadProfilePic(formData)
      .then(response => {
        var updatedPic = response.data.picture
        const newState = Object.assign({}, this.state, {
          isLoading: false
        })
        this.setState(newState)
        console.log(this.props)
        this.props.mustRefresh(updatedPic)
        this.props.closeModal()
        // handle your response;
      })

      .catch(error => {
        // handle your error
        console.log('ko')
      })
      .finally(() => {})
  }
  handleNewImage = e => {
    this.setState({ image: e.target.files[0] })
  }

  handleSave = data => {
    const img = this.editor.getImageScaledToCanvas().toDataURL()
    const rect = this.editor.getCroppingRect()

    this.setState({
      preview: {
        img,
        rect,
        scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius
      }
    })
  }

  handleScale = e => {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
  }
  handleScaleValue = value => {
    const scale = parseFloat(value)
    this.setState({ scale })
  }

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut })
  }

  rotateLeft = e => {
    e.preventDefault()

    this.setState({
      rotate: this.state.rotate - 90
    })
  }

  rotateRight = e => {
    e.preventDefault()
    this.setState({
      rotate: this.state.rotate + 90
    })
  }

  handleXPosition = e => {
    const x = parseFloat(e.target.value)
    this.setState({ position: { ...this.state.position, x } })
  }

  handleYPosition = e => {
    const y = parseFloat(e.target.value)
    this.setState({ position: { ...this.state.position, y } })
  }

  handleWidth = e => {
    const width = parseInt(e.target.value)
    this.setState({ width })
  }

  handleHeight = e => {
    const height = parseInt(e.target.value)
    this.setState({ height })
  }

  logCallback(e) {
    // eslint-disable-next-line
    // console.log('callback', e)
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor
  }

  handlePositionChange = position => {
    this.setState({ position })
  }
  onLoadSuccess = () => {
    this.setState({ isLoading: false })
  }

  render() {
    return (
      <Segment basic compact style={{ margin: ' 0 auto', minWidth: '300px' }}>
        <div className="avatar-upload">
          <div>
            <ReactAvatarEditor
              ref={this.setEditorRef}
              scale={parseFloat(this.state.scale)}
              width={this.state.width}
              height={this.state.height}
              position={this.state.position}
              onPositionChange={this.handlePositionChange}
              rotate={parseFloat(this.state.rotate)}
              onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
              onLoadSuccess={this.onLoadSuccess}
              onImageReady={this.logCallback.bind(this, 'onImageReady')}
              image={this.state.image}
              className="editor-canvas"
              crossOrigin="anonymous"
              style={
                this.state.isLoading
                  ? { display: 'none' }
                  : { display: 'inline-block' }
              }
            />

            {this.state.isLoading && (
              <div
                style={{ width: '150px', height: '150px', margin: '0 auto' }}
              >
                <Placeholder
                  inverted
                  style={{ height: 100, width: 100, margin: '0 auto' }}
                >
                  <Placeholder.Image />
                </Placeholder>
              </div>
            )}
          </div>
          <input
            name="newImage"
            type="file"
            id="fileinput"
            className="inputfile"
            onChange={this.handleNewImage}
            onClick={() => this.setState({ isLoading: true })}
          />
          <label htmlFor="fileinput">
            <Icon
              link
              name="images outline"
              style={{ margin: '10px' }}
              size="big"
              inverted
            />
          </label>
          <br />

          <Slider
            style={{ marginTop: '20px' }}
            color="red"
            value={this.state.scale}
            inverted
            settings={{
              start: this.state.scale,
              min: this.state.allowZoomOut ? 0.1 : 1,
              max: 2,
              step: 0.01,
              onChange: value => {
                this.handleScaleValue(value)
              }
            }}
          />
          <br />
          <Icon
            link
            name="undo alternate"
            size="big"
            inverted
            style={{ margin: '10px' }}
            onClick={this.rotateLeft}
          />
          <Icon
            link
            name="redo alternate"
            size="big"
            inverted
            style={{ margin: '10px' }}
            onClick={this.rotateRight}
          />
          <br />

          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <Icon
              link
              name="save outline"
              size="big"
              inverted
              onClick={this.handleFileUpload}
            />
          </div>
          <br />
        </div>
      </Segment>
    )
  }
}

export default ProfilePicEditor

var dataURLToBlob = function(dataURL) {
  var BASE64_MARKER = ';base64,'
  var parts
  var contentType
  var raw
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(',')
    contentType = parts[0].split(':')[1]
    raw = parts[1]
    return new Blob([raw], { type: contentType })
  }

  parts = dataURL.split(BASE64_MARKER)
  contentType = parts[0].split(':')[1]
  raw = window.atob(parts[1])
  var rawLength = raw.length
  var uInt8Array = new Uint8Array(rawLength)
  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  return new Blob([uInt8Array], { type: contentType })
}
