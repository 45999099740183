import React, { Component, Fragment } from 'react'
import { Grid, Label } from 'semantic-ui-react'
import { withPolyglot } from 'polyglot-react'
import moment from 'moment'
import 'rc-slider/assets/index.css'
import Slider from 'rc-slider'
import 'react-toastify/dist/ReactToastify.min.css'

class PaceSpeed extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      perfDate: new Date(),
      perfTime: null,
      perfDistance: null,
      unit: 'meter',
      errorFields: [],
      displayPerfHour: false,
      perfHour: 0,
      minute: 6,
      second: 0,
      speed: 10,
      isReal: false,
      isWaiting: false
    }
  }

  updateValues(value, key) {
    var { minute, second } = this.state
    minute = key === 'minute' ? value : minute
    second = key === 'second' ? value : second
    var timeInSecond = minute * 60 + second
    var speed = 3600 / timeInSecond

    speed = speed.toFixed(2)
    this.setState({
      ...this.state,
      minute: minute,
      second: second,
      speed: speed
    })
  }
  roadmap() {
    const roadmapArray = {
      '100m': 100,
      '200m': 200,
      '300m': 300,
      '400m': 400,
      '500m': 500,
      '600m': 600,
      '800m': 800,
      '1k': 1000,
      '1500m': 1500,
      '1600m': 1600,
      '2k': 2000,
      '5k': 5000,
      '10k': '10000',
      Semi: 21097,
      Marathon: 42195
    }

    const timesheet = Object.keys(roadmapArray).map(label => {
      var totalSeconds =
        (roadmapArray[label] * (this.state.minute * 60 + this.state.second)) /
        1000
      return (
        <div key={label}>
          <strong>{label}</strong>{' '}
          {moment('1900-01-01 00:00:00')
            .add(totalSeconds, 'seconds')
            .format(totalSeconds >= 3600 ? 'hh:mm:ss' : 'mm:ss')}
        </div>
      )
    })
    return timesheet
  }

  render() {
    const { polyglot } = this.props

    return (
      <Fragment>
        <style>
          {`
          .rc-slider {
            margin-top:5px
          }
          .rc-slider-track {
            background-color: red;
          }
          .rc-slider-handle:active {
              border-color: red;
              box-shadow: 0 0 5px red;
          }
          .rc-slider-handle {
            border: solid 2px red;
            background-color: red;
            touch-action: pan-x;
            margin-top: -16px;
            width: 32px;
            height: 32px;
          }
          .rc-slider-handle:active {
            border-color: red;
            box-shadow: 0 0 5pxred;
            cursor: -webkit-grabbing;
            cursor: grabbing;
          }
          .rc-slider-handle:hover {
            border-color: red;
          }
          .ui.checkbox label,.ui.checkbox label:hover{
            background:transparent;
            color:#fff;
            border:none;
          }
        `}
        </style>

        <Grid centered columns={1}>
          <Grid.Column style={{ textAlign: 'center' }}>
            <Label
              style={{ userSelect: 'none' }}
              size="huge"
              onClick={() =>
                this.updateValues(
                  this.state.minute < 7
                    ? this.state.minute + 1
                    : this.state.minute,
                  'minute'
                )
              }
            >
              {moment('1900-01-01 00:00:00')
                .add(this.state.minute, 'minute')
                .format('mm')}
            </Label>{' '}
            :
            <Label
              style={{ userSelect: 'none' }}
              size="huge"
              onClick={() =>
                this.updateValues(
                  this.state.second < 59
                    ? this.state.second + 1
                    : this.state.second,
                  'second'
                )
              }
            >
              {moment('1900-01-01 00:00:00')
                .add(this.state.second, 'seconds')
                .format('ss')}
            </Label>
            <Label style={{ userSelect: 'none' }} size="big" basic>
              min/km
            </Label>
          </Grid.Column>
        </Grid>
        <Grid centered columns={1}>
          <Grid.Column style={{ textAlign: 'center' }}>
            <Label
              style={{ userSelect: 'none' }}
              size="huge"
              onClick={() =>
                this.updateValues(
                  this.state.second < 59
                    ? this.state.second + 1
                    : this.state.second,
                  'second'
                )
              }
            >
              {this.state.speed}
            </Label>
            <Label style={{ userSelect: 'none' }} size="big" basic>
              km/h
            </Label>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column
              style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
            >
              <span style={{ userSelect: 'none', color: 'grey' }}>
                {' '}
                {polyglot.t('paceSpeed.minutes')}
              </span>
              <Slider
                min={2}
                max={8}
                defaultValue={0}
                value={this.state.minute}
                onChange={value => this.updateValues(value, 'minute')}
                style={{ userSelect: 'none' }}
              />
              <br />
              <span style={{ userSelect: 'none', color: 'grey' }}>
                {polyglot.t('paceSpeed.seconds')}
              </span>
              <Slider
                min={0}
                max={59}
                defaultValue={0}
                value={this.state.second}
                onChange={value => this.updateValues(value, 'second')}
                style={{ userSelect: 'none' }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid>
          <Grid.Row>
            <Grid.Column style={{ color: '#d2d2d2' }}>
              {this.roadmap()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    )
  }
}

PaceSpeed = withPolyglot()(PaceSpeed)
export default PaceSpeed
