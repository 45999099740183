import React, { Component, Fragment } from "react"
import { Message, Feed, Loader, Container, Placeholder, Segment } from "semantic-ui-react"
import { setMyNotificationAsRead } from "../services/Api"
import { withPolyglot } from "polyglot-react"
import _ from "lodash"
import moment from "moment"
import ReactGA from "react-ga"
import { getNotificationsForMe } from "../services/Api"
import AppError from "../components/AppError"

class UserNotifications extends Component {
	// default State object
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
			error: false,
			isLoading: true,
			notifications: []
		}
	}
	componentDidMount() {
		this.call_getNotificationForMe()
		ReactGA.pageview(window.location.pathname + window.location.search)
	}
	componentWillUnmount() {
		setMyNotificationAsRead().then((response) => {
			if (response.ok) {
			}
		})
	}
	async call_getNotificationForMe() {
		const response = await getNotificationsForMe()
		if (response.ok) {
			console.log(response)
			const data = response.data.rows
			const newState = Object.assign({}, this.state, {
				notificationCount: response.data.unreadCount,
				notifications: data,
				isLoading: false,
				hasError: false,
				redirectToReferrer: true
			})
			// store the new state object in the component's state
			this.setState(newState)
		} else {
			const newState = Object.assign({}, this.state, {
				isLoading: false,
				hasError: true,
				error: response
			})
			// store the new state object in the component's state
			this.setState(newState)
		}
	}

	render() {
		const { polyglot } = this.props
		return (
			<Fragment>
				{this.state.isLoading && (
					<Container style={{ marginTop: "10px", height: "100%", textAlign: "center" }}>
						<Segment inverted>
							<Loader active />
							<Placeholder inverted fluid>
								{_.times(20, String).map(function(item, i) {
									return (
										<Placeholder.Header image key={i}>
											<Placeholder.Line />
											<Placeholder.Line />
										</Placeholder.Header>
									)
								})}
							</Placeholder>
						</Segment>
					</Container>
				)}
				{this.state.hasError && !this.state.isLoading && (
					<AppError message={this.state.error.problem ? this.state.error.problem : ""} />
				)}
				{!this.state.hasError && !this.state.isLoading && (
					<div className="notifications full-page">
						<style>{`
              .notifications .feed .event {
                border-bottom: 1px solid grey;
                margin-bottom:13px
              }
              .read{
                opacity:0.5
              }
						`}</style>
						<Container style={{ height: "100%", textAlign: "left" }}>
							<Feed>
								{Array.isArray(this.state.notifications) && this.state.notifications.length ? (
									this.state.notifications.map(function(notification, i) {
										return (
											<Feed.Event
												className={notification.read ? "read" : ""}
												key={i}
												image={"/icons/icon-72x72.png"}
												date={moment(notification.createdAt).format("ddd DD/MM/YYYY HH:mm:ss")}
												summary={notification.title}
												extraText={notification.message}
											/>
										)
									})
								) : (
									<Fragment>
										<Message color="black"> {polyglot.t("notifications.none")}</Message>
									</Fragment>
								)}
							</Feed>
						</Container>
					</div>
				)}
			</Fragment>
		)
	}
}

UserNotifications = withPolyglot()(UserNotifications)
export default UserNotifications
