import React, { Component } from "react"

import { withPolyglot } from "polyglot-react"
import "react-toastify/dist/ReactToastify.min.css"
import AppError from "../components/AppError"
import { Config } from "../config"
import ReactGA from "react-ga"
ReactGA.initialize(Config.gaId)

class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			email: "",
			password: "",
			redirectToReferrer: false,
			hasError: false,
			error: false,
			isLoading: false
		}
	}
	componentDidMount() {
		ReactGA.pageview(window.location.pathname + window.location.search)
	}

	render() {
		const { polyglot } = this.props

		return (
			<div className="Home">
				<AppError message={polyglot.t("generic.message.notfound")} />
			</div>
		)
	}
}
Login = withPolyglot()(Login)
export default Login
