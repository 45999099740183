import React, { Component } from "react"

import { Button, Form, Grid, Segment, Message } from "semantic-ui-react"
import { Redirect, Link } from "react-router-dom"
import { signup } from "../services/Api"
import { withPolyglot } from "polyglot-react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import ReactGA from "react-ga"
class Signup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			name: "",
			email: "",
			password: "",
			redirectToReferrer: false,
			hasError: false,
			error: false
		}
	}

	componentWillMount() {
		ReactGA.pageview(window.location.pathname + window.location.search)
	}

	async call_signup() {
		const { polyglot } = this.props

		const { name, email, password } = this.state
		if (!name || !email || !password) {
			toast.error(polyglot.t("signup.message.fields.mandatory"))
			return
		}

		const response = await signup({ name: name, email: email, password: password })
		if (response.ok) {
			localStorage.RWJToken = response.data.token
			localStorage.RWJRole = response.data.user.role
			localStorage.RWJUserId = response.data.user.id
			localStorage.RWJUserIsActive = response.data.user.isActive
			const newState = Object.assign({}, this.state, {
				hasError: false,
				redirectToReferrer: true
			})
			this.setState(newState)
		} else {
			var data = response.data
			var err_param = response.data.param
			var err_name = response.data.name
			if (err_param === "email" && err_name === "match") {
				data.message = "signup.message.fields.email.wrong"
			} else if (err_param === "email" && !err_name) {
				data.message = "signup.message.fields.email.exists"
				console.log("signup.message.fields.email.exists")
			} else if (err_param === "password" && err_name === "minlength") {
				data.message = "signup.message.fields.password.short"
			}

			const newState = Object.assign({}, this.state, {
				hasError: true,
				error: response
			})

			toast.error(polyglot.t(data.message))
			this.setState(newState)
		}
	}

	handleValue(e, key) {
		this.setState({ ...this.state, [key]: e.target.value })
	}

	render() {
		const { from } = this.props.location.state || { from: { pathname: "/" } }
		const { redirectToReferrer } = this.state
		const { polyglot } = this.props

		if (redirectToReferrer) {
			return <Redirect to={from} />
		}

		return (
			<div className="signup-form">
				<style>{`
              .main-menu .menu-btn, .main-menu .menu-btn icon  {
                display:none;
              }

						`}</style>
				<Grid textAlign="center" style={{ height: "100%" }} verticalAlign="middle">
					<Grid.Column style={{ maxWidth: 450 }}>
						<Form size="large">
							<Segment>
								<Form.Input
									fluid
									icon="user"
									iconPosition="left"
									placeholder={polyglot.t("signup.name")}
									onChange={(e) => this.handleValue(e, "name")}
									value={this.state.name}
								/>
								<Form.Input
									fluid
									icon="at"
									iconPosition="left"
									placeholder={polyglot.t("signup.email")}
									onChange={(e) => this.handleValue(e, "email")}
									value={this.state.email}
								/>
								<Form.Input
									fluid
									icon="lock"
									iconPosition="left"
									placeholder={polyglot.t("signup.password")}
									type="password"
									onChange={(e) => this.handleValue(e, "password")}
									value={this.state.password}
								/>
								<div className="bottom-10">
									<Link className="center-text" to="/login">
										{" "}
										{polyglot.t("signup.login.go")}
									</Link>
									<div className="clear" />
								</div>
								<Button color="red" fluid size="large" onClick={this.call_signup.bind(this)}>
									{polyglot.t("signup.register")}
								</Button>
							</Segment>
						</Form>
					</Grid.Column>
				</Grid>
				{this.state.hasError && (
					<div>
						<Message negative>
							<Message.Header>Error</Message.Header>
							<p> {this.state.error.data.message}</p>
						</Message>
					</div>
				)}
				<ToastContainer autoClose={3000} />
			</div>
		)
	}
}
Signup = withPolyglot()(Signup)
export default Signup
