import React, { Component } from 'react'
import { Grid, Container } from 'semantic-ui-react'
import { getDrills, updateDrill } from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import DrillCreate from '../components/DrillCreate'
import { withPolyglot } from 'polyglot-react'
import AppError from '../components/AppError'
const categories = [
  'warmup',
  'pose',
  'fall',
  'pull',
  'combined',
  'strength',
  'other'
]
class Drills extends Component {
  constructor(props) {
    super(props)
    this.state = {
      faults: [],
      clues: [],
      tableData: [],
      tableColumns: [
        {
          dataField: 'name',
          text: 'Name'
        },
        {
          dataField: 'category',
          text: 'Category',
          editor: {
            type: Type.SELECT,
            options: categories.map(cat => {
              return { value: cat, label: cat }
            })
          }
          /*options: [{
						value: 'A',
						label: 'A'
					}]*/
        },
        {
          dataField: 'description',
          text: 'Description',
          editor: {
            type: Type.TEXTAREA
          },
          editorStyle: (cell, row, rowIndex, colIndex) => {
            const width = '100%'
            const height = '100px'
            return { width, height }
          }
        },
        {
          dataField: 'image',
          text: 'Image',
          formatter: (cell, row) => (
            <img alt="" style={{ height: '40px' }} src={'/drills/' + cell} />
          )
        },

        {
          dataField: 'video',
          text: 'VideoID'
        },
        {
          dataField: 'gear',
          text: 'Equipement'
        },

        {
          dataField: 'isPublished',
          text: 'Publié',
          formatter: this.statusFormatter,
          formatExtraData: {
            true: 'icon large check square outline color-green',
            false: 'icon large square outline color-red'
          },
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false'
          }
        }
      ],
      hasError: 0,
      error: false,
      isLoading: true
    }
  }
  statusFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <i className={formatExtraData[cell]} />
      //<i className={"glyphicon glyphicon-chevron-up"} />
    )
  }
  async handleCellSave(dataField, row, value) {
    let body = Object.assign(row, { [dataField]: value })

    var response = await updateDrill(row.id, body)
    if (!response.ok) {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
    }
  }

  async getData() {
    const response = await getDrills()
    if (response.ok) {
      const data = response.data.rows

      // store the new state object in the component's state
      this.setState({
        tableData: data,
        isLoading: false,
        hasError: false,
        redirectToReferrer: true
      })
    } else {
      // Error
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response
      })
      this.setState(newState)
    }
  }
  componentDidMount() {
    this.getData()
  }

  render() {
    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div>
        <Container>
          <style>{`
						 .react-bootstrap-table{
							margin-top:0px;
							text-align:left;
							background-color:#fff
						 }
						 table {
							 white-space: pre-line;
						 }
						`}</style>
          {localStorage.getItem('RWJRole') === 'admin' && <DrillCreate />}
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <BootstrapTable
                  keyField="id"
                  data={this.state.tableData}
                  columns={this.state.tableColumns}
                  bordered={false}
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      this.handleCellSave(column.dataField, row, newValue)
                    }
                  })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

Drills = withPolyglot()(Drills)
export default Drills
