import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Header,
  Container,
  Icon,
  Grid,
  Segment,
  Image
} from 'semantic-ui-react'
import { getDrillsByCategory } from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import { withPolyglot } from 'polyglot-react'
import AppError from '../components/AppError'

class DrillsCategory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drills: [],
      hasError: 0,
      error: false,
      isLoading: true,

      currentDrill: false
    }
  }

  async getData() {
    const response = await getDrillsByCategory(this.props.match.params.category)
    if (response.ok) {
      const data = response.data.rows

      // store the new state object in the component's state
      this.setState({
        drills: data,
        isLoading: false,
        hasError: false
      })
      //	this.setState({ redirectToReferrer: true })
    } else {
      // Error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
    }
  }
  componentDidMount() {
    this.getData()
  }

  render() {
    const { polyglot } = this.props
    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div className="page ">
        <Container text className="page ">
          <style>{`
				
					.segments{
						background: rgba(0, 0, 0, 0.6) !important;
					}
					.segments .segment {
						border-top: 1px solid #3a3939 !important;
						border-right: 1px solid #3a3939 !important;
						border-left: 1px solid #3a3939 !important;
					}
					.segments .segment:last-child 					{
						border-bottom: 1px solid #3a3939 !important;
					}
					.segment .image {
						margin-right:1em !important;
					}

							
					.page	.ui.avatar.image {
						width: 3.5em;
						height: 3.5em;
						margin-top: 0.2rem;
						margin-bottom: 0.2rem;
					}
					.page .ui.segment 
					{
						padding:0.4em;
					}
						`}</style>

          <Header as="h2" className="color-white center-text uppercase  top-10">
            {polyglot.t('drillByCategory.' + this.props.match.params.category)}
          </Header>
          {/**
					<Divider />
					 */}

          <Grid style={{ minHeight: '80vh', marginTop: '0px' }} relaxed>
            <Grid.Row style={{ paddingTop: '0px' }}>
              <Grid.Column
                style={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
              >
                <Segment.Group>
                  {this.state.drills.length !== 0 &&
                    this.state.drills.map(drill => (
                      <Segment key={drill.id} basic textAlign="left">
                        <Link to={'/drills/' + drill.id}>
                          <div>
                            <Icon
                              name="chevron right"
                              size="small"
                              className="floated right color-grey top-20"
                            />
                            {drill.image ? (
                              <Image src={'/drills/' + drill.image} avatar />
                            ) : (
                              <Image src={'/icons/icon-72x72.png'} avatar />
                            )}

                            {drill.name}
                          </div>
                        </Link>
                      </Segment>
                    ))}
                  {this.state.drills.length === 0 && (
                    <span>{polyglot.t('generic.message.empty')}</span>
                  )}
                </Segment.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

DrillsCategory = withPolyglot()(DrillsCategory)
export default DrillsCategory
