import React, { Component } from 'react'
import { Container } from 'semantic-ui-react'
import { getDrill, logDrills } from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import { withPolyglot } from 'polyglot-react'
import AppError from '../components/AppError'
import DrillVideo from '../components/DrillVideo'
import { findIndex } from 'lodash'
import SwipeableViews from 'react-swipeable-views'

class Drill extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drills: [],
      hasError: 0,
      error: false,
      isLoading: true,
      drill: false,
      activeIndex: 0
    }
  }

  async getData() {
    let drill
    let drillIndex = 0
    const drillsList = this.props.location.state
      ? this.props.location.state.drills
      : []
    if (drillsList.length !== 0) {
      drillIndex = findIndex(drillsList, {
        _id: this.props.match.params.id
      })
      drill = drillsList[drillIndex]
    } else {
      const response = await getDrill(this.props.match.params.id)
      if (response.ok) {
        drill = response.data
      } else {
        this.setState({
          isLoading: false,
          hasError: true,
          error: response
        })
      }
    }

    this.setState({
      activeIndex: drillIndex,
      drill: drill,
      isLoading: false,
      hasError: false
    })
  }
  componentDidMount() {
    this.getData()
  }
  handleChangeIndex = activeIndex => {
    this.setState({
      activeIndex
    })
  }
  nextView = e => {
    var { activeIndex = 0 } = this.state

    this.setState({ activeIndex: activeIndex + 1 })
  }
  prevView = e => {
    var { activeIndex = 0 } = this.state

    this.setState({ activeIndex: activeIndex - 1 })
  }

  logDrills = async e => {
    const drillsList = this.props.location.state
      ? this.props.location.state.drills
      : []
    const doneDrills = drillsList.map(x => x._id)
    this.setState({ isLoading: true })
    await logDrills({ doneDrills })
    this.props.history.goBack()
  }

  render() {
    const { activeIndex } = this.state
    const drillsList = this.props.location.state
      ? this.props.location.state.drills
      : []
    const trainingMode = this.props.location.state
      ? this.props.location.state.trainingMode
      : false

    var swipeableDrills = []

    var showLeftIcon = index => {
      return index !== 0
    }
    var showRightIcon = index => {
      return index < drillsList.length - 1
    }
    var showDoneIcon = index => {
      return index === drillsList.length - 1
    }

    if (drillsList.length === 0) {
      swipeableDrills.push(<DrillVideo drill={this.state.drill} />)
    } else {
      for (let index = 0; index < drillsList.length; index++) {
        const element = drillsList[index]
        swipeableDrills.push(
          <DrillVideo
            drill={element}
            drillIndex={index}
            activeIndex={activeIndex}
            drillsTotalCount={drillsList.length}
            trainingMode={trainingMode}
            leftIcon={{ show: showLeftIcon(index), action: this.prevView }}
            rightIcon={{ show: showRightIcon(index), action: this.nextView }}
            doneIcon={{ show: showDoneIcon(index), action: this.logDrills }}
          />
        )
      }
    }

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div className="page page-drill-full">
        <Container text>
          <style>{`
					.page-drill-full
					{
						margin-left: -2em!important; 
						margin-right: -2em!important;
					}
						`}</style>

          <div className="pad-top-5"></div>

          <SwipeableViews
            enableMouseEvents={true}
            index={activeIndex}
            onChangeIndex={this.handleChangeIndex}
            disabled={drillsList.length === 0}
            children={swipeableDrills}
          ></SwipeableViews>
        </Container>
      </div>
    )
  }
}

Drill = withPolyglot()(Drill)
export default Drill
