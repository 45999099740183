import React, { PureComponent, Fragment } from "react"
import { Container, Segment, Header, Icon, Button } from "semantic-ui-react"
import { withPolyglot } from "polyglot-react"
import ReactGA from "react-ga"
import { Config } from "../config"
ReactGA.initialize(Config.gaId)

class AppError extends PureComponent {
	render() {
		const { polyglot } = this.props
		return (
			<Fragment>
				<style>{`
        .ui.container.app-error{
        margin:10px auto !important;
        height:100%;
        text-align:center;
        width:250px !important;
        }

      `}</style>
				<Container className={"app-error"}>
					<Segment placeholder circular inverted color="red" style={{ width: 250, height: 250 }}>
						<Header icon>
							<Icon name="frown outline" />
							<div> {polyglot.t("error.oops")}</div>
						</Header>
						<div className={"bottom-5"}>{this.props.message ? this.props.message : polyglot.t("error.message")}</div>

						<Button
							inverted
							onClick={() => {
								ReactGA.event({ category: "Error", action: "Refresh" })
								window.location.reload(true)
							}}>
							{polyglot.t("error.retry")}
						</Button>
					</Segment>
				</Container>
			</Fragment>
		)
	}
}

AppError = withPolyglot()(AppError)
export default AppError
