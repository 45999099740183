import React, { Component } from 'react'
import { Form, Icon, Select } from 'semantic-ui-react'
import { createAnalysis, getMyAthletes } from '../services/Api'
import { withPolyglot } from 'polyglot-react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

class AnalysisCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      urlCode: '',
      userId: '',
      myAthletes: []
    }
    this.call_createAnalysis = this.call_createAnalysis.bind(this)
  }
  componentDidMount() {
    this.call_getMyathletes()
  }
  async call_getMyathletes() {
    var response
    response = await getMyAthletes()

    if (response.ok) {
      const data = response.data.rows
      data.sort(function(a, b) {
        if (!a.name && !b.name) return 0
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      })
      var userList = data.map(user => {
        return { key: user.id, text: user.name, value: user.id }
      })

      const newState = Object.assign({}, this.state, {
        myAthletes: userList,
        isLoading: false,
        hasError: false
      })
      // store the new state object in the component's state
      this.setState(newState)
      this.setState({ redirectToReferrer: true })
    } else {
      // Error
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }
  async call_createAnalysis() {
    const { polyglot } = this.props
    toast.success(polyglot.t('generic.message.update'), {
      // onClose:     this.props.mustRefresh()
    })
    const payload = { urlCode: this.state.urlCode, user: this.state.userId }
    const response = await createAnalysis(payload)
    if (response.ok) {
      /*  const newState = Object.assign({}, this.state, {
        user:'',
        urlCode:'',

			});
			// store the new state object in the component's state
      this.setState(newState);
      */
      const newId = response.data._id
      this.props.history.push('/analysis/' + newId)
    }
  }
  handleValue(e, key) {
    this.setState({ ...this.state, [key]: e.target.value })
  }

  handleUserList(e, data) {
    this.setState({ userId: data.value })
  }

  render() {
    const { polyglot } = this.props

    return (
      <Form inverted style={{ paddingTop: '15px' }}>
        <Form.Group>
          <Select
            placeholder={polyglot.t('analysisCreate.athlete')}
            value={this.state.userId}
            options={this.state.myAthletes}
            onChange={this.handleUserList.bind(this)}
            style={{ height: '1em' }}
          />
          <Form.Input
            placeholder={polyglot.t('analysisCreate.urlCode')}
            value={this.state.urlCode}
            onChange={e => this.handleValue(e, 'urlCode')}
          />

          <Icon
            link
            name="save outline"
            size="big"
            inverted
            onClick={this.call_createAnalysis}
          />
        </Form.Group>
        <ToastContainer autoClose={3000} />
      </Form>
    )
  }
}

AnalysisCreate = withPolyglot()(AnalysisCreate)
export default AnalysisCreate
