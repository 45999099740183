import React, { Component } from "react"

import { Button, Form, Grid, Segment, Divider } from "semantic-ui-react"
import { Redirect } from "react-router-dom"
import { login } from "../services/Api"
import { withPolyglot } from "polyglot-react"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import ReactGA from "react-ga"
class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			email: "",
			password: "",
			redirectToReferrer: false,
			hasError: false,
			error: false,
			isLoading: false
		}
	}

	componentWillMount() {
		ReactGA.pageview(window.location.pathname + window.location.search)
		if (localStorage.RWJToken) {
			this.setState({ redirectToReferrer: true })
		}
	}

	async authenticate() {
		const { polyglot } = this.props
		const { email, password } = this.state
		var response = await login(email, password)
		var customResponse = {}
		if (response.ok) {
			localStorage.RWJToken = response.data.token
			localStorage.RWJRole = response.data.user.role
			localStorage.RWJUserId = response.data.user.id
			localStorage.RWJUserName = response.data.user.name
			localStorage.RWJUserIsActive = response.data.user.isActive
			const newState = Object.assign({}, this.state, {
				isLoading: false,
				hasError: false,
				redirectToReferrer: true
			})
			this.setState(newState)
			//console.log(this.props)
			//this.props.justLoggedIn()
		} else {
			// Error
			// console.log("erreur")
			customResponse = {
				data: {
					message: polyglot.t("login.message.error")
				}
			}
			response = customResponse
			//toast( 	 polyglot.t('profile.update.done')

			const newState = Object.assign({}, this.state, {
				isLoading: false,
				hasError: true,
				error: response
			})
			this.setState(newState)
			toast.error(this.state.error.data.message, {
				// onClose: (window.location="/home")
			})
		}
	}

	handleValue(e, key) {
		this.setState({ ...this.state, [key]: e.target.value })
	}

	render() {
		const { from } = this.props.location.state || { from: { pathname: "/" } }
		const { redirectToReferrer } = this.state
		const { polyglot } = this.props

		if (redirectToReferrer) {
			return <Redirect to={from} />
		}

		return (
			<div className="login-form">
				<style>{`
              .main-menu .menu-btn, .main-menu .menu-btn icon  {
                display:none;
              }

						`}</style>
				<Grid textAlign="center" style={{ height: "100%" }} verticalAlign="middle">
					<Grid.Column style={{ maxWidth: 450 }}>
						<Form size="large">
							<Segment>
								<Form.Input
									fluid
									icon="at"
									iconPosition="left"
									placeholder={polyglot.t("login.email")}
									onChange={(e) => this.handleValue(e, "email")}
									value={this.state.email}
								/>
								<Form.Input
									fluid
									icon="lock"
									iconPosition="left"
									placeholder={polyglot.t("login.password")}
									type="password"
									onChange={(e) => this.handleValue(e, "password")}
									value={this.state.password}
								/>
								<div className="">
									<Button color="red" fluid size="large" onClick={this.authenticate.bind(this)}>
										{polyglot.t("login.login")}
									</Button>
								</div>
								<div className="top-5">
									<Link className="top-10" to="/forgot">
										{" "}
										{polyglot.t("login.password.forgot")}
									</Link>
									<div className="clear" />
								</div>
							</Segment>
						</Form>
						<Divider hidden />
						<Link className="top-10" to="/signup" style={{ display: "block" }}>
							<Button fluid size="large">
								{polyglot.t("login.signup")}
							</Button>
						</Link>
					</Grid.Column>
				</Grid>

				<ToastContainer autoClose={3000} />
			</div>
		)
	}
}
Login = withPolyglot()(Login)
export default Login
