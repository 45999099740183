import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Header,
  Grid,
  Card,
  Dimmer,
  Loader,
  Tab,
  Feed,
  List,
  Image,
  Button,
  Table
} from 'semantic-ui-react'
import {
  getProtocol,
  updateProtocol,
  getDrills,
  addDrillToProtocol,
  removeDrillFromProtocol,
  addWarmupDrillToProtocol,
  removeWarmupDrillFromProtocol
} from '../services/Api'
import { withPolyglot } from 'polyglot-react'
import { differenceBy, filter } from 'lodash'
import { RIEInput, RIEToggle, RIESelect } from '@attently/riek'
import moment from 'moment'
import { ToastContainer } from 'react-toastify'

import AppError from '../components/AppError'
const types = [
  { id: 'neutral', text: 'Neutral' },
  { id: 'pose', text: 'Pose' },
  { id: 'fall', text: 'Fall' },
  { id: 'pull', text: 'Pull' }
]
class Protocol extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      players: [],
      allDrills: [],
      drills: [],
      warmupDrills: [],
      currentDrillBloc: 'warmup',
      protocolData: {},
      notificationData: {}
    }
    const { polyglot } = props
    this.state.notificationData.message = polyglot.t(
      'course.notification.default.message'
    )
    this.state.notificationData.title = polyglot.t(
      'course.notification.default.title'
    )
  }
  componentDidMount() {
    if (this.call_getProtocol()) {
      this.call_getDrills()
    }
  }

  async call_getProtocol() {
    const response = await getProtocol(this.props.match.params.id)
    if (response.ok) {
      this.setState({
        isLoading: false,
        hasError: false,
        drills: response.data.drills || [],
        warmupDrills: response.data.warmupDrills || [],
        protocolData: response.data
      })
      return true
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
      return false
    }
  }

  async call_getDrills() {
    const response = await getDrills()
    if (response.ok) {
      // const { polyglot } = this.props;
      const data = response.data.rows
      this.setState({
        allDrills: data,
        isLoading: false,
        hasError: false,
        redirectToReferrer: true
      })
    } else {
      // Error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
    }
  }

  async addToProtocol(type, object) {
    const drill = object

    var response
    if (this.state.currentDrillBloc === 'warmup') {
      response = await addWarmupDrillToProtocol(this.props.match.params.id, {
        drillId: drill.id
      })
    } else {
      response = await addDrillToProtocol(this.props.match.params.id, {
        drillId: drill.id
      })
    }

    if (response.ok) {
      this.setState({
        drills: response.data.drills,
        warmupDrills: response.data.warmupDrills
      })
    }
  }
  async removeFromProtocol(type, drill) {
    var response
    if (type === 'warmupDrill') {
      response = await removeWarmupDrillFromProtocol(
        this.props.match.params.id,
        {
          drillId: drill._id || drill.id
        }
      )
    } else {
      response = await removeDrillFromProtocol(this.props.match.params.id, {
        drillId: drill._id || drill.id
      })
    }

    if (response.ok) {
      // pullAllBy(newArray, [player], "email")
      this.setState({
        drills: response.data.drills,
        warmupDrills: response.data.warmupDrills
      })
    }
  }
  onDatePickerChange(date) {
    this.call_updateProtocol({ date: date })
  }
  call_updateProtocol = prop => {
    //console.log(prop)
    if (prop.hasOwnProperty('date')) {
      prop['date'] = moment(prop.date, 'DD-MM-YYYY')
    }
    if (typeof prop.type === 'object') {
      prop = { type: prop.type.id }
    }

    let newprotocolData = this.state.protocolData
    Object.assign(newprotocolData, prop)
    const newState = Object.assign({}, this.state, {
      protocolData: newprotocolData
    })
    updateProtocol(this.props.match.params.id, newprotocolData)
      .then(response => {
        if (response.ok) {
          this.setState(newState)
        }
      })
      .catch()
  }

  render() {
    const { polyglot } = this.props
    var _this = this
    let allDrills = this.state.allDrills
    let drills = this.state.drills
    let warmupDrills = this.state.warmupDrills

    const protocolWarmupDrillsList = warmupDrills.map(function(drill, index) {
      return (
        <List.Item key={drill._id || drill.id}>
          <List.Content>
            <List.Header>
              <span className="drill-label">
                {index + 1} - {drill.name}
              </span>
              <Button
                icon="close"
                color="red"
                size="mini"
                onClick={() => _this.removeFromProtocol('warmupDrill', drill)}
                style={{
                  marginLeft: '10px',
                  marginBottom: '5px',
                  verticalAlign: 'top'
                }}
              />
            </List.Header>
            <List.Description />
          </List.Content>
        </List.Item>
      )
    })
    const protocolDrillsList = drills.map(function(drill, index) {
      return (
        <List.Item key={drill._id || drill.id}>
          <List.Content>
            <List.Header>
              <span className="drill-label">
                {index + 1} - {drill.name}
              </span>

              <Button
                icon="close"
                color="red"
                size="mini"
                onClick={() => _this.removeFromProtocol('drill', drill)}
                style={{
                  marginLeft: '10px',
                  marginBottom: '5px',
                  verticalAlign: 'top'
                }}
              />
            </List.Header>
            <List.Description />
          </List.Content>
        </List.Item>
      )
    })

    let drillsList = category =>
      filter(
        differenceBy(allDrills, drills.concat(warmupDrills), 'name'),
        function(o) {
          if (category === 'warmup' || category === 'strength') {
            return o.category === category
          } else {
            return o.category !== 'warmup' && o.category !== 'strength'
          }
        }
      ).map(function(drill) {
        return (
          <Table.Row key={drill.id}>
            <Table.Cell>
              {drill.image && (
                <Image src={'/drills/' + drill.image} avatar size="mini" />
              )}
            </Table.Cell>
            <Table.Cell>{drill.name}</Table.Cell>
            <Table.Cell>{drill.category}</Table.Cell>
            <Table.Cell>
              <Button
                icon="check"
                color="green"
                size="mini"
                onClick={() => _this.addToProtocol('drill', drill)}
                style={{ marginLeft: '10px', verticalAlign: 'top' }}
              />
            </Table.Cell>
          </Table.Row>
        )
      })

    return (
      <Fragment>
        <div className="main-container course">
          <Dimmer active={this.state.isLoading}>
            <Loader />
          </Dimmer>
          {this.state.hasError && !this.state.isLoading && (
            <AppError
              message={this.state.error.problem ? this.state.error.problem : ''}
            />
          )}
          {!this.state.hasError && !this.state.isLoading && (
            <div>
              <style>{`
            .user-list{text-align:left }
            .riek {
              display: block;
              width: 100%;
              min-height: 12px;
            }
            textarea.riek {
              min-height: 200px;
            }
            .react-datepicker__input-container input {
              border:none;
						}
						.ui.header>.icon.small
						{
							font-size: .75em!important;
							cursor:pointer;

						}
						.drill-label{
							width:200px;
							display:inline-block;
						}

						`}</style>

              <Grid stackable columns={2} divided style={{ height: '100%' }}>
                <Grid.Row
                  style={{
                    paddingLeft: '2rem'
                  }}
                >
                  <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                    <div style={{ float: 'right' }}>
                      <RIEToggle
                        value={this.state.protocolData.isAvailable || ''}
                        className={this.state.highlight ? 'editable' : ''}
                        change={this.call_updateProtocol}
                        classLoading="loading"
                        propName="isAvailable"
                        textTrue={polyglot.t('protocol.isAvailable')}
                        textFalse={polyglot.t('protocol.isAvailable.not')}
                      />
                    </div>
                    <Header as="h2">
                      <RIEInput
                        value={this.state.protocolData.label || ''}
                        change={this.call_updateProtocol}
                        propName="label"
                        className={
                          this.state.protocolData.label ? ' ' : ' riek'
                        }
                        classLoading="loading"
                        classInvalid="invalid"
                      />{' '}
                      {/**
                      <Icon
                        name="copy"
                        size="small"
                        onClick={this.call_copyCourse}
                        style={{ marginLeft: "10px", float: "right" }}
                      />
											 */}
                    </Header>
                    <Grid stackable>
                      <Grid.Column className="card-white pad-bottom-30 pad-top-15 card-description">
                        <Card fluid>
                          <Card.Content textAlign="left">
                            <Card.Header>
                              {polyglot.t('protocol.type')}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {polyglot.t('generic.select.label')} :{''}
                              </span>
                              <span
                                style={{
                                  display: 'inline-block',
                                  minWidth: '50px',
                                  marginLeft: '5px'
                                }}
                              >
                                <RIESelect
                                  value={{
                                    id: this.state.protocolData.type,
                                    text: types.find(
                                      x => x.id === this.state.protocolData.type
                                    )['text']
                                  }}
                                  className={
                                    this.state.protocolData.type ? ' ' : ' riek'
                                  }
                                  change={this.call_updateProtocol}
                                  options={types}
                                  classLoading="loading"
                                  propName="type"
                                  style={{
                                    display: 'inline-block',
                                    width: 'auto'
                                  }}
                                />
                              </span>
                            </div>
                          </Card.Content>
                        </Card>

                        <Card
                          fluid
                          style={
                            this.state.currentDrillBloc === 'warmup'
                              ? { border: '1px solid grey' }
                              : {}
                          }
                          onClick={() =>
                            this.setState({ currentDrillBloc: 'warmup' })
                          }
                        >
                          <Card.Content textAlign="left">
                            <Card.Header>
                              {polyglot.t('protocol.warmup')}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <Feed>{protocolWarmupDrillsList}</Feed>
                          </Card.Content>
                        </Card>
                        <Card
                          fluid
                          style={
                            this.state.currentDrillBloc === 'technique'
                              ? { border: '1px solid grey' }
                              : {}
                          }
                          onClick={() =>
                            this.setState({ currentDrillBloc: 'technique' })
                          }
                        >
                          <Card.Content textAlign="left">
                            <Card.Header>
                              {polyglot.t('protocol.drills')}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <Feed>{protocolDrillsList}</Feed>
                          </Card.Content>
                        </Card>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                    <Fragment>
                      <Tab
                        menu={{ secondary: true, pointing: true }}
                        panes={[
                          {
                            menuItem: polyglot.t('course.warmup'),
                            render: () => (
                              <Tab.Pane attached={false}>
                                <Table basic="very" celled collapsing>
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell> </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {polyglot.t('course.drill.name')}
                                      </Table.HeaderCell>

                                      <Table.HeaderCell>
                                        {polyglot.t('course.drill.category')}
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body>
                                    {drillsList('warmup')}
                                  </Table.Body>
                                </Table>
                              </Tab.Pane>
                            )
                          },
                          {
                            menuItem: polyglot.t('course.drills'),
                            render: () => (
                              <Tab.Pane attached={false}>
                                <Table basic="very" celled collapsing>
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell> </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {polyglot.t('course.drill.name')}
                                      </Table.HeaderCell>

                                      <Table.HeaderCell>
                                        {polyglot.t('course.drill.category')}
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body>{drillsList()}</Table.Body>
                                </Table>
                              </Tab.Pane>
                            )
                          },
                          {
                            menuItem: polyglot.t('course.strength'),
                            render: () => (
                              <Tab.Pane attached={false}>
                                <Table basic="very" celled collapsing>
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell> </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {polyglot.t('course.drill.name')}
                                      </Table.HeaderCell>

                                      <Table.HeaderCell>
                                        {polyglot.t('course.drill.category')}
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body>
                                    {drillsList('strength')}
                                  </Table.Body>
                                </Table>
                              </Tab.Pane>
                            )
                          },
                          {
                            menuItem: polyglot.t('course.mobility'),
                            render: () => (
                              <Tab.Pane attached={false}>
                                <Table basic="very" celled collapsing>
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell>
                                        {polyglot.t('course.drill.name')}
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {polyglot.t('course.drill.category')}
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body />
                                </Table>
                              </Tab.Pane>
                            )
                          }
                        ]}
                      />
                    </Fragment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )}
          <ToastContainer autoClose={3000} />
        </div>
      </Fragment>
    )
  }
}

Protocol = withPolyglot()(Protocol)
export default withRouter(Protocol)
