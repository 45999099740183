import React, { Component } from "react"
import { Form, Input, Button } from "semantic-ui-react"
import { createFault } from "../services/Api"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"

class FaultCreate extends Component {
	constructor(props) {
		super(props)
		this.state = {
			code: ""
		}
		this.call_createFault = this.call_createFault.bind(this)
	}
	async call_createFault() {
		const response = await createFault(this.state)
		if (response.ok) {
			const newState = Object.assign({}, this.state, {
				code: ""
			})
			// store the new state object in the component's state
			console.log(this.state)
			this.setState(newState)
			console.log(this.state)
		}
	}
	handleValue(e, key) {
		this.setState({ ...this.state, [key]: e.target.value })
	}
	render() {
		return (
			<Form>
				<Form.Field inline>
					<label>Fault Code</label>
					<Input placeholder="Code" value={this.state.code} onChange={(e) => this.handleValue(e, "code")} />
					<Button icon="save outline" onClick={this.call_createFault} />
				</Form.Field>
			</Form>
		)
	}
}

export default FaultCreate
