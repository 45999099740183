import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import {
  Grid,
  Button,
  Container,
  Select,
  Icon,
  Confirm
} from 'semantic-ui-react'
import {
  getMyCourses,
  getMyGroups,
  getAllCourses,
  createCourse,
  updateCourseIsAvailable,
  updateGroup,
  deleteCourse
} from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import BootstrapTable from 'react-bootstrap-table-next'
import { withPolyglot } from 'polyglot-react'
import moment from 'moment'
import AppError from '../components/AppError'

class Courses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableColumns: [
        {
          dataField: 'date',
          text: 'Date',
          formatter: (cell, row) => (
            <Link to={'/class/' + row.id}>
              {' '}
              {moment(cell).format(' ddd DD/MM/YYYY')}{' '}
            </Link>
          )
        },
        {
          dataField: 'label',
          text: 'Label',
          formatter: (cell, row) => <Link to={'/class/' + row.id}>{cell}</Link>
        },
        {
          dataField: 'group',
          text: 'Groupe',
          editor: {
            type: Type.SELECT,
            options: []
          }
        },
        {
          dataField: 'warmupDrills',
          text: 'Warmup Drills',
          formatter: (cell, row) => (
            <ol>
              {Array.isArray(cell) && cell[0]
                ? cell.map((drill, i, array) => (
                    <li key={drill._id}>
                      <Link to={'/drills/' + drill._id}>{drill.name}</Link>
                    </li>
                  ))
                : ''}
            </ol>
          )
        },
        {
          dataField: 'drills',
          text: 'Drills',
          formatter: (cell, row) => (
            <ol>
              {Array.isArray(cell) && cell[0]
                ? cell.map((drill, i, array) => (
                    <li key={drill._id}>
                      <Link to={'/drills/' + drill._id}>{drill.name}</Link>
                    </li>
                  ))
                : ''}
            </ol>
          )
        },
        {
          dataField: 'description',
          text: 'Description'
        },
        {
          dataField: 'players',
          text: 'Players',
          formatter: (cell, row) =>
            Array.isArray(cell) && cell[0]
              ? cell.map((user, i, array) => (
                  <Fragment key={user._id}>
                    <Link key={user._id} to={'/athlete/' + user._id}>
                      {user.name}
                    </Link>

                    {array.length === i + 1 ? '' : ', '}
                  </Fragment>
                ))
              : ''
        },
        {
          dataField: 'isAvailable',
          text: 'isAvailable',
          formatter: this.statusFormatter,
          formatExtraData: {
            true: 'icon large check square outline color-green',
            false: 'icon large square outline color-red'
          },
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false'
          }
        },
        {
          dataField: 'id',
          text: '',
          formatter: (cell, row) => (
            <Fragment>
              <Icon
                name="trash alternate outline"
                onClick={() => this.open(row.id)}
              ></Icon>
            </Fragment>
          )
        }
        /*
				,{
					dataField: "publicDescription",
					text: "Public Description"
				}
				*/
      ],
      hasError: 0,
      error: false,
      isLoading: true,
      group: '',
      myGroups: [],
      confirmOpen: false,
      markedForDel: null
    }
  }

  open = id => this.setState({ confirmOpen: true, markedForDel: id })
  close = () => this.setState({ confirmOpen: false })

  call_deleteCourse = async () => {
    let response = await deleteCourse(this.state.markedForDel)

    if (response.ok) {
      this.getData()
      this.close()
    }
  }
  call_getMyGroups = async () => {
    var response = await getMyGroups()
    if (response.ok) {
      let groups = response.data

      return groups
    } else {
      // Error
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }

  async getData() {
    var response
    let query = this.props.location.search
    const params = new URLSearchParams(query)
    let groupParam = params.get('group')
    let groupName = groupParam !== '-' ? groupParam : ''

    let groups = await this.call_getMyGroups()

    let myGroups = groups.map(x => {
      return { key: x, value: x, text: x }
    })
    myGroups.unshift({ key: '-', value: '-', text: '-' })

    const myGroups_1 = groups.map(x => {
      return { value: x, label: x }
    })

    if (localStorage.getItem('RWJRole') === 'admin') {
      response = await getAllCourses(groupName)
    } else {
      response = await getMyCourses(groupName)
    }

    if (response.ok) {
      const data = response.data.rows
      console.log(data)
      const newState = Object.assign({}, this.state, {
        tableData: data,
        isLoading: false,
        hasError: false,
        myGroups,
        group: groupName
      })
      newState.tableColumns.forEach(field => {
        if (field.dataField === 'group') {
          field.editor.options = myGroups_1
        }
      })
      this.setState(newState)
    } else {
      // Error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed

    if (prevProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      })

      this.getData()
    }
  }

  componentDidMount() {
    this.getData()
  }
  handleCellSave(dataField, id, value) {
    switch (dataField) {
      case 'isAvailable':
        updateCourseIsAvailable(id, value)
        break

      case 'group':
        updateGroup(id, value)
        break

      default:
        break
    }
  }
  statusFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <i aria-hidden="true" className={formatExtraData[cell]} />
      //<i className={"glyphicon glyphicon-chevron-up"} />
    )
  }
  call_createCourse() {
    const { polyglot } = this.props
    const defaultParams = {
      label: polyglot.t('courses.new'),
      type: '',
      description: '',
      publicDescription: ''
    }
    createCourse(defaultParams)
      .then(res => {
        if (res.ok) {
          const newId = res.data._id
          this.props.history.push('/class/' + newId)
        }
      })
      .catch()
  }
  selectGroup = (event, data) => {
    //this.setState({ value: event.target.value })

    let selectedGroup = data.value
    this.props.history.push('/classes?group=' + selectedGroup)
  }
  render() {
    const { polyglot } = this.props
    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div>
        <Container>
          <Confirm
            open={this.state.confirmOpen}
            onCancel={this.close}
            onConfirm={() => this.call_deleteCourse()}
            content={polyglot.t('generic.message.delete')}
          />

          <style>{`
						 .react-bootstrap-table{
							text-align:left;
							background-color:#fff
						 }


						.modal .content 
						{
							color:#000;
						}

						`}</style>
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <Button
                  circular
                  icon="plus"
                  onClick={() => this.call_createCourse()}
                />
                <Select
                  style={{ float: 'right' }}
                  placeholder="Group"
                  options={this.state.myGroups}
                  onChange={this.selectGroup}
                  value={this.state.group}
                />
                <BootstrapTable
                  keyField="id"
                  data={this.state.tableData}
                  columns={this.state.tableColumns}
                  bordered={false}
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    onStartEdit: (row, column, rowIndex, columnIndex) => {
                      console.log('start to edit!!!')
                    },
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      this.handleCellSave(column.dataField, row.id, newValue)
                    },
                    afterSaveCell: (oldValue, newValue, row, column) => {
                      console.log('After Saving Cell!!')
                    }
                  })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}
Courses = withPolyglot()(Courses)
export default Courses
