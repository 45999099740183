import React, { Component } from 'react'
import { Form, Button, Select } from 'semantic-ui-react'
import { createDrill } from '../services/Api'
import { withPolyglot } from 'polyglot-react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

class DrillCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      description: '',
      analysis: '',
      image: ''
    }
    this.drillCategories = [
      { key: 'warmup', text: 'warmup', value: 'warmup' },
      { key: 'pose', text: 'pose', value: 'pose' },
      { key: 'fall', text: 'fall', value: 'fall' },
      { key: 'pull', text: 'pull', value: 'pull' },
      { key: 'combined', text: 'combined', value: 'combined' },
      { key: 'strength', text: 'strength', value: 'strength' },
      { key: 'other', text: 'other', value: 'other' }
    ]
    this.call_createDrill = this.call_createDrill.bind(this)
  }
  async call_createDrill() {
    const response = await createDrill(this.state)
    if (response.ok) {
      // store the new state object in the component's state
      this.setState({
        name: '',
        category: '',
        description: '',
        analysis: '',
        image: '',
        gear: ''
      })
    }
  }
  handleValue(e, key) {
    this.setState({ ...this.state, [key]: e.target.value })
  }

  handleCategory(e, data) {
    this.setState({ category: data.value })
  }
  render() {
    const { polyglot } = this.props

    return (
      <Form inverted style={{ paddingTop: '15px' }}>
        <Form.Group>
          <Form.Input
            placeholder={polyglot.t('drillCreate.name')}
            value={this.state.name}
            onChange={e => this.handleValue(e, 'name')}
          />
          <Select
            placeholder={polyglot.t('drillCreate.category')}
            value={this.state.category}
            options={this.drillCategories}
            onChange={this.handleCategory.bind(this)}
            style={{ height: '1em' }}
          />
          <Form.TextArea
            placeholder={polyglot.t('drillCreate.description')}
            value={this.state.description}
            onChange={e => this.handleValue(e, 'description')}
          />
          <Form.Input
            placeholder={polyglot.t('drillCreate.image.url')}
            value={this.state.image}
            onChange={e => this.handleValue(e, 'image')}
          />
          <Form.Input
            placeholder={polyglot.t('drillCreate.video.url')}
            value={this.state.analysis}
            onChange={e => this.handleValue(e, 'analysis')}
          />
          <Form.Input
            placeholder={polyglot.t('drillCreate.gear')}
            value={this.state.gear}
            onChange={e => this.handleValue(e, 'gear')}
          />
          <Form.Input
            placeholder={polyglot.t('drillCreate.duration')}
            value={this.state.duration}
            onChange={e => this.handleValue(e, 'duration')}
          />
          <Form.Checkbox label="Coach specific" value={this.state.isCustom} />
        </Form.Group>
        <Button icon="save outline" onClick={this.call_createDrill} />
      </Form>
    )
  }
}

DrillCreate = withPolyglot()(DrillCreate)
export default DrillCreate
