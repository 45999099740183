import React, { Component } from "react"
import { Divider, Container, Header } from "semantic-ui-react"
import { withPolyglot } from "polyglot-react"

import AppError from "../components/AppError"
import "react-datepicker/dist/react-datepicker.css"
//import {Line} from 'react-chartjs-2';

import PaceSpeed from "../components/PaceSpeed"
import { Config } from "../config"

import ReactGA from "react-ga"
ReactGA.initialize(Config.gaId)

class PaceConverter extends Component {
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
			error: false,
			isLoading: false
		}
	}
	async componentDidMount() {
		ReactGA.pageview(window.location.pathname + window.location.search)
		/* this.props.notificationRefresh()*/
	}

	render() {
		const { polyglot } = this.props

		return this.state.isLoading ? (
			<div className="ui active centered inline loader" />
		) : this.state.hasError ? (
			<AppError message={this.state.error.problem ? this.state.error.problem : ""} />
		) : (
			<div className="page ">
				<Container text>
					<style>{`
							.next-class .item .content .header{
							border-bottom : 1px solid #636363;
						}
						`}</style>
					<Header as="h2" className="color-white center-text uppercase  top-10">
						{polyglot.t("home.action.paceSpeed")}
					</Header>
					<Divider />
					<PaceSpeed />
				</Container>
			</div>
		)
	}
}
PaceConverter = withPolyglot()(PaceConverter)
export default PaceConverter
