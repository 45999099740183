import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'polyglot-react'
import App from './App'
//import registerServiceWorker from "./registerServiceWorker"
import * as serviceWorker from './serviceWorker'
import en_us from './loc/en-us'
import fr_fr from './loc/fr-fr'
import 'moment/locale/fr'
import moment from 'moment'
import 'semantic-ui-css/semantic.min.css'

const translations = {
  'fr-fr': fr_fr,
  'en-us': en_us
}

var locale = 'fr-fr'
try {
  localStorage.getItem && (window.storage = localStorage)
} catch (exception) {}
if (window.storage) {
  window.localStorage.getItem('RWJlocale')
    ? window.localStorage.getItem('RWJlocale')
    : window.localStorage.setItem('RWJlocale', 'fr-fr')
  locale = window.localStorage.getItem('RWJlocale')
}
moment.locale(locale)

ReactDOM.render(
  <Provider locale={locale} phrases={translations[locale]}>
    <App />
  </Provider>,

  document.getElementById('root')
)
//registerServiceWorker()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister()
serviceWorker.register()
