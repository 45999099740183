import React, { Component, Fragment } from "react"
import { Message, Grid, Icon, Select, Label, Form, Segment, Placeholder, Loader } from "semantic-ui-react"
import { createPerf } from "../services/Api"
import { withPolyglot } from "polyglot-react"
import DatePicker, { registerLocale } from "react-datepicker"
import enUS from "date-fns/locale/en-US"
import fr from "date-fns/locale/fr"
import moment from "moment"

//import { Slider } from 'react-semantic-ui-range'
import "rc-slider/assets/index.css"
import Slider from "rc-slider"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
registerLocale("fr", fr)
registerLocale("en-US", enUS)
class PerfInputSet extends Component {
	constructor(props, context) {
		super(props, context)
		this.distance = [{ key: "400", text: "400m", value: "400" }, { key: "1600", text: "1600m", value: "1600" }]
		this.distanceMaxMinute = {
			_400: 5,
			_1600: 15
		}

		this.state = {
			perfDate: new Date(),
			perfTime: null,
			perfDistance: null,
			unit: "meter",
			errorFields: [],
			hasError: false,
			displayPerfHour: false,
			perfHour: 0,
			perfMinute: 0,
			perfSecond: 0,
			minuteSliderMax: 5,
			isReal: false,
			isWaiting: false,
			formError: ""
		}
		//this.onTimeInputChange = this.onTimeInputChange.bind(this);
		this.onDatePickerChange = this.onDatePickerChange.bind(this)
		this.onDistanceChange = this.onDistanceChange.bind(this)
		this.createPerf = this.createPerf.bind(this)
		this.handleRadio = this.handleRadio.bind(this)
	}

	onDatePickerChange(date) {
		this.setState({ perfDate: date })
	}
	onDistanceChange(e, { value }) {
		this.setState({
			perfDistance: value,
			minuteSliderMax: this.distanceMaxMinute["_" + value]
		})
	}
	handleRadio(e, { value }) {
		this.setState({ isReal: value })
	}
	createPerf() {
		let hasError = false
		const data = {
			time:
				+this.state.perfHour + +this.state.perfMinute + +this.state.perfSecond === 0
					? ((hasError = true),
					  this.setState(Object.assign({}, this.state, { errorFields: this.state.errorFields.concat("perfTime") })))
					: "",
			//'time':"2000",
			distance: this.state.perfDistance
				? this.state.perfDistance
				: ((hasError = true),
				  this.setState(Object.assign({}, this.state, { errorFields: this.state.errorFields.concat("perfDistance") }))),
			unit: this.state.unit,
			isReal: this.state.isReal,
			date: this.state.perfDate
				? this.state.perfDate
				: ((hasError = true),
				  this.setState(Object.assign({}, this.state, { errorFields: this.state.errorFields.concat("perfDate") }))),
			userId: localStorage.RWJUserId
		}

		let newState = Object.assign({}, this.state, { formError: "" })
		if (hasError) {
			newState = Object.assign({}, this.state, { formError: "visible" })
			return
		} else {
			newState = Object.assign({}, this.state, { formError: "" })
		}

		// minutes are worth 60 seconds. Hours are worth 60 minutes.
		data.time = +this.state.perfHour * 60 * 60 + +this.state.perfMinute * 60 + +this.state.perfSecond
		newState = Object.assign({}, this.state, { isWaiting: true })
		this.setState(newState)

		createPerf(data)
			.then(() => this.showSucess())
			.catch((error) => console.log(error))
	}
	resetFieldErrorStatus(fieldName) {
		var i = this.state.errorFields.toString().indexOf(fieldName)
		if (i !== -1) {
			this.state.errorFields.splice(i, 1)
		}
	}
	showSucess() {
		const { polyglot } = this.props
		this.setState({ isWaiting: false })
		toast.success(polyglot.t("generic.message.update"), {
			// onClose:     this.props.mustRefresh()
		})
		this.props.history.push("/userPerfs")
	}
	render() {
		const { polyglot } = this.props
		const marks = {}
		marks[this.state.minuteSliderMax] = this.state.minuteSliderMax

		if (this.state.isWaiting) {
			return (
				<Segment inverted>
					<Loader active size="massive">
						Loading
					</Loader>
					<Placeholder inverted>
						<Placeholder.Paragraph>
							<Placeholder.Line />
							<Placeholder.Line />
							<Placeholder.Line />
							<Placeholder.Line />
							<Placeholder.Line />
							<Placeholder.Line />
							<Placeholder.Line />
							<Placeholder.Line />
							<Placeholder.Line />
							<Placeholder.Line />
							<Placeholder.Line />
							<Placeholder.Line />
						</Placeholder.Paragraph>
					</Placeholder>
				</Segment>
			)
		}
		return (
			<Form size="small" className="perfs-container">
				<Message className={this.state.formError} error header={polyglot.t("home.perf.error.submission")} />
				<style>{`
						.rc-slider {
							margin-top:5px
						}
						.rc-slider-track {
							background-color: red;
						}
						.rc-slider-handle:active {
								border-color: red;
								box-shadow: 0 0 5px red;
						}
						.rc-slider-handle {

							border: solid 2px red;
							background-color: red;
							touch-action: pan-x;
							margin-top: -16px;
							width: 32px;
							height: 32px;
					}
					.rc-slider-handle:active {
						border-color: red;
						box-shadow: 0 0 5pxred;
						cursor: -webkit-grabbing;
						cursor: grabbing;
					}
					.rc-slider-handle:hover {
						border-color: red;
					}

					.ui.checkbox label,.ui.checkbox label:hover{
						background:transparent;
						color:#fff;
						border:none;
					}
				`}</style>
				<div className="perfs-input-container" style={{ margin: "0 auto" }}>
					{/**
							<Icon
								name="stopwatch"
								className="floated right"
								size="large"
								style={{ color: "rgba(210, 209, 209, 0.4)" }}
							/>
						
							<Label ribbon color="red" size="large">
								{Array.prototype.map.call(this.distance, (s) => s.text).join(", ")}{" "}
							</Label>
							 */}

					<Grid columns={2}>
						<Grid.Row>
							<Grid.Column>
								<Select
									id="perfDistance"
									className={this.state.errorFields.toString().indexOf("perfDistance") !== -1 ? "error" : ""}
									placeholder={polyglot.t("home.perf.distance")}
									options={this.distance}
									onChange={this.onDistanceChange}
									onFocus={this.resetFieldErrorStatus("perfDistance")}
								/>
							</Grid.Column>
							<Grid.Column>
								<DatePicker
									id="perfDate"
									dateFormat="dd/MM/yyyy"
									className={this.state.errorFields.toString().indexOf("perfDate") !== -1 ? "error" : ""}
									selected={this.state.perfDate}
									onChange={this.onDatePickerChange}
									onFocus={this.resetFieldErrorStatus("perfDate")}
									locale={polyglot.t("locale")}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Grid centered columns={1}>
						<Grid.Column style={{ textAlign: "center" }}>
							<Form.Group inline style={{ marginTop: "5px", textAlign: "center", display: "inline-flex" }}>
								<Form.Radio
									label={polyglot.t("home.perf.isReal")}
									value={1}
									checked={this.state.isReal === 1}
									onChange={this.handleRadio}
								/>
								<Form.Radio
									label={polyglot.t("home.perf.isReal.not")}
									value={0}
									checked={!this.state.isReal || this.state.isReal === 0}
									onChange={this.handleRadio}
								/>
							</Form.Group>
						</Grid.Column>
					</Grid>
					<Grid centered columns={1}>
						<Grid.Column style={{ textAlign: "center" }}>
							{this.state.displayPerfHour &&
								(
									<Label
										style={{ userSelect: "none" }}
										size="huge"
										onClick={() =>
											this.setState({
												perfHour: this.state.perfHour < 36 ? this.state.perfHour + 1 : this.state.perfHour
											})
										}>
										{moment("1900-01-01 00:00:00")
											.add(this.state.perfHour, "hour")
											.format("HH")}
									</Label>
								) + " :"}
							<Label
								style={{ userSelect: "none" }}
								size="huge"
								onClick={() =>
									this.setState({
										perfMinute:
											this.state.perfMinute < this.state.minuteSliderMax
												? this.state.perfMinute + 1
												: this.state.perfMinute
									})
								}>
								{moment("1900-01-01 00:00:00")
									.add(this.state.perfMinute, "minute")
									.format("mm")}
							</Label>{" "}
							:
							<Label
								style={{ userSelect: "none" }}
								size="huge"
								onClick={() =>
									this.setState({
										perfSecond: this.state.perfSecond < 59 ? this.state.perfSecond + 1 : this.state.perfSecond
									})
								}>
								{moment("1900-01-01 00:00:00")
									.add(this.state.perfSecond, "seconds")
									.format("ss")}
							</Label>
						</Grid.Column>
					</Grid>
					<Grid>
						<Grid.Row>
							<Grid.Column style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}>
								{this.state.displayPerfHour ? (
									<Fragment>
										<span style={{ userSelect: "none", color: "grey" }}>{polyglot.t("home.perf.new.hour")}</span>
										<Slider
											min={0}
											max={36}
											defaultValue={0}
											value={this.state.perfHour}
											onChange={(value) => this.setState({ perfHour: value })}
											style={{ userSelect: "none" }}
										/>
									</Fragment>
								) : null}
								<span style={{ userSelect: "none", color: "grey" }}> {polyglot.t("home.perf.new.minute")}</span>
								<Slider
									min={0}
									max={this.state.minuteSliderMax}
									marks={marks}
									defaultValue={0}
									value={this.state.perfMinute}
									onChange={(value) => this.setState({ perfMinute: value })}
									style={{ userSelect: "none" }}
								/>
								<br />
								<span style={{ userSelect: "none", color: "grey" }}>{polyglot.t("home.perf.new.seconds")}</span>
								<Slider
									min={0}
									max={59}
									marks={{ 59: 59 }}
									defaultValue={0}
									value={this.state.perfSecond}
									onChange={(value) => this.setState({ perfSecond: value })}
									style={{ userSelect: "none" }}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>

					<div className="top-20">
						<Icon link name="save outline" size="big" inverted onClick={this.createPerf} />
					</div>
				</div>
				<ToastContainer autoClose={3000} />
			</Form>
		)
	}
}

PerfInputSet = withPolyglot()(PerfInputSet)
export default PerfInputSet
