import React, { Component, Fragment } from 'react'
import { Icon, Modal } from 'semantic-ui-react'

import 'react-datepicker/dist/react-datepicker.css'
import ContentLoader from 'react-content-loader'
import ProfilePicEditor from './ProfilePicEditor'
class ProfilePic extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      isLoading: true,
      modalOpen: false,
      noCache: '?e' + Math.random()
    }
  }
  handleImageLoaded() {
    this.setState({ isLoading: false })
  }
  handleOpenModal = () => this.setState({ modalOpen: true })
  handleCloseModal = () => this.setState({ modalOpen: false })
  render() {
    return (
      <form className="ui middle aligned center ">
        <div className="ui middle aligned center  container avatar-upload">
          {this.state.isLoading && (
            <Fragment>
              <ContentLoader
                height={180}
                width={628}
                speed={1}
                primaryColor="#7d7d7d"
                secondaryColor="#ecebeb"
                style={{ maxWidth: '300px' }}
              >
                <circle cx="313" cy="90" r="90" />
              </ContentLoader>
              <div style={{ clear: 'both' }} />
            </Fragment>
          )}
          <img
            className={
              this.state.isLoading
                ? 'hidden'
                : 'visible profile-image preload-image'
            }
            onLoad={this.handleImageLoaded.bind(this)}
            alt="img"
            src={
              this.props.currentPic.indexOf('identicon') === -1 &&
              this.props.editable
                ? this.props.currentPic + this.state.noCache
                : this.props.currentPic
            }
          />
          {this.props.editable && (
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <Modal
                trigger={
                  <div
                    className="color-white"
                    style={{ minWidth: '14em' }}
                    onClick={() => this.handleOpenModal()}
                  >
                    <Icon link name="edit" size="big" inverted />
                  </div>
                }
                open={this.state.modalOpen}
                onClose={() => this.handleCloseModal()}
                size="fullscreen"
                basic
                centered={false}
              >
                <Modal.Header>
                  <Icon
                    name="arrow left"
                    style={{
                      color: 'grey',
                      position: 'absolute',
                      top: '-10px'
                    }}
                    onClick={() => this.handleCloseModal()}
                  />
                </Modal.Header>
                <Modal.Content style={{ textAlign: 'center' }}>
                  {this.props.currentPic && (
                    <ProfilePicEditor
                      currentPic={
                        this.props.currentPic + '?' + new Date().getTime()
                      }
                      history={this.props.history}
                      closeModal={this.handleCloseModal}
                      mustRefresh={this.props.mustRefresh}
                    />
                  )}
                </Modal.Content>
              </Modal>
            </div>
          )}
        </div>
      </form>
    )
  }
}
export default ProfilePic
