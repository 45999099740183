import React, { Component, Fragment } from 'react'
import { Card } from 'semantic-ui-react'
import { withPolyglot } from 'polyglot-react'
import moment from 'moment'
import ComputedAnalysis from '../components/ComputedAnalysis'

import { Config } from '../config'
import ReactGA from 'react-ga'
ReactGA.initialize(Config.gaId)

class UserAnalysis extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Fragment>
        <Card
          fluid
          className={'card-dark color-white analysis'}
          style={{ margin: 'auto' }}
        >
          <Card.Content>
            <Card.Meta className=" bottom-40">
              <span className="date" style={{ float: 'right' }}>
                {moment(this.props.date).format('LL')}
              </span>
              <div style={{ float: 'left' }}>
                <strong>{this.props.title}</strong>
              </div>
            </Card.Meta>
            <Card.Description className="color-white">
              <ComputedAnalysis
                polyglot={this.props.polyglot}
                {...this.props}
                displayAnalysis={true}
              />
            </Card.Description>
          </Card.Content>
        </Card>
      </Fragment>
    )
  }
}

UserAnalysis = withPolyglot()(UserAnalysis)
export default UserAnalysis
