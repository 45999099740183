import React, { Component } from 'react'

import { Grid, Container } from 'semantic-ui-react'
import { getMyAnalysesCreated, getAllAnalyses } from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import AnalysisCreate from '../components/AnalysisCreate'
import { withPolyglot } from 'polyglot-react'
import moment from 'moment'
import AppError from '../components/AppError'

class Analyses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableColumns: [
        {
          dataField: 'date',
          text: 'Date',
          formatter: (cell, row) => (
            <a href={'/analysis/' + row._id}>
              {' '}
              {moment(cell).format(' ddd DD/MM/YYYY')}{' '}
            </a>
          )
        },
        {
          dataField: 'user',
          text: 'User',
          formatter: (cell, row) => cell.name
        },
        {
          dataField: 'title',
          text: 'Title',
          formatter: (cell, row) => (
            <a href={'/analysis/' + row._id}> {cell} </a>
          )
        },
        {
          dataField: 'isAvailable',
          text: 'Etat',
          formatter: this.statusFormatter,
          formatExtraData: {
            true: 'icon large check square outline color-green',
            false: 'icon large square outline color-red'
          }
        }
      ],
      data: [],
      hasError: 0,
      error: false,
      isLoading: true
    }
  }

  async getData() {
    var response

    if (localStorage.getItem('RWJRole') === 'admin') {
      response = await getAllAnalyses()
    } else {
      response = await getMyAnalysesCreated()
    }

    if (response.ok) {
      const data = response.data
      const newState = Object.assign({}, this.state, {
        tableData: data,
        isLoading: false,
        hasError: false
      })
      // store the new state object in the component's state
      this.setState(newState)
      this.setState({ redirectToReferrer: true })
    } else {
      // Error
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }
  componentDidMount() {
    this.getData()
  }
  statusFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <i aria-hidden="true" className={formatExtraData[cell]} />
      //<i className={"glyphicon glyphicon-chevron-up"} />
    )
  }

  render() {
    //		const { polyglot } = this.props;
    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div>
        <Container>
          <style>{`
						 .react-bootstrap-table{
							text-align:left;
							background-color:#fff
						 }

						`}</style>
          {<AnalysisCreate history={this.props.history} />}
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                {this.state.tableData && (
                  <BootstrapTable
                    keyField="_id"
                    data={this.state.tableData}
                    columns={this.state.tableColumns}
                    bordered={false}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}
Analyses = withPolyglot()(Analyses)
export default Analyses
