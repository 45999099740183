import React, { Component } from "react"
import { Grid, Container } from "semantic-ui-react"
import { getFaults } from "../services/Api"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import FaultCreate from "../components/FaultCreate"
import { withPolyglot } from "polyglot-react"
import AppError from "../components/AppError"
class Faults extends Component {
	constructor(props) {
		super(props)
		this.state = {
			faults: [],
			clues: [],
			tableData: [],
			tableColumns: [
				{
					dataField: "code",
					text: "Code"
				}
			],
			hasError: 0,
			error: false,
			isLoading: true
		}
	}

	translator(cell, row) {
		// console.log(this.state.polyglot)
		/* const { polyglot } = this.props;
  return( 	 polyglot.t('login.login') )
  */
	}

	async getData() {
		const response = await getFaults()
		if (response.ok) {
			const { polyglot } = this.props

			const data = response.data.rows
			const data_translated = data.map((obj) => {
				var rObj = {}
				rObj["id"] = obj.id
				rObj["code"] = polyglot.t("faults." + obj.code)
				return rObj
			})

			const newState = Object.assign({}, this.state, {
				tableData: data_translated,
				isLoading: false,
				hasError: false
			})
			// store the new state object in the component's state
			this.setState(newState)
			this.setState({ redirectToReferrer: true })
		} else {
			// Error
			const newState = Object.assign({}, this.state, {
				isLoading: false,
				hasError: true,
				error: response
			})
			this.setState(newState)
		}
	}
	componentDidMount() {
		this.getData()
	}

	render() {
		return this.state.isLoading ? (
			<div className="ui active centered inline loader" />
		) : this.state.hasError ? (
			<AppError message={this.state.error.problem ? this.state.error.problem : ""} />
		) : (
			<div>
				<Container>
					<style>{`
						 .react-bootstrap-table{
							margin-top:00px;
							text-align:left;
							background-color:#fff
						 }
						`}</style>
					<Grid padded>
						{localStorage.getItem("RWJRole") === "admin" ? (
							<Grid padded columns="equal">
								<Grid.Column style={{ backgroundColor: "#fff", marginTop: "10px" }}>
									<FaultCreate />
								</Grid.Column>
							</Grid>
						) : null}
						<Grid.Row>
							<Grid.Column>
								<BootstrapTable
									keyField="id"
									data={this.state.tableData}
									columns={this.state.tableColumns}
									bordered={false}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</div>
		)
	}
}

Faults = withPolyglot()(Faults)
export default Faults
