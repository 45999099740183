import React, {Fragment } from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
	  {...rest}
	  render={props =>
			localStorage.getItem('RWJToken')  ?
			(
				(localStorage.RWJUserIsActive && JSON.parse(localStorage.RWJUserIsActive) === true) || props.location.pathname ==="/pending" ?
				(
          <Fragment>
            <Component {...props} />
          </Fragment>
				)
				:
				(
					<Redirect
					to={{
						pathname: "/pending",
						state: { from: props.location }
					}}
					/>
				)
			)
			:
			(
				<Redirect
				to={{
					pathname: "/login",
					state: { from: props.location }
				}}
				/>
			)
	  }
	/>
  );
export default PrivateRoute;
