import React, { Component, Fragment } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  NavLink
} from 'react-router-dom'

// This component ios HoC that prevents the user from accessing a route if he's not logged in
import PrivateRoute from './services/PrivateRoute'
import { withPolyglot } from 'polyglot-react'
import Home from './screens/Home'
import Profile from './screens/Profile'
import Login from './screens/Login'
import Signup from './screens/Signup'
import PasswordForgot from './screens/PasswordForgot'
import Pending from './screens/Pending'
import Athletes from './screens/Athletes'
import Athlete from './screens/Athlete'
import Courses from './screens/Courses'
import Course from './screens/Course'
import UserTrainings from './screens/UserTrainings'
import UserTraining from './screens/UserTraining'

import UserTrainingLog from './screens/UserTrainingLog'
import Faults from './screens/Faults'
import Drills from './screens/Drills'
import Drill from './screens/Drill'
import DrillsByCategory from './screens/DrillsByCategory'
import DrillsCategory from './screens/DrillsCategory'
import NotFound from './screens/NotFound'
import UserNotifications from './screens/UserNotifications'
import Notification from './screens/Notification'
import NotificationCenter from './screens/NotificationCenter'
import Analyses from './screens/Analyses'
import UserAnalyses from './screens/UserAnalyses'
import UserPerfs from './screens/UserPerfs'
import UserNewPerf from './screens/UserNewPerf'
import Analysis from './screens/Analysis'
import PaceConverter from './screens/PaceConverter'
import UserProtocol from './screens/UserProtocol'
import Protocols from './screens/Protocols'
import Protocol from './screens/Protocol'
import RunnerProfile from './screens/RunnerProfile'
import NotificationBell from './components/NotificationBell'
import 'semantic-ui-css/semantic.min.css'
import AppSidebar from './components/AppSidebar'
import PWAPrompt from 'react-ios-pwa-prompt'
//import Footer from './components/Footer'
import './App.css'
import {
  Menu,
  Container,
  Image,
  Icon,
  Sidebar,
  Message
} from 'semantic-ui-react'
class App extends Component {
  showSettings(event) {
    event.preventDefault()
  }

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      notificationCount: false
    }
  }

  handleShowClick = () => this.setState({ visible: true })
  handleSidebarHide = () => this.setState({ visible: false })

  render() {
    const { polyglot } = this.props
    try {
      localStorage.getItem && (window.storage = localStorage)
    } catch (exception) {}

    const { visible } = this.state
    return (
      <Fragment>
        {window.storage && (
          <Router>
            <div className="App">
              <Menu fixed="top" inverted className="main-menu">
                <Container>
                  <Menu.Item position="left" style={{ marginLeft: '5px' }}>
                    <Switch>
                      <Route
                        path={[
                          // '/userTraining/', //provoque de duplications dans l'historique a cause des slides et des # dans l'url
                          '/userTrainingLog/',
                          '/drills/',
                          '/userperfs',
                          '/notifications',
                          '/paceConverter',
                          '/userAnalyses',
                          '/userNewPerf',
                          '/userProtocol',
                          '/runnerProfile'
                        ]}
                        render={props => (
                          <span
                            onClick={() => {
                              props.history.goBack()
                            }}
                            style={{ paddingLeft: '5px' }}
                          >
                            <Icon
                              name="arrow left"
                              size="large"
                              className="floated left  top-10"
                              style={{ color: 'grey' }}
                            />
                          </span>
                        )}
                      />
                      <Route
                        path={['/todayTraining/']} //acces par la home page
                        render={props => (
                          <Link to="/" style={{ paddingLeft: '5px' }}>
                            <Icon
                              name="arrow left"
                              size="large"
                              className="floated left  top-10"
                              style={{ color: 'grey' }}
                            />
                          </Link>
                        )}
                      />
                      <Route
                        path={['/userTraining/']}
                        render={props => (
                          <Link
                            to="/userTrainings"
                            style={{ paddingLeft: '5px' }}
                          >
                            <Icon
                              name="arrow left"
                              size="large"
                              className="floated left  top-10"
                              style={{ color: 'grey' }}
                            />
                          </Link>
                        )}
                      />
                      <Route>
                        {/*(localStorage.getItem("RWJRole") === "admin" ||
													localStorage.getItem("RWJRole") === "coach") && (
													<Icon name="bars" size="large" onClick={this.handleShowClick} className="menu-btn" />
													)*/}
                        <span
                          onClick={this.handleShowClick}
                          style={{ paddingLeft: '5px' }}
                        >
                          <Icon name="bars" size="large" className="menu-btn" />
                        </span>
                      </Route>
                    </Switch>
                  </Menu.Item>
                  <Menu.Item as="a" header href="/">
                    <Image className="app-logo" src="/logo-app-inline.png" />
                  </Menu.Item>

                  <Switch>
                    <Route
                      path={['/notifications']}
                      render={props => (
                        <Menu.Item
                          position="right"
                          onClick={() => props.history.goBack()}
                          style={{ marginRight: '15px' }}
                        >
                          <NotificationBell />
                        </Menu.Item>
                      )}
                    />
                    <Route>
                      <Menu.Item
                        as={Link}
                        position="right"
                        to="/notifications"
                        style={{ marginRight: '10px' }}
                      >
                        <NotificationBell />
                      </Menu.Item>
                    </Route>
                  </Switch>
                </Container>
              </Menu>
              <Menu fixed="bottom" inverted className="main-menu bottom">
                <Container>
                  <Menu.Item
                    position="left"
                    style={{ marginLeft: '25px' }}
                    as={NavLink}
                    to="/userTrainings"
                  >
                    <Icon
                      name="calendar alternate outline"
                      size="large"
                      className="menu-btn"
                    />
                  </Menu.Item>

                  <Switch>
                    <Route
                      render={props => {
                        return (
                          <Menu.Item
                            as={Link}
                            to="/"
                            className={
                              props.history.location.pathname !==
                                '/userTrainings' &&
                              props.history.location.pathname !== '/profile'
                                ? 'active'
                                : ''
                            }
                          >
                            <Icon
                              name="home"
                              size="large"
                              className="menu-btn"
                            />
                          </Menu.Item>
                        )
                      }}
                    />
                  </Switch>

                  <Menu.Item
                    as={NavLink}
                    position="right"
                    to="/profile"
                    style={{ marginRight: '25px' }}
                  >
                    <Icon name="user" size="large" className="menu-btn" />
                  </Menu.Item>
                </Container>
              </Menu>

              {localStorage.getItem('RWJToken') && (
                <AppSidebar
                  visible={visible}
                  handleSidebarHide={this.handleSidebarHide}
                />
              )}

              {/*<Sidebar.Pushable as={Container} fluid style={{height:'100vh'}}>*/}
              <Sidebar.Pushable as={Container} fluid>
                <Sidebar.Pusher dimmed={visible} style={{ height: '100%' }}>
                  <Container
                    fluid
                    style={{ marginTop: '46px', paddingBottom: '20px' }}
                    className="main-content"
                  >
                    <Switch>
                      {/* A user can't go to the HomePage if is not authenticated */}
                      <Route path="/login" component={Login} />
                      <Route path="/signup" component={Signup} />
                      <Route
                        path="/forgot/:token?"
                        component={PasswordForgot}
                      />

                      <PrivateRoute path="/pending" component={Pending} exact />
                      <PrivateRoute path="/" component={Home} exact />
                      <PrivateRoute path="/index.html" component={Home} exact />
                      <PrivateRoute
                        path="/home/:action?"
                        component={Home}
                        exact
                      />
                      <PrivateRoute path="/profile" component={Profile} exact />
                      {/*{<PrivateRoute path='/notifications'  component={(props) =>
                    (<Notifications resetNotificationCount={()=>this.resetNotificationCount}  {...props} />)} exact/>}*/}
                      <PrivateRoute
                        path="/notifications"
                        component={UserNotifications}
                        exact
                      />
                      <PrivateRoute
                        path="/notification-center"
                        component={NotificationCenter}
                        exact
                      />
                      <PrivateRoute
                        path="/notification"
                        component={Notification}
                        exact
                      />
                      <PrivateRoute
                        path="/athletes"
                        component={Athletes}
                        exact
                      />
                      <PrivateRoute
                        path="/athlete/:id"
                        component={Athlete}
                        exact
                      />
                      <PrivateRoute
                        path="/analyses"
                        component={Analyses}
                        exact
                      />
                      <PrivateRoute
                        path="/userAnalyses"
                        component={UserAnalyses}
                        exact
                      />
                      <PrivateRoute
                        path="/analysis/:id"
                        component={Analysis}
                        exact
                      />
                      <PrivateRoute path="/classes" component={Courses} exact />
                      <PrivateRoute
                        path="/userTrainings"
                        component={UserTrainings}
                        exact
                      />
                      <PrivateRoute
                        path="/userTraining/:trainingId?"
                        component={UserTraining}
                        exact
                      />
                      <PrivateRoute
                        path="/todayTraining/:trainingId?"
                        component={UserTraining}
                        exact
                      />
                      <PrivateRoute
                        path="/userTrainingLog/:trainingId?"
                        component={UserTrainingLog}
                        exact
                      />
                      <PrivateRoute
                        path="/class/:id"
                        component={Course}
                        exact
                      />
                      <PrivateRoute path="/faults" component={Faults} exact />
                      <PrivateRoute
                        path="/drills/category"
                        component={DrillsByCategory}
                        exact
                      />
                      <PrivateRoute
                        path="/drills/category/:category"
                        component={DrillsCategory}
                        exact
                      />
                      <PrivateRoute path="/drills" component={Drills} exact />
                      <PrivateRoute
                        path="/drills/:id"
                        component={Drill}
                        exact
                      />
                      <PrivateRoute
                        path="/paceConverter"
                        component={PaceConverter}
                        exact
                      />
                      <PrivateRoute
                        path="/userPerfs"
                        component={UserPerfs}
                        exact
                      />
                      <PrivateRoute
                        path="/userNewPerf"
                        component={UserNewPerf}
                        exact
                      />
                      <PrivateRoute
                        path="/userProtocol/:id?"
                        component={UserProtocol}
                        exact
                      />
                      <PrivateRoute
                        path="/runnerProfile"
                        component={RunnerProfile}
                        exact
                      />

                      <PrivateRoute
                        path="/protocols"
                        component={Protocols}
                        exact
                      />
                      <PrivateRoute
                        path="/protocols/:id"
                        component={Protocol}
                        exact
                      />

                      <Route component={NotFound} />
                    </Switch>
                  </Container>
                </Sidebar.Pusher>
              </Sidebar.Pushable>
              <PWAPrompt
                debug={false}
                timesToShow={5}
                copyTitle={polyglot.t('app.ios.prompt.title')}
                copyBody={polyglot.t('app.ios.prompt.body')}
                copyShareButtonLabel={polyglot.t(
                  'app.ios.prompt.shareButtonLabel'
                )}
                copyAddHomeButtonLabel={polyglot.t(
                  'app.ios.prompt.addHomeButtonLabel'
                )}
                copyClosePrompt={polyglot.t('app.ios.prompt.closePrompt')}
              />
            </div>
          </Router>
        )}
        {!window.storage && (
          <Container text style={{ marginTop: '40px' }}>
            <Message negative>
              <Message.Header>
                Cookies désactivés / Disabled cookies
              </Message.Header>
              <p>
                Cette application utilise les cookies uniquement pour vous
                authentifier, veuillez les reactiver
              </p>
              <p>
                This app uses cookies only for authentication purpose, please
                allow them.
              </p>
            </Message>
          </Container>
        )}
      </Fragment>
    )
  }
}

App = withPolyglot()(App)

export default App
