import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Button, Container } from 'semantic-ui-react'
import {
  getAllProtocols,
  createProtocol,
  updateProtocolIsAvailable
} from '../services/Api'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import { withPolyglot } from 'polyglot-react'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter'
import moment from 'moment'
import AppError from '../components/AppError'

const selectOptions = {
  neutral: 'Neutral',
  pose: 'Pose',
  fall: 'Fall',
  pull: 'Pull'
}
class Protocols extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableColumns: [
        {
          dataField: 'date',
          text: 'Date',
          editable: false,
          formatter: (cell, row) => (
            <Link to={'/protocols/' + row.id}>
              {' '}
              {moment(cell).format(' ddd DD/MM/YYYY')}{' '}
            </Link>
          )
        },
        {
          dataField: 'label',
          text: 'Label',
          editable: false,
          formatter: (cell, row) => (
            <Link to={'/protocols/' + row.id}>{cell}</Link>
          )
        },
        {
          dataField: 'type',
          text: 'Type ',
          editable: false,
          filter: selectFilter({
            options: selectOptions
          })
        },
        {
          dataField: 'warmupDrills',
          text: 'Warmup Drills',
          editable: false,
          formatter: (cell, row) => (
            <ol>
              {Array.isArray(cell) && cell[0]
                ? cell.map((drill, i, array) => (
                    <li key={drill._id}>
                      <Link to={'/drills/' + drill._id}>{drill.name}</Link>
                    </li>
                  ))
                : ''}
            </ol>
          )
        },
        {
          dataField: 'drills',
          text: 'Drills',
          editable: false,
          formatter: (cell, row) => (
            <ol>
              {Array.isArray(cell) && cell[0]
                ? cell.map((drill, i, array) => (
                    <li key={drill._id}>
                      <Link to={'/drills/' + drill._id}>{drill.name}</Link>
                    </li>
                  ))
                : ''}
            </ol>
          )
        },
        {
          dataField: 'isAvailable',
          text: 'isAvailable',
          formatter: this.statusFormatter,
          formatExtraData: {
            true: 'icon large check square outline color-green',
            false: 'icon large square outline color-red'
          },
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false'
          }
        }
        /*
				,{
					dataField: "publicDescription",
					text: "Public Description"
				}
				*/
      ],
      hasError: 0,
      error: false,
      isLoading: true
    }
  }

  async getData() {
    var response = await getAllProtocols()

    if (response.ok) {
      const data = response.data.rows
      console.log(data)

      // store the new state object in the component's state
      this.setState({
        tableData: data,
        isLoading: false,
        hasError: false,
        redirectToReferrer: true
      })
      this.setState({ redirectToReferrer: true })
    } else {
      // store the new state object in the component's state
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
    }
  }
  componentDidMount() {
    this.getData()
  }
  statusFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <i aria-hidden="true" className={formatExtraData[cell]} />
      //<i className={"glyphicon glyphicon-chevron-up"} />
    )
  }
  handleCellSave(dataField, id, value) {
    switch (dataField) {
      case 'isAvailable':
        updateProtocolIsAvailable(id, value)
        break
      default:
        break
    }
  }
  call_createProtocol() {
    const { polyglot } = this.props
    const defaultParams = {
      label: polyglot.t('protocol.new')
    }
    createProtocol(defaultParams)
      .then(res => {
        if (res.ok) {
          const newId = res.data._id
          this.props.history.push('/protocols/' + newId)
        }
      })
      .catch()
  }

  render() {
    //		const { polyglot } = this.props;
    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div>
        <Container>
          <style>{`
						 .react-bootstrap-table{
							text-align:left;
							background-color:#fff
						 }
						 .sr-only{display:none}

						`}</style>
          <Grid padded>
            <Grid.Row>
              <Grid.Column>
                <Button
                  circular
                  icon="plus"
                  onClick={() => this.call_createProtocol()}
                />
                <BootstrapTable
                  keyField="id"
                  data={this.state.tableData}
                  columns={this.state.tableColumns}
                  bordered={false}
                  filter={filterFactory()}
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    onStartEdit: (row, column, rowIndex, columnIndex) => {
                      console.log('start to edit!!!')
                    },
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      this.handleCellSave(column.dataField, row.id, newValue)
                    },
                    afterSaveCell: (oldValue, newValue, row, column) => {
                      console.log('After Saving Cell!!')
                    }
                  })}
                />
                {/*
                <BootstrapTable
                  keyField="id"
                  data={this.state.tableData}
									columns={this.state.tableColumns}
								
                  bordered={false}
                  cellEdit={cellEditFactory({
                    mode: "click",
                    blurToSave: true,
                    onStartEdit: (row, column, rowIndex, columnIndex) => {
                      console.log("start to edit!!!")
                    },
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      this.handleCellSave(column.dataField, row.id, newValue)
                    },
                    afterSaveCell: (oldValue, newValue, row, column) => {
                      console.log("After Saving Cell!!")
                    }
									})}
							
								/>
									*/}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}
Protocols = withPolyglot()(Protocols)
export default Protocols
