const en_us = {
  undefined: '',
  locale: 'en-US',
  'generic.message.update': 'Update done',
  'generic.message.created': 'Creation done',
  'generic.message.error': 'Opération failed',
  'generic.message.done': 'Modification effectuée',
  'generic.message.empty': 'Nothing to display yet',
  'generic.message.delete': 'This will delete the element! ',
  'generic.message.notfound':
    'Sorry, we could not find the information you asked ... ',
  'app.proud.partner': 'Proud partner of ',
  'generic.select.empty': '- Select one -',
  'generic.select.label': 'Choose',

  'error.oops': 'Oops,',
  'error.message': 'Something went wrong...',
  'error.retry': 'Retry',

  'sidebar.home': 'Home',
  'sidebar.trainings': 'Trainings',
  'sidebar.profile': 'Profile',
  'sidebar.notification.center': 'Notification Center',
  'sidebar.courses': 'Classes',
  'sidebar.athletes': 'Athletes',
  'sidebar.analyses': 'Technique Analysis',
  'sidebar.faults': 'Faults',
  'sidebar.clues': 'Cues',
  'sidebar.drills': 'Drills',

  'sidebar.protocols': 'Protocols',
  'sidebar.update.check': 'Update check',
  'sidebar.sign_out': 'Logout',

  'athlete.courses': 'Training',
  'app.ios.prompt.title': 'Add to Home Screen',
  'app.ios.prompt.body': `This website has app functionality.
	Add it to your home screen to access it easily, in fullscreen`,
  'app.ios.prompt.shareButtonLabel': `1) Press the 'Share' button`,
  'app.ios.prompt.addHomeButtonLabel': `2) Press 'Add to Home Screen`,
  'app.ios.prompt.closePrompt': 'Cancel',

  'login.login': 'Login',
  'login.email': 'Email',
  'login.password': 'Password',
  'login.signup': 'Register',
  'login.password.forgot': 'Forgotten password',

  'login.message.error': 'Autenthication failed',

  'forgot.email': 'Email',
  'forgot.reset': 'Reset',
  'forgot.login': "S'identifier",
  'forgot.message.error': 'Unknown mail address!',
  'forgot.password.new': 'New password',
  'forgot.message.mail.sent': `An email has been sent to %{email} to reset your password.
Check you spam folder.`,
  'forgot.message.error.invalid.link': 'Invalid link !',
  'forgot.password.change': 'Change',
  'forgot.message.fields.password.short':
    'Votre mot de passe devrait contenir au moins 6 caractères',
  'forgot.message.password.changed':
    'Your password has been successfully reset, you can login with this new password',

  'signup.name': 'Firstname Lastname',
  'signup.email': 'Email',
  'signup.password': 'Password',
  'signup.password.confirm': 'Password (again)',
  'signup.register': 'Register',
  'signup.login.go': 'Already registered ? Login here',

  'signup.message.fields.mandatory': 'Please fill all fields',
  'signup.message.fields.email.wrong': 'Email format not valid',
  'signup.message.fields.email.exists': 'Email déja enregistré',
  'signup.message.fields.password.short':
    'Your password should be at least 6 characters',

  'profile.name': 'Firstname Lastname',
  'profile.email': 'Email',
  'profile.gender': 'Gender',
  'profile.country': 'Country',
  'profile.language': 'Language',
  M: 'Male',
  F: 'Female',
  O: 'Other',
  'profile.message.update.done': 'Profile updated',

  'pending.label': 'Welcome %{name} !',
  'pending.content': `Your registration is pending validation from your coach.
Please be patient !`,

  'home.coach': 'Coach :',
  'home.slowingFactor': 'Slowing factor :',
  'home.improvement.area': 'Improvement area:',
  'home.improvement.area.speed': 'Speed',
  'home.improvement.area.endurance': 'Endurance',

  'usertraining.course.warmup': 'Warm-up',
  'usertraining.course.technique': 'Technique',
  'usertraining.course.warmup.technique': 'Warm-up & Technique',
  'usertraining.course.training': 'Training',
  'usertraining.course.focus': 'Focus / Comments',
  'usertraining.course.cooldown': 'Cool down',
  'usertraining.course.speed': 'Speed',
  'usertraining.course.pace': 'Pace',

  'home.action.times': 'My times',
  'home.action.times.add': 'Add',
  'home.action.training.next': 'Next session',
  'home.action.runner.profile': 'Runner profile',
  'home.action.course': 'Session',
  'home.action.book': 'Book',
  'home.action.analysis': 'Analysis',
  'home.action.drills': 'Drills',
  'home.action.stats': 'Stats',
  'home.action.paceSpeed': 'Converter',
  'home.action.warmup.technique': 'Warmup & Technique',

  'home.class.next': 'Next session',
  'home.class.next.none': 'No session to display yet',

  'home.perf.time': 'time',
  'home.perf.date': 'date',
  'home.perf.distance': 'distance',

  'home.perf.new': 'New time',
  'home.perf.isReal': 'Done',
  'home.perf.isReal.not': 'Working pace',
  'home.perf.new.hour': 'hours',
  'home.perf.new.minute': 'minutes',
  'home.perf.new.seconds': 'seconds',
  'home.perf.all': 'Lastest times',
  'home.perf.none': 'No times to display yet',
  'home.perf.log': 'Previous times',
  'home.perf.error.submission': 'Please fill all mandatory fields',

  'home.stats.all': 'Statistics',
  'home.stats.6months': 'Last 6 months',

  'home.technique.analysis': 'Technique analysis',
  'home.technique.analysis.none': `No technique analysis yet?
   Ask the coach!`,

  'paceSpeed.minutes': 'minutes',
  'paceSpeed.seconds': 'seconds',

  'faults.label': 'Area of improvement:',
  'faults.scratching_landing': 'Scratchy landing',
  'faults.overpulling': 'Overpulling',
  'faults.excessive_arm_movement': 'Excessive arm movement',
  'faults.vertical_oscillation': 'Vertical oscillation ',
  'faults.resistance_to_falling': 'Resistance to falling',
  'faults.active_landing': 'Active landing',
  'faults.heel_striking': 'Heel striking',
  'faults.bending_waist': 'Bending at the waist',
  'faults.overstriding': 'Overstriding',
  'faults.pulling_late': 'Pulling late',
  'faults.noisy_landing': 'Noisy landing',

  'AdviceEdit.title': 'Clue from the coach',

  'runnerprofile.runner.profile': 'Runner profile',
  'runnerprofile.fatigueFactor': 'Fatigue factor',
  'runnerprofile.noPerfs': `No data available,
 enter your 400m and 1600m times`,
  'runnerprofile.improvement.area': 'Improvement area',
  'runnerprofile.improvement.area.speed': 'Speed',
  'runnerprofile.improvement.area.endurance': 'Endurance',
  'runnerprofile.main.deviation': `Main technique deviation`,
  'runnerprofile.technique.focus': `Technique focus configured`,

  'runnerprofile.perf.all': 'Lastest times',

  'courses.new': 'New session',
  'course.isAvailable': 'Published',
  'course.isAvailable.not': 'Draft',
  'course.attendees': 'Attendees',
  'course.group': 'Group',

  'course.group.or.new': ' Or create ',
  'course.warmup': 'Warm-up',
  'course.warmup.drills': 'Warm up & Technique',
  'course.training': 'Training',
  'course.drills': 'Drills',
  'course.technique': 'Technique',
  'course.strength': 'Strength',
  'course.mobility': 'Mobility',

  'course.focus': 'Focus/Comments',
  'course.cooldown': 'Cool-Down',
  'course.athletes.my': 'My athletes',
  'course.description': 'Description',
  'course.publicDescription': 'Public description',

  'course.trainingLogs': 'Comments',
  'course.drill.name': 'Name',
  'course.drill.category': 'Category',
  'course.drill.description': 'Description',
  'course.drill.duration': 'Duration',
  'course.drill.video.url': 'Analysis',
  'course.notification.default.title': 'New training session',
  'course.notification.default.message':
    'Hello, your next training session is available!',

  'help.slowingFactorAndProfile.header': 'Slowing factor and improvement area',
  'help.slowingFactorAndProfile.content': `Your slowing factor is computed with your latest times done on 400 and 1600m.
  If done in :
  - less than 30 days, the factor is accurate (green check mark),
  - less than 60 days the factor is average (orange minus sign).
  - more than 60 days not very accurate ( red exclamation point).
`,

  'help.slowingFactorAndProfile.content.bak': `Your slowing factor is computed with your latest times done on 400 and 1600m.
  If done in :
  - less than 30 days, the factor is accurate (green check mark),
  - less than 60 days the factor is average (orange minus sign).
  - more than 60 days not very accurate ( red exclamation point).
  A factor of 20 and shows a lack of speed.
  A factor above 20 shows a lack of endurance.`,

  'help.framecount.header': 'Frame count',
  'help.framecount.content': `The framecount is how we access the quality of a stride.
  The goal with a 60 fps camera is 2:2
  `,

  'help.faults.overstriding.content': `Overstring reduces efficiency.
  Landing to far ahead of the body leads to a breaking effect.
  Trying to push off, increases the delay to get back in pose  `,

  'help.faults.scratching_landing.content': 'Scratchy landing',
  'help.faults.overpulling.content': 'Overpulling',
  'help.faults.excessive_arm_movement.content': 'Excessive arm movement',
  'help.faults.vertical_oscillation.content': `Vertical oscillation is a loss of efficicency in a movement that should be as linear as possible.
  The cause may be an attempt to push off with the foot or the quadriceps or a constant tension on the foot with a heel that never touches the ground.`,
  'help.faults.resistance_to_falling.content': `Resistance to falling is the main raison for a lack of speed.
  Running is free falling, that fall is the only way to make speed.`,
  'help.faults.active_landing.content': 'Active landing',
  'help.faults.heel_striking.content': 'Heel striking',
  'help.faults.bending_waist.content': 'Bending at the waist',
  'help.faults.pulling_late.content': 'Pulling late',
  'help.faults.noisy_landing.content': 'Noisy landing',

  'notifications.none': 'No notification to display yet !',
  'notification.athletes.my': 'My athletes',
  'notification.sendTo': 'Send to',
  notification: 'Notification',
  'notification.content': 'Content',
  'notification.title': 'Title',
  'notification.message': 'Message',

  'drillByCategory.title': 'Drills',
  'drillByCategory.warmup': 'Warm Up',
  'drillByCategory.pose': 'Pose',
  'drillByCategory.fall': 'Fall',
  'drillByCategory.pull': 'Pull',
  'drillByCategory.combined': 'Combined',
  'drillByCategory.strength': 'Strength',
  'drillByCategory.correction': 'Correction',

  'drillCreate.name': 'Drill name',
  'drillCreate.category': 'Category',
  'drillCreate.description': 'Description',
  'drillCreate.image.url': 'Image url ',
  'drillCreate.video.url': 'Video url',
  'drillCreate.duration': 'Duration',
  'drillCreate.gear': 'Gear',

  'drillVideo.setup': 'Setup',
  'drillVideo.execution': 'Execution',
  'drillVideo.focus': 'Focus',
  'drillVideo.instructions': 'Instructions',

  'usertrainings.none': 'No training to display yet',
  'usertrainings.nextWeeks': 'Next weeks',
  'traininglog.comments.label': 'Times, feelings ...',
  'traininglog.comments.placeholder': 'Times, feelings ...',
  'traininglog.done.label': 'Done',
  'traininglog.done.not.label': ' Not done',
  'traininglog.date.label': 'on',
  'analysis.fps': 'frames/s',

  'protocol.warmup': 'Warm-up',
  'protocol.drills': 'Technique',
  'protocol.focus': 'Focus',
  'protocol.new': 'New protocol',
  'protocol.isAvailable.not': 'Draft',
  'protocol.isAvailable': 'Published',
  'protocol.type': 'Type',

  'analysisCreate.athlete': "Athlete's name",
  'analysisCreate.urlCode': 'Youtube video code',

  'analysis.analysis': 'Analysis',
  'analysis.isAvailable.not': 'Draft',
  'analysis.isAvailable': 'Published',
  'analysis.framecount': 'Framecount',
  'analysis.frameToPose': 'Frames to Pose',
  'analysis.frameFromPose': 'Frames from Pose',
  'analysis.analysis.display': 'View analysis',
  'analysis.score.display': 'Back to scores',
  'analysis.technique.score': 'Technique score',

  'analysis.time.initialContact': 'Time at initial contact',
  'analysis.time.inPose': 'Time at Pose',
  'analysis.time.endFall': 'Time at end of Fall',
  'analysis.time.lastContact': 'Time at end of contact',
  'analysis.count.frames': 'Count frames',

  'analysis.execution.cadence': 'Cadence',
  'analysis.cadence.mark': 'Mark',
  'analysis.cadence.compute': 'Compute',

  'analysis.execution.pose': 'POSE',
  'analysis.execution.poseExecutionLanding': 'Initial contact',
  'analysis.execution.poseExecutionLanding.1': 'Far from body',
  'analysis.execution.poseExecutionLanding.2': 'Close to body',
  'analysis.execution.poseExecutionLanding.3': 'Under the body',

  'analysis.execution.poseExecutionKnee': 'Knee',
  'analysis.execution.poseExecutionKnee.1': 'Locked',
  'analysis.execution.poseExecutionKnee.3': 'Bent',

  'analysis.execution.poseExecutionVertical': 'Verticality at Pose',
  'analysis.execution.poseExecutionVertical.1': 'Drastically ahead',
  'analysis.execution.poseExecutionVertical.2': 'Ahead',
  'analysis.execution.poseExecutionVertical.3': 'Vertical',

  'analysis.execution.fall': 'FALL',
  'analysis.execution.fallExecutionAlignment': 'Body alignement',
  'analysis.execution.fallExecutionAlignment.1': 'Bent at the waist',
  'analysis.execution.fallExecutionAlignment.2': 'Slightly bent at the waist',
  'analysis.execution.fallExecutionAlignment.3': 'Aligned',
  'analysis.execution.fallExecutionTime': 'Maintaining Pose',
  'analysis.execution.fallExecutionTime.1': 'Short',
  'analysis.execution.fallExecutionTime.2': 'Average',
  'analysis.execution.fallExecutionTime.3': 'Long',
  'analysis.execution.fallExecutionFrames': `Frames falling`,
  'analysis.execution.pull': 'PULL',
  'analysis.execution.pullExecutionTiming': 'Timing',
  'analysis.execution.pullExecutionTiming.1': 'Late',
  'analysis.execution.pullExecutionTiming.2': 'Average',
  'analysis.execution.pullExecutionTiming.3': 'Good',
  'analysis.execution.pullExecutionLegBent': 'Trailing leg',
  'analysis.execution.pullExecutionLegBent.1': 'Extended',
  'analysis.execution.pullExecutionLegBent.3': 'Bent',

  'analysis.execution.pullExecutionDirection': 'Direction',
  'analysis.execution.pullExecutionDirection.1': 'Back',
  'analysis.execution.pullExecutionDirection.3': 'Towards the hips',

  'analysis.execution.pullBreakingAngle': 'Breaking angle',

  'analysis.fault.focus': 'Fault & Technique focus',
  'analysis.fault': 'Main fault',
  'analysis.focus': 'Main improvement area',

  'drills.details': '********************',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.label': 'Banded run',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.setup': 'Pose',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.setup.2':
    'Knee of support leg slightly bent',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.setup.3':
    'Bodyweight on the ball of foot',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.execution.1': 'Run in place ',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.execution.2':
    'Press the hips into the band',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.execution.3': '',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.focus.1':
    'Keep body in proper alignement ',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.focus.2': 'Maintain a high cadence',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.focus.3':
    'Focus on pulling, not pushing on the foot',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.repetitions.1': '30s with band ',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.repetitions.2': 'Run 10 meters',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.repetitions.3': '',

  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.label': 'Bodyweight perception',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.setup': 'Spring position',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.setup.1': 'Knees bent',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.setup.2': 'Bodyweight on the ball of foot',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.setup.3': 'Elbows bent at 90°',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.execution.1':
    'Shift bodyweight to the front then to the back',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.execution.2':
    'Hold position to the front for 20s, to the back for 20s',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.execution.3': '',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.focus.1':
    'Perceive the changes of pressure on the ground relatively to body position',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.focus.2': 'Keep body in proper alignement',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.focus.3': 'Stay in balance',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.repetitions.1':
    '10 bodyweight shifts to the front then to the back ',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.repetitions.2':
    '10x 20s hold in front/20s hold towards the back',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.repetitions.3': 'Run 10 meters',

  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.label': 'Bodyweight perception in Pose',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.setup': 'Pose',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.setup.2':
    'Knee of support leg slightly bent',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.setup.3':
    'Bodyweight on the ball of foot',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.execution.1':
    'Shift bodyweight to the front then to the back',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.execution.2':
    'Hold position to the front for 20s, to the back for 20s',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.execution.3': 'Repeat on the other side',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.focus.1':
    'Perceive the changes of pressure on the ground relatively to body position',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.focus.2':
    'Keep body in proper alignement',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.focus.3': 'Stay in balance',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.repetitions.1':
    '10x bodyweight shifts to the front then to the back ',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.repetitions.2':
    '10x 20s hold in front, 20s hold towards the back',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.repetitions.3':
    'Switch side and Run 10 meters',

  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.label': 'Bunny Hops',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.setup': 'Spring position',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.setup.1': 'Knees bent',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.setup.2': 'Bodyweight on the ball of foot',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.setup.3': 'Elbows bent at 90°',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.execution.1':
    'Hop in place then fall forward',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.execution.2': '',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.execution.3': '',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.focus.1':
    'Maintain the same cadence in place and moving forward',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.focus.2':
    'Find the least amount of fall needed to move forward',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.focus.3': 'Minimize the muscular efforts',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.repetitions.1':
    'Alternate 3 hops in place, 3 hops moving forward',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.repetitions.2': 'Run for 10 meters',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.repetitions.3': '',

  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.label': 'Bunny hops backward',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.setup': 'Spring position',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.setup.1': 'Knees bent',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.setup.2': 'Bodyweight on the ball of foot',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.setup.3': 'Elbows bent at 90°',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.execution.1':
    'Hop in place then fall slighly backwards',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.execution.2': '',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.execution.3': '',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.focus.1':
    'Maintain the same cadence in place and moving backwards',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.focus.2':
    'Find the least amount of fall needed to move ',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.focus.3': 'Minimize the muscular efforts',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.repetitions.1':
    'Alternate 3 hops in place, 3 hops moving backward',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.repetitions.2':
    'Turn around and run for 10 meters',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.repetitions.3': '',

  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.label': 'Carioca',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.setup': 'Spring position',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.setup.1': 'Knees bent',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.setup.2': 'Bodyweight on the ball of foot',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.setup.3': 'Elbows bent at 90°',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.execution.1': 'Fall slightly on one side',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.execution.2':
    'Place your foot alternately in front of you and in the back',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.execution.3': '',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.focus.1':
    'Limit the movement of the shoulders',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.focus.2':
    'Perceive bodyweight on the ball of foot',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.focus.3': '',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.repetitions.1': '10 steps to the right',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.repetitions.2': '10 steps to the left',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.repetitions.3': 'Run for 10 meters',

  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.label': 'Change of support',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.setup': 'Pose',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.setup.2':
    'Knee of support leg slightly bent',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.setup.3': 'Bodyweight on the ball of foot',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.execution.1':
    'Hop in place then pull your foot from the ground, straight under the hip, in Pose',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.execution.2': 'Switch side',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.execution.3': '',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.focus.1':
    'Pull the foot straight under the hip',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.focus.2':
    'Let the airborne foot drop and land naturally',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.focus.3':
    'During the hop in place, make sure that the ankle of the airborne foot is at opposite knee height',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.repetitions.1': '10 in place, 10 forward ',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.repetitions.2': 'Run for 10 meters',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.repetitions.3': '',

  'z004qJvt36cySVe24kDnbcG960100qzIAbg.label': 'Criss-Cross',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.setup': 'Spring position',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.setup.1': 'Knees bent',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.setup.2':
    'Bodyweight on the ball of foot',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.setup.3': 'Elbows bent at 90°',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.execution.1':
    'Jump in place and fall forward',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.execution.2':
    'Cross your feet, alternating the foot in front',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.execution.3': '',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.focus.1':
    'Heel slightly kisses the ground',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.focus.2': '',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.focus.3': '',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.repetitions.1':
    '10 in place, 10 forward ',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.repetitions.2': 'Run for 10 meters',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.repetitions.3': '',

  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.label': 'Double lunge',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.setup': 'Small lunge',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.setup.1':
    'One foot in front of the over, one foot length apart',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.setup.2': 'Knees bent',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.setup.3':
    'Bodyweight on the ball of foot',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.execution.1':
    'Put the front foot under the hip then the back foot, alternating',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.execution.2': '',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.execution.3': '',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.focus.1':
    'Pull both foot under the hip in a straight ligne',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.focus.2': '',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.focus.3': '',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.repetitions.1':
    '10 times left foot in front, 10 times right foot in front',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.repetitions.2': 'Run for 10 meters',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.repetitions.3': '',

  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.label': 'Double pull',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.setup': 'Spring position',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.setup.1': 'Knees bent',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.setup.2': 'Bodyweight on the ball of foot',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.setup.3': 'Elbows bent at 90°',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.execution.1':
    'Pull both feet under the hip, use and aggressive shrug from the shoulders',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.execution.2': '',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.execution.3': '',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.focus.1':
    'Focus on pulling not pushing on the feet',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.focus.2':
    'Minimise the vertical oscillations of the head',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.focus.3': '',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.repetitions.1': '5 in place',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.repetitions.2': 'Run for 10 meters',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.repetitions.3': '',

  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.label': 'Exagerated high pull one foot',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.setup':
    'Pose, one arm straight pointing down',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.setup.2':
    'Knee of support leg slightly bent',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.setup.3':
    'Bodyweight on the ball of foot',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.execution.1':
    'Run, pulling the foot towards the hand at each stride',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.execution.2': '',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.execution.3': '',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.focus.1':
    'Keep the torso upright, no bending to the side or in front',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.focus.2': '',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.focus.3': '',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.repetitions.1':
    '10 in place, 10 forward then',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.repetitions.2': 'Run for 10 meters',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.repetitions.3': 'Switch side',

  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.label': 'Exagerated pull one foot',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.setup': 'Pose',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.setup.2':
    'Knee of support leg slightly bent',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.setup.3': 'Bodyweight on the ball of foot',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.execution.1':
    'Run, pulling the foot at opposite knee height',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.execution.2': '',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.execution.3': '',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.focus.1':
    'After the pull, let the airborne foot drop and land naturally',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.focus.2': '',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.focus.3': '',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.repetitions.1': '10 in place, 10 forward ',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.repetitions.2': 'Run for 10 meters',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.repetitions.3': 'Switch side',

  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.label':
    'Exagerated pull one foot 3 heights',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.setup': 'Pose',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.setup.2':
    'Knee of support leg slightly bent',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.setup.3':
    'Bodyweight on the ball of foot',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.execution.1':
    'Run, pulling the foot at mid-shin height, opposite knee height, and glute height',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.execution.2': '',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.execution.3': '',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.focus.1':
    'The difference between the three heights must be clear ',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.focus.2':
    'After the pull, let the airborne foot drop and land naturally',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.focus.3': '',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.repetitions.1': '5 times on each side',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.repetitions.2': 'Run for 10 meters',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.repetitions.3': '',

  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.label': 'Face up hip dips',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.setup': 'Seated with straight legs',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.setup.1':
    'Hands on the floor behind the hips',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.setup.2': 'Shoulders down',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.setup.3': '',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.execution.1':
    'Raise the hips to align them with the shoulders and ankles',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.execution.2': '',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.execution.3': '',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.focus.1': 'Keep the legs straight',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.focus.2': '',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.focus.3': '',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.repetitions.1': '3x 12 repetitions',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.repetitions.2':
    'Run for 10 meters between each set',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.repetitions.3': '',

  'a65AlAKrDek7f81jVQ7G746lcb344X5F.label': 'Face up hip dips one leg up',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.setup':
    'Seated on the floor with straight legs, one foot up',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.setup.1':
    'Hands on the floor behind the hips',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.setup.2': 'Shoulders down',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.setup.3': '',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.execution.1':
    'Raise the hips to align them with the shoulders and ankles',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.execution.2': '',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.execution.3': '',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.focus.1': 'Keep the legs straight',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.focus.2': '',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.focus.3': '',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.repetitions.1':
    '3x10 repetitions on each side',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.repetitions.2':
    'Run for 10 meters between each set',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.repetitions.3': '',

  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.label': 'Finger on belly button',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.setup': 'Pose',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.setup.2':
    'Knee of support leg slightly bent',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.setup.3':
    'Bodyweight on the ball of foot',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.execution.1':
    'Run in place, put two fingers on the belly button',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.execution.2':
    'Fall forward to apply pressure on your fingers',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.execution.3': '',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.focus.1':
    'Feel the pressure on your fingers while keeping an upright torso',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.focus.2': 'Feel the fall when running',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.focus.3': '',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.repetitions.1':
    '10 strides then run for 10 meters',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.repetitions.2':
    'Run for 10 meters between each set',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.repetitions.3': '',

  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.label': 'Foot tapping',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.setup':
    'Pose, one arm straight pointing down',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.setup.2':
    'Knee of support leg slightly bent',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.setup.3': 'Bodyweight on the ball of foot',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.execution.1':
    'Bring the ankle to the hand while hopping',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.execution.2': '',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.execution.3': '',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.focus.1':
    'Keep the torso upright, no bending to the side or in front',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.focus.2': '',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.focus.3': '',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.repetitions.1': '10 in place, 10 forward ',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.repetitions.2': 'Run for 10 meters',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.repetitions.3': 'Switch side',

  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.label': 'Front lunge',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.setup': 'Small lunge',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.setup.1':
    'One foot in front of the over, one foot length apart',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.setup.2': 'Knees bent',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.setup.3':
    'Bodyweight on the ball of foot',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.execution.1':
    'Bring forward foot under the hip',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.execution.2': '',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.execution.3': '',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.focus.1':
    'Pull foot up under the hip in a straight ligne',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.focus.2':
    'Maintain the gap between the feet',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.focus.3': '',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.repetitions.1':
    '10 strides right foot in front',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.repetitions.2':
    '10 strides left foot in front',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.repetitions.3': 'Run for 10 meters',

  'QNryITSngSojSEEpov5xKtIkCefYrsSA.label': 'Front to back Pendulum',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.setup': 'Spring position',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.setup.1': 'Knees bent',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.setup.2': 'Bodyweight on the ball of foot',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.setup.3': 'Elbows bent at 90°',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.execution.1':
    'Lift a straight leg in front and bring it back ',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.execution.2':
    'Switch support before ground contact, while bringing the other leg straight to the rear',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.execution.3': '',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.focus.1':
    'Maintain the same cadence in place and when moving forward',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.focus.2': '',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.focus.3': '',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.repetitions.1':
    '10 strides right leg in front',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.repetitions.2':
    '10 strides left leg in front',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.repetitions.3': 'Run for 10 meters',

  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.label': 'Get in Pose',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.setup': 'Spring position',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.setup.1': 'Knees bent',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.setup.2':
    'Bodyweight on the ball of foot',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.setup.3': 'Elbows bent at 90°',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.execution.1':
    'Bring your ankle at opposite knee height',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.execution.2':
    'Bring the airborne foot back down',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.execution.3': '',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.focus.1': 'Keep the ankle relaxed',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.focus.2': 'Knee of support leg is bent',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.focus.3':
    'Bodyweight on the ball of foot',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.repetitions.1': '20 on each side ',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.repetitions.2': 'Run for 10 meters',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.repetitions.3': '',

  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.label': 'Hands clapsed behind',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.setup':
    'Pose, arms straight in the back, hand clapsed ',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.setup.2':
    'Knee of support leg slightly bent',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.setup.3': 'Bodyweight on the ball of foot',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.execution.1': 'Run in place',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.execution.2':
    'Fall forward driving the hips away from the hands',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.execution.3': '',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.focus.1':
    'Keep the falling sensation when running after the drill',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.focus.2': '',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.focus.3': '',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.repetitions.1': '',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.repetitions.2': '',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.repetitions.3': '',

  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.label': 'Hands clapsed in front',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.setup':
    'Pose, arms straight, hand clapsed in front',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.setup.2':
    'Knee of support leg slightly bent',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.setup.3': 'Bodyweight on the ball of foot',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.execution.1':
    'Run in place while minimizing the oscillations of the hands',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.execution.2':
    'Fall forward keep the hans as still as possible',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.execution.3': '',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.focus.1':
    'Keep the falling sensation when running after the drill',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.focus.2': '',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.focus.3': '',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.repetitions.1': '10 strides',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.repetitions.2': 'Run for 10 meters',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.repetitions.3': '',

  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.label': 'Hip dips',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.setup': 'Plank position on the hands',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.setup.1': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.setup.2': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.setup.3': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.execution.1':
    'Bring the hips down and up',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.execution.2': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.execution.3': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.focus.1': 'Keep the arms straight',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.focus.2': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.focus.3': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.repetitions.1': '3x12 repetitions',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.repetitions.2': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.repetitions.3': '',

  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.label': 'Hip dips one leg up',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.setup':
    'Plank position on the hands, one foot up',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.setup.1': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.setup.2': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.setup.3': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.execution.1': 'Bring the hips down and up',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.execution.2': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.execution.3': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.focus.1': 'Keep the arms straight',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.focus.2': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.focus.3': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.repetitions.1':
    '3x 5 right foot up/5 left foot up',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.repetitions.2': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.repetitions.3': '',

  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.label': 'Hip dips one arm',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.setup':
    'Plank position, one hand at shoulder height',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.setup.1': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.setup.2': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.setup.3': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.execution.1':
    'Bring the hips down and up',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.execution.2': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.execution.3': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.focus.1':
    'Keep the support arm straigth',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.focus.2': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.focus.3': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.repetitions.1':
    '3x 5 right arm up/5 left left arm up',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.repetitions.2': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.repetitions.3': '',

  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.label': 'Hops in Pose',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.setup': 'Pose',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.setup.2':
    'Knee of support leg slightly bent',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.setup.3': 'Bodyweight on the ball of foot',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.execution.1':
    'Hop in place maintaining Pose',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.execution.2': '',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.execution.3': '',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.focus.1':
    'Keep the airborne foot in Pose, under the hip, at opposite knee height',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.focus.2': '',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.focus.3': '',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.repetitions.1':
    '10 jumps on the right foot',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.repetitions.2': '10 on the left foot',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.repetitions.3': 'Run for 10 meters',

  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.label': 'Jump rope change of support',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.setup': 'Pose, with a jump rope',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.setup.2':
    'Knee of support leg slightly bent',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.setup.3': 'Bodyweight on the ball of foot',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.execution.1': 'Jump rope in Pose',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.execution.2':
    'Switch feet everty two jumps',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.execution.3': '',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.focus.1':
    'Keep the airborne foot in Pose, under the hip, at opposite knee height',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.focus.2': '',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.focus.3': '',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.repetitions.1': '20 jumps ',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.repetitions.2':
    'Drop the rope and run for 10 meters',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.repetitions.3': '',

  '5l02fmJyivrknTNR664tgkuICyPnBft5o.label': 'Jump rope double pull',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.setup':
    'Spring position, with a jump rope',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.setup.1': 'Knees bent',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.setup.2': 'Bodyweight on the ball of foot',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.setup.3': 'Elbows bent at 90°',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.execution.1': 'Jump rope feet together',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.execution.2':
    'Bring both feet at Pose height',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.execution.3': '',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.focus.1':
    'Try to keep the head at the same height than when hopping in place',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.focus.2':
    'Focus on pulling, not pushing on the foot',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.focus.3': '',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.repetitions.1': '20 jumps ',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.repetitions.2':
    'Drop the rope and run for 10 meters',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.repetitions.3': '',

  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.label': 'Jump rope in Pose',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.setup': 'Pose, with a jump rope',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.setup.2':
    'Knee of support leg slightly bent',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.setup.3': 'Bodyweight on the ball of foot',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.execution.1': 'Jump rope in Pose',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.execution.2': '',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.execution.3': '',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.focus.1':
    'Keep the airborne foot in Pose, under the hip, at opposite knee height',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.focus.2': '',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.focus.3': '',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.repetitions.1':
    '3x 10 jumps on the right foot/10 on the left foot ',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.repetitions.2':
    'Drop the rope down and run for 10 meters',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.repetitions.3': '',

  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.label': 'Jump rope pony',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.setup': 'Pony Pose, with a jump rope',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.setup.1':
    'Airborn foot under the hip, toes touching the ground',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.setup.2':
    'Knee of support leg slightly bent',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.setup.3': 'Bodyweight on the ball of foot',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.execution.1': 'Jump rope in Pony Pose',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.execution.2': 'Switch foot for each jump',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.execution.3': '',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.focus.1': 'Jump as little as possible',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.focus.2': '',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.focus.3': '',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.repetitions.1': '20 jumps',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.repetitions.2':
    'Drop the rope and run for 10 meters',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.repetitions.3': '',

  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.label': 'Jump rope run in place',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.setup': 'Pose, with a jump rope',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.setup.2':
    'Knee of support leg slightly bent',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.setup.3':
    'Bodyweight on the ball of foot',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.execution.1':
    'Run in place with the jump Rope',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.execution.2': '',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.execution.3': '',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.focus.1':
    'Pull the foot from the ground before the airborne foot is allowed to drop',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.focus.2': '',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.focus.3': '',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.repetitions.1': '20 in place',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.repetitions.2':
    'Drop the rope and run for 10 meters',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.repetitions.3': '',

  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.label': 'Jumpe rope simple under',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.setup':
    'Spring position, with a jump rope',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.setup.1': 'Knees bent',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.setup.2': 'Bodyweight on the ball of foot',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.setup.3': 'Elbows bent at 90°',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.execution.1': 'Jump rope feet together',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.execution.2': '',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.execution.3': '',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.focus.1': 'Keep the  Knees bent',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.focus.2':
    'Heels kiss the ground at each jump',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.focus.3': '',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.repetitions.1': '20 jumps',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.repetitions.2':
    'Drop the rope and run for 10 meters',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.repetitions.3': '',

  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.label': 'Jumping jack to Pose',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.setup': 'Spring position, feet apart',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.setup.1': 'Knees bent',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.setup.2': 'Bodyweight on the ball of foot',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.setup.3': 'Elbows bent at 90°',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.execution.1':
    'From the start position feet apart, jump and land in Pose',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.execution.2':
    'Alternate Pose on the right foot and left foot',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.execution.3': '',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.focus.1':
    'Land in proper Pose each time, airborne foot under the hip, knee pointing forward',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.focus.2':
    'Avoid side to side movement of the body',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.focus.3': '',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.repetitions.1': '10 in place, 10 forward',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.repetitions.2': 'Run for 10 meters',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.repetitions.3': '',

  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.label': 'Jumps feet together and apart',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.setup': 'Spring position,',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.setup.1': 'Knees bent',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.setup.2': 'Bodyweight on the ball of foot',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.setup.3': 'Elbows bent at 90°',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.execution.1':
    'Jump spreading your feet in the air',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.execution.2': 'Land with feet joined',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.execution.3': '',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.focus.1':
    'Maintain the same cadence in place and moving forward',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.focus.2':
    'Fall forward to move, no muscular effort',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.focus.3':
    'Heels kiss the ground at each jump',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.repetitions.1': '10 in place, 10 forward',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.repetitions.2': 'Run for 10 meters',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.repetitions.3': '',

  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.label': 'Jumps feet apart and together',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.setup': 'Spring position, feet apart',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.setup.1': 'Knees bent',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.setup.2': 'Bodyweight on the ball of foot',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.setup.3': 'Elbows bent at 90°',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.execution.1':
    'From the start position feet apart, jump and join your feet in the air',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.execution.2': 'Land feet apart',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.execution.3': '',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.focus.1':
    'Maintain the same cadence in place and moving forward',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.focus.2':
    'Fall forward to move, no muscular effort',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.focus.3':
    'Heels kiss the ground at each jump',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.repetitions.1': '10 in place, 10 forward',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.repetitions.2': 'Run for 10 meters',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.repetitions.3': '',

  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.label': 'Palm on lower back',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.setup': 'Spring position',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.setup.1': 'Knees bent',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.setup.2': 'Bodyweight on the ball of foot',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.setup.3': 'Elbows bent at 90°',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.execution.1':
    'Place the palm on one hand on the lower back',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.execution.2': 'Fall forward',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.execution.3': '',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.focus.1': 'Feel the fall from the hips',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.focus.2': '',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.focus.3': '',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.repetitions.1': '10 in place, 10 forward',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.repetitions.2': 'Run for 10 meters',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.repetitions.3': '',

  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.label': 'Pony',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.setup': 'Pony Pose',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.setup.1':
    'Airborn foot under the hip, toes touching the ground',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.setup.2':
    'Knee of support leg slightly bent',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.setup.3': 'Bodyweight on the ball of foot',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.execution.1':
    'Pull the support from the ground and land in Pony Pose on the other foot',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.execution.2':
    'One hop in place and repeat',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.execution.3': '',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.focus.1':
    'Pull the support foot as little as possible',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.focus.2':
    'Shrug your shoulder for easier pull',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.focus.3': '',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.repetitions.1': '10 forward',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.repetitions.2': 'Run for 10 meters',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.repetitions.3': '',

  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.label': 'Running backwards',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.setup': 'Pose',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.setup.2':
    'Knee of support leg slightly bent',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.setup.3': 'Bodyweight on the ball of foot',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.execution.1': 'Run in place',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.execution.2': 'Fall backward',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.execution.3':
    '10 moving backward then run for 10 meters',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.focus.1':
    'Intial contact is on the ball of foot',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.focus.2':
    'Heels kiss the ground at each stride',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.focus.3': '',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.repetitions.1': '10 backwards',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.repetitions.2': 'Run for 10 meters',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.repetitions.3': '',

  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.label': 'Running hands overhead',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.setup': 'Pose, arms straight overhead',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.setup.2':
    'Knee of support leg slightly bent',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.setup.3':
    'Bodyweight on the ball of foot',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.execution.1': 'Run in place',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.execution.2': 'Fall forward',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.execution.3': '',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.focus.1':
    'The torso is vertical, shoulders over the hips',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.focus.2': '',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.focus.3': '',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.repetitions.1':
    '10 in place, 10 forward',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.repetitions.2': 'Run for 10 meters',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.repetitions.3': '',

  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.label': 'Running in place',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.setup': 'Pose',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.setup.2':
    'Knee of support leg slightly bent',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.setup.3':
    'Bodyweight on the ball of foot',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.execution.1': 'Run in place',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.execution.2':
    '10 in place then run for 10 meters',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.execution.3': '',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.focus.1':
    'Intial contact is on the ball of foot',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.focus.2':
    'Heels kiss the ground at each stride',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.focus.3': '',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.repetitions.1': '10 in place',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.repetitions.2': 'Run for 10 meters',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.repetitions.3': '',

  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.label': 'Running Pose',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.setup': 'Spring position',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.setup.1': 'Knees bent',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.setup.2': 'Bodyweight on the ball of foot',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.setup.3': 'Elbows bent at 90°',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.execution.1':
    'Pull foot up straight under the hip, at opposite knee height',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.execution.2':
    'Keep the knee of support leg bent',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.execution.3':
    'Keep bodyweight on the ball of foot',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.focus.1':
    'Pull the foot in a straight line',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.focus.2': 'Do not drive with the knee',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.focus.3': '',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.repetitions.1': '30s on the right side',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.repetitions.2': '30s on the left side',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.repetitions.3': '',

  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.label': 'Side hip dips',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.setup': 'Side plank',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.setup.1': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.setup.2': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.setup.3': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.execution.1':
    'Bring your hips down and up',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.execution.2': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.execution.3': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.focus.1':
    'Body aligned, no butt going back',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.focus.2': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.focus.3': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.repetitions.1':
    '3x 5 on the right/5 on the left',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.repetitions.2': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.repetitions.3': '',

  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.label': 'Side to side pendulum',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.setup': 'Spring position',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.setup.1': 'Knees bent',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.setup.2':
    'Bodyweight on the ball of foot',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.setup.3': 'Elbows bent at 90°',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.execution.1':
    'Lift a straight leg on the side while hoping, then bring it back to the middle',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.execution.2':
    'Before the lifted foot touches the ground, bring the other leg on the opposite side',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.execution.3': '',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.focus.1':
    'Maintain the same cadence in place and moving forward',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.focus.2': '',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.focus.3': '',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.repetitions.1': '10 in place, 10 forward',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.repetitions.2': 'Run for 10 meters',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.repetitions.3': '',

  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.label': 'Skip',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.setup': 'Spring position',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.setup.1': 'Knees bent',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.setup.2': 'Bodyweight on the ball of foot',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.setup.3': 'Elbows bent at 90°',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.execution.1':
    'Jump and actively pull one foot up, under the hip',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.execution.2':
    'Land simultaneously on both feet',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.execution.3': '',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.focus.1': 'Pull the foot straight up',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.focus.2':
    'Let the airborne foot drop and land effortlessly on the ground',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.focus.3': '',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.focus': '',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.repetitions.1': '10 in place, 10 forward',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.repetitions.2': 'Run for 10 meters',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.repetitions.3': 'Switch foot',

  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.label': 'Skip alternating feet',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.setup': 'Spring position',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.setup.1': 'Knees bent',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.setup.2': 'Bodyweight on the ball of foot',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.setup.3': 'Elbows bent at 90°',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.execution.1':
    'Jump and alternately pull one foot up, under the hip',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.execution.2':
    'Land simultaneously on both feet',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.execution.3': '',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.focus.1': 'Pull the foot straight up',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.focus.2':
    'Let the airborne foot drop and land effortlessly on the ground',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.focus.3': '',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.focus': '',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.repetitions.1': '10 in place, 10 forward',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.repetitions.2': 'Run for 10 meters',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.repetitions.3': '',

  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.label': 'Support leg pull',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.setup': 'Pose',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.setup.2':
    'Knee of support leg slightly bent',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.setup.3': 'Bodyweight on the ball of foot',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.execution.1':
    'Hop on support foot, bring it to the height of the foot in Pose',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.execution.2': '',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.execution.3': '',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.focus.1':
    'Shrug you shoulder for an easier pull',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.focus.2':
    'Focus on pulling, not pushing on the foot',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.focus.3':
    'Focus on actively pulling the foot from the ground when running',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.repetitions.1':
    '5 on right foot/5 on left foot ',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.repetitions.2': 'Run for 10 meters',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.repetitions.3': '',

  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.label': 'Timber drill',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.setup': 'Spring position',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.setup.1': 'Knees bent',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.setup.2': 'Bodyweight on the ball of foot',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.setup.3': 'Elbows bent at 90°',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.execution.1':
    'Fall forward and catch yourself as late as possible',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.execution.2':
    '5 on right foot, 5 on left foot then run for 10 meters',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.execution.3': '',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.focus.1': 'Feel the fall from the hips',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.focus.2': 'Keep the torso upright',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.focus.3': '',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.repetitions.1':
    '5 on right foot/5 on left foot ',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.repetitions.2': 'run for 10 meters',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.repetitions.3': '',

  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.label': 'Timber drill in Pose',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.setup': 'Pose',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.setup.2':
    'Knee of support leg slightly bent',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.setup.3': 'Bodyweight on the ball of foot',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.execution.1':
    'Fall forward and catch yourself as late as possible',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.execution.2':
    '5 on right foot, 5 on left foot then run for 10 meters',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.execution.3': '',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.focus.1': 'Feel the fall from the hips',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.focus.2': 'Keep the torso upright',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.focus.3': '',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.repetitions.1':
    '5 on right foot/5 on left foot ',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.repetitions.2': 'run for 10 meters',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.repetitions.3': '',

  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.label':
    'Timber Drill with change of support',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.setup': 'Pose',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.setup.2':
    'Knee of support leg slightly bent',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.setup.3': 'Bodyweight on the ball of foot',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.execution.1': 'Fall forward ',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.execution.2':
    'Pull support foot to land on Pose on the airborne foot',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.execution.3': '',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.focus.1':
    'Pull the support foot at the end of the fall',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.focus.2': '',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.focus.3': '',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.repetitions.1':
    '5 on right foot/5 on left foot ',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.repetitions.2': 'run for 10 meters',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.repetitions.3': '',

  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.label': 'Toes in and Out',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.setup': 'Spring position',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.setup.1': 'Knees bent',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.setup.2':
    'Bodyweight on the ball of foot',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.setup.3': 'Elbows bent at 90°',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.execution.1':
    'Hop and point your toes to the outside, to the front then to the inside',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.execution.2': '',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.execution.3': '',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.focus.1':
    'Heels must slightly kiss the ground',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.focus.2': '',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.focus.3': '',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.repetitions.1': '10 forward',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.repetitions.2': 'Run for 10 meters',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.repetitions.3': '',

  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.label': 'Twist',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.setup': 'Spring position',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.setup.1': 'Knees bent',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.setup.2': 'Bodyweight on the ball of foot',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.setup.3': 'Elbows bent at 90°',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.execution.1':
    'Hop and point alternatly your pelvis to one side and to the other',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.execution.2':
    '10 in place, 10 forward then run for 10 meters',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.execution.3': '',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.focus.1':
    'Keep your shoulders facing forward',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.focus.2':
    'Heels must slightly kiss the ground',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.focus.3': '',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.repetitions.1': '10 in place, 10 forward',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.repetitions.2': 'Run for 10 meters',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.repetitions.3': '',

  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.label': 'Wall fall',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.setup':
    'Spring position, about one meter away from the wall',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.setup.1': 'Knees bent',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.setup.2':
    'Bodyweight on the ball of foot',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.setup.3': '',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.execution.1': 'Fall forward ',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.execution.2':
    'Catch your self as late as possible with hands on the wall',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.execution.3': '',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.focus.1': 'Fall from the hips',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.focus.2': 'Keep the torso upright',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.focus.3': '',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.repetitions.1': '10 repetitions',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.repetitions.2': 'Run for 10 meters',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.repetitions.3': '',

  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.label': 'Wall fall in Pose',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.setup':
    'Spring position, about one meter away from the wall',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.setup.1':
    'Foot under the hip, ankle at opposite knee height',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.setup.2':
    'Knee of support leg slightly bent',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.setup.3': 'Bodyweight on the ball of foot',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.execution.1': 'Fall forward ',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.execution.2':
    'Catch your self as late as possible with hands on the wall',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.execution.3': '',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.focus.1': 'Fall from the hips',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.focus.2': 'Keep the torso upright',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.focus.3': '',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.repetitions.1':
    '5 on right foot, 5 on left foot ',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.repetitions.2': 'Run for 10 meters',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.repetitions.3': ''
}
export default en_us
