import React, { Component, Fragment, Suspense, lazy } from 'react'
import {
  Form,
  Dimmer,
  Loader,
  Container,
  Icon,
  Modal,
  List,
  Transition
} from 'semantic-ui-react'
import { getMe, updateProfile } from '../services/Api'
import { COUNTRY_OPTIONS } from '../services/countriesData.js'
import { withPolyglot } from 'polyglot-react'
import ProfilePic from '../components/ProfilePic'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css'
import ReactGA from 'react-ga'
import AppError from '../components/AppError'
import _ from 'lodash'

//import Flag from 'react-world-flags'
const Flag = lazy(() => import('react-world-flags'))

const localeOptions = [
  {
    text: 'Français',
    value: 'fr-fr'
  },
  {
    text: 'English',
    value: 'en-us'
  }
]

class Profile extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      userPicForceReload: '',
      language: window.localStorage.getItem('RWJlocale'),
      hasError: false,
      error: false,
      isLoading: true,
      modalCountryOpen: false,
      modalLanguageOpen: false,
      forceRefresh: '',
      visible: false
    }
    this.call_updateProfile = this.call_updateProfile.bind(this)
    this.handleRadio = this.handleRadio.bind(this)
    this.handleCountry = this.handleCountry.bind(this)
    this.handleLocale = this.handleLocale.bind(this)
  }

  componentDidMount() {
    this.getCurrentUser()
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  switchLanguage() {
    if (this.state.language === 'fr-fr') {
      this.state.language.setItem('RWJlocale', 'en-us')
    } else {
      window.localStorage.setItem('RWJlocale', 'fr-fr')
    }
    this.setState({ isLoading: true })
    window.location.reload()
  }
  handleValue(e, key) {
    var user = this.state.user
    user[key] = e.target.value
    this.setState({ user: user })
  }
  handleRadio(e, { value }) {
    var user = this.state.user
    user['gender'] = value
    this.setState({ user: user })
  }
  handleCountry(e, { value }) {
    var user = this.state.user
    user['country'] = value
    this.setState({ user: user })
    this.handleCloseCountry()
  }

  setCountry = value => {
    var user = this.state.user
    user['country'] = value
    this.setState({ user: user })
    this.handleCloseCountry()
  }

  handleLocale(e, { value }) {
    var user = this.state.user
    user['locale'] = value
    this.setState({ user: user })
  }
  setLocale = value => {
    var user = this.state.user
    user['locale'] = value
    this.setState({ user: user })
    this.handleCloseLanguage()
  }

  handleOpenCountry = () => this.setState({ modalCountryOpen: true })
  handleCloseCountry = () => this.setState({ modalCountryOpen: false })

  handleOpenLanguage = () => this.setState({ modalLanguageOpen: true })
  handleCloseLanguage = () => this.setState({ modalLanguageOpen: false })
  async call_updateProfile() {
    const { polyglot } = this.props
    var data = {}
    data.name = this.state.user.name
    data.gender = this.state.user.gender
    data.country = this.state.user.country
    data.locale = this.state.user.locale
    const response = await updateProfile(localStorage.RWJUserId, data)
    this.setState({ isLoading: true })
    if (response.ok) {
      const data = response.data
      const language = data.locale || 'fr-fr'
      window.localStorage.setItem('RWJlocale', language)
      this.setState({ isLoading: false })
      toast(polyglot.t('profile.message.update.done'), {
        onClose: (window.location = '/')
      })
    }
  }

  async getCurrentUser() {
    const response = await getMe()
    if (response.ok) {
      const data = response.data
      const responseUser = data
      //console.log(responseUser)
      const newState = Object.assign({}, this.state, {
        user: responseUser,
        isLoading: false,
        hasError: false,
        redirectToReferrer: true,
        visible: true
      })
      // store the new state object in the component's state
      this.setState(newState)
    } else {
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }
  refreshAfterNewPic = newPicUrl => {
    this.setState({
      isLoading: true
    })
    //degueu mais pas trouvé de solution
    this.props.history.go(0)
    const updatedUser = this.state.user
    updatedUser.picture = newPicUrl

    this.setState({
      user: updatedUser,
      userPicForceReload: '?fr=' + Date.now().toString()
    })
  }
  render() {
    const { polyglot } = this.props

    //	COUNTRY_OPTIONS.map((x) => (x.flag = x.key.toLowerCase()))
    //console.log(COUNTRY_OPTIONS)
    const countryFromCode = code => {
      if (!code) {
        return ''
      }
      let country = _.find(COUNTRY_OPTIONS, { value: code })
      return country.text
    }

    const languageFromLocale = locale => {
      if (!locale) {
        return ''
      }
      let language = _.find(localeOptions, { value: locale })
      return language.text
    }

    return (
      <Fragment>
        <Dimmer active={this.state.isLoading}>
          <Loader />
        </Dimmer>
        {this.state.hasError && (
          <AppError
            message={this.state.error.problem ? this.state.error.problem : ''}
          />
        )}
        {!this.state.hasError && (
          <Transition
            visible={this.state.visible}
            animation="scale"
            duration={100}
          >
            <div className="profile full-page">
              <style>{`
						.profilePicture img.ui.image{
							display: inline-block;
						}
            .profile .ui.form .field>label{
              color:grey;
              margin-bottom:0;
              line-height:1em
            }
            .profile .ui.form input,
            .profile .ui.form input:focus
            {
              background:transparent;
              color:#fff;
              border:none;
              border-bottom:1px solid grey;
              border-radius:0;
              padding:.15em 1em;
            }
            .ui.checkbox label,.ui.checkbox label:hover{
              background:transparent;
              color:#fff;
              border:none;
            }
            .ui.checkbox
            {
              padding:.15em 1em;

            }
            .ui.form .field>.selection.dropdown {
              width: auto;
            }
            .ui.selection.dropdown
            {
              background-color:transparent;
              color:#fff
            }
            .ui.selection.visible.dropdown>.text:not(.default) {
              color: #fff;
            }
            .ui.selection.active.dropdown:hover{

              border-color: transparent;
            }
            .ui.selection.active.dropdown .menu ,
            .ui.selection.active.dropdown:hover .menu {
              border-color: transparent;
              background-color:#343433;
            }
            .ui.selection.dropdown .menu>.item{
              background-color:transparent;
              color:#fff;

            }
            .ui.selection.active.dropdown:hover {
            }
            .ui.selection.active.dropdown
            {
              border-color:transparent;
						}
						.ui.dimmer{
							background-color:#000;
						}
						.ui.modal .scrolling.content {
							max-height: calc(90vh);
							overflow: auto;
					}	

						`}</style>
              <div className="profilePic-placeholder">
                {this.state.user.picture && (
                  <ProfilePic
                    currentPic={
                      this.state.user.picture + this.state.userPicForceReload
                    }
                    mustRefresh={this.refreshAfterNewPic}
                    editable={true}
                    history={this.props.history}
                  />
                )}
              </div>
              <Container style={{ height: '100%', textAlign: 'left' }}>
                <Form style={{ marginTop: '10px' }}>
                  <Form.Field>
                    <label> {polyglot.t('profile.name')} </label>
                    <input
                      defaultValue={this.state.user.name}
                      onChange={e => this.handleValue(e, 'name')}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label> {polyglot.t('profile.email')} </label>
                    <input defaultValue={this.state.user.email} readOnly />
                  </Form.Field>
                  <Form.Field>
                    <label> {polyglot.t('profile.gender')} </label>
                    <Form.Group
                      inline
                      style={{
                        marginTop: '5px',
                        borderBottom: '1px solid grey'
                      }}
                    >
                      <Form.Radio
                        label={polyglot.t('M')}
                        value="M"
                        checked={this.state.user.gender === 'M'}
                        onChange={this.handleRadio}
                      />
                      <Form.Radio
                        label={polyglot.t('F')}
                        value="F"
                        checked={this.state.user.gender === 'F'}
                        onChange={this.handleRadio}
                      />
                      <Form.Radio
                        label={polyglot.t('O')}
                        value="O"
                        checked={this.state.user.gender === 'O'}
                        onChange={this.handleRadio}
                      />
                    </Form.Group>
                  </Form.Field>
                  <Form.Field>
                    <label> {polyglot.t('profile.country')} </label>
                    <Form.Group
                      inline
                      style={{
                        marginTop: '5px',
                        borderBottom: '1px solid grey'
                      }}
                    >
                      {/*
										<Dropdown
											name="country"
											onChange={this.handleCountry}
											options={COUNTRY_OPTIONS}
											search={false}
											selection
											floating
											selectOnBlur={false}
											value={this.state.user.country}
											className="couentry-selector"
										/>
								 */}
                      <Modal
                        trigger={
                          <div
                            className="color-white"
                            style={{ minWidth: '14em' }}
                            onClick={this.handleOpenCountry}
                          >
                            {countryFromCode(this.state.user.country)}{' '}
                            <Icon
                              name="caret down"
                              style={{ float: 'right' }}
                            />
                          </div>
                        }
                        open={this.state.modalCountryOpen}
                        onClose={this.handleCloseCountry}
                        size="fullscreen"
                        basic
                        centered={false}
                      >
                        <Modal.Header>
                          <Icon
                            name="arrow left"
                            style={{
                              color: 'grey',
                              position: 'absolute',
                              top: '-10px'
                            }}
                            onClick={this.handleCloseCountry}
                          />
                        </Modal.Header>
                        <Modal.Content
                          scrolling
                          style={{ backgroundColor: '#000' }}
                        >
                          {' '}
                          <Suspense fallback={<Loader />}>
                            <List inverted verticalAlign="middle">
                              {COUNTRY_OPTIONS.map(country => (
                                <List.Item
                                  key={country.key}
                                  style={{ borderColor: 'grey' }}
                                  onClick={() => this.setCountry(country.value)}
                                >
                                  <List.Content>
                                    <List.Header
                                      style={{
                                        fontWeight: 'normal',
                                        textTransform: 'initial',
                                        padding: '10px 5px'
                                      }}
                                    >
                                      <Flag
                                        code={country.key}
                                        width="16"
                                        height="11"
                                      />{' '}
                                      <span
                                        style={{
                                          marginLeft: '5px',
                                          fontSize: '1.1rem'
                                        }}
                                      >
                                        {' '}
                                        {country.text}
                                      </span>
                                    </List.Header>
                                  </List.Content>
                                </List.Item>
                              ))}
                            </List>
                          </Suspense>
                        </Modal.Content>
                      </Modal>
                    </Form.Group>
                  </Form.Field>
                  <Form.Field>
                    <label> {polyglot.t('profile.language')} </label>
                    <Form.Group
                      inline
                      style={{
                        marginTop: '5px',
                        borderBottom: '1px solid grey'
                      }}
                    >
                      {/*
										<Dropdown
											value={this.state.user.locale}
											selection
											options={localeOptions}
											onChange={this.handleLocale}
										/>
										*/}
                      <Modal
                        trigger={
                          <div
                            className="color-white"
                            style={{ minWidth: '14em' }}
                            onClick={this.handleOpenLanguage}
                          >
                            {languageFromLocale(this.state.user.locale)}{' '}
                            <Icon
                              name="caret down"
                              style={{ float: 'right' }}
                            />
                          </div>
                        }
                        open={this.state.modalLanguageOpen}
                        onClose={this.handleCloseLanguage}
                        size="fullscreen"
                        basic
                        centered={false}
                      >
                        <Modal.Header>
                          <Icon
                            name="arrow left"
                            style={{
                              color: 'grey',
                              position: 'absolute',
                              top: '-10px'
                            }}
                            onClick={this.handleCloseLanguage}
                          />
                        </Modal.Header>
                        <Modal.Content
                          scrolling
                          style={{ backgroundColor: '#000' }}
                        >
                          <List inverted verticalAlign="middle">
                            {localeOptions.map(lang => (
                              <List.Item
                                key={lang.value}
                                style={{ borderColor: 'grey' }}
                                onClick={() => this.setLocale(lang.value)}
                              >
                                <List.Content>
                                  <List.Header
                                    style={{
                                      fontWeight: 'normal',
                                      textTransform: 'initial',
                                      padding: '10px 5px'
                                    }}
                                  >
                                    <span
                                      style={{
                                        marginLeft: '5px',
                                        fontSize: '1.1rem'
                                      }}
                                    >
                                      {' '}
                                      {lang.text}
                                    </span>
                                  </List.Header>
                                </List.Content>
                              </List.Item>
                            ))}
                          </List>
                        </Modal.Content>
                      </Modal>
                    </Form.Group>
                  </Form.Field>

                  <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Icon
                      link
                      name="save outline"
                      size="big"
                      inverted
                      onClick={this.call_updateProfile}
                    />
                  </div>
                </Form>
              </Container>
              <ToastContainer autoClose={3000} />
            </div>
          </Transition>
        )}
      </Fragment>
    )
  }
}

Profile = withPolyglot()(Profile)
export default Profile
