import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import Linkify from 'linkifyjs/react'

import {
  Icon,
  Card,
  Label,
  Divider,
  Modal,
  List,
  Embed,
  Container,
  Header,
  Segment,
  Grid,
  Image,
  Popup
} from 'semantic-ui-react'
import { getMyClassById, getAllMyPerfs } from '../services/Api'
import { withPolyglot } from 'polyglot-react'

import AppError from '../components/AppError'
import 'react-datepicker/dist/react-datepicker.css'
//import {Line} from 'react-chartjs-2';

import { Config } from '../config'
import moment from 'moment'
//import getAnalysisId from 'get-analysis-id'
import ReactGA from 'react-ga'
ReactGA.initialize(Config.gaId)

class UserTraining extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},

      hasError: false,
      error: false,
      isLoading: true,
      showPaceSpeed: false,
      myClass: {},
      myClassPaces: {},
      allPerfsArray: [],
      frameCount: '',
      analysisModalIsOpen: false,
      analysisModalUrl: '',
      analysisModalCode: '',
      swipeDisabled: false,
      hasCoolDown: false,
      hasWarmupOrDrills: false,
      activeIndex: 0
    }
  }
  async componentDidMount() {
    //await this.call_getAllMyPerfs()
    //await this.call_getMyClassById()
    var response = await getAllMyPerfs()
    if (response.ok) {
      var allPerfsArray = response.data
    } else {
      // store the new state object in the component's state
      this.setState({
        hasError: true,
        error: response
      })
      return
    }

    const { trainingId } = this.props.match.params
    var classPaces = []
    response = await getMyClassById(trainingId)
    if (response.ok && response.data) {
      var myClass = response.data

      if (myClass.publicDescription) {
        var classData = this.customizeNextClass(
          myClass.publicDescription,
          allPerfsArray
        )
        myClass.publicDescription = classData.finalString
        classPaces = classData.classPaces
      }
      var hasWarmupOrDrills =
        myClass.publicWarmUp ||
        myClass.drills.length !== 0 ||
        myClass.warmupDrills.length !== 0 ||
        myClass.publicSkills

      var hasWarmup = myClass.publicWarmUp || myClass.warmupDrills.length !== 0

      var hasDrills = myClass.drills.length !== 0 || myClass.publicSkill
      var hasTraining = myClass.publicDescription

      var hasCoolDown = myClass.publicCoolDown

      this.setState({
        myClass,
        myClassPaces: classPaces,
        allPerfsArray: allPerfsArray,
        hasWarmup,
        hasDrills,
        hasWarmupOrDrills,
        hasTraining,
        activeIndex: window.location.hash
          ? parseInt(window.location.hash.replace(/#/, ''))
          : 0,
        hasCoolDown,
        isLoading: false,
        hasError: false
      })
    } else {
      // store the new state object in the component's state
      this.setState({
        hasError: true,
        error: response
      })
      return
    }
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  handleOnSwitching = () => {
    window.location.hash = this.state.activeIndex
  }
  handleChangeIndex = activeIndex => {
    this.setState({
      activeIndex
    })
  }
  nextView = e => {
    var { activeIndex = 0 } = this.state

    this.setState({ activeIndex: activeIndex + 1 })
  }
  prevView = e => {
    var { activeIndex = 0 } = this.state

    this.setState({ activeIndex: activeIndex - 1 })
  }

  analysisModalOpen = url => {
    this.setState({ analysisModalIsOpen: true, analysisModalUrl: url })
  }
  analysisModalClose = () => this.setState({ analysisModalIsOpen: false })

  customizeNextClass(nextClassString, allPerfsArray) {
    //tableau avec le lien entre la performance et la chrono
    const classPaces = {}
    var perfObj = {}
    allPerfsArray.forEach(function(el) {
      if (el.length !== 0) {
        perfObj['_' + el[0].distance] = el[0].time
      }
    })
    var regex = /\{([^}]+)\}/g //var nextClassString=this.state.myClass;
    if (!nextClassString) return ''

    var result = nextClassString.replace(regex, function(match, group) {
      match = match
        .replace('{', '')
        .replace('}', '')
        .replace(/\s/g, '') //permet d'enlever les espaces blancs inutiles
      const [interval, base, adjustOption] = match.split('|')

      let adjust = 0
      if (!perfObj['_' + base]) {
        //return "n/a"
        return ''
      }

      let operator = '+'

      if (adjustOption && adjustOption.indexOf('-') !== -1) {
        operator = '-'
        adjust = Number(adjustOption.split(operator)[1])
        //console.log("adjuste option neg")
      }
      if (adjustOption && adjustOption.indexOf('+') !== -1) {
        adjust = Number(adjustOption.split(operator)[1])
        //	console.log("adjuste option +")
      }

      if (!perfObj['_' + base]) {
        //return "n/a"
        //return ""
      }
      classPaces[base] = perfObj['_' + base]
      var baseTime = perfObj['_' + base]
      var customTarget = Number((interval * perfObj['_' + base]) / base) // temps de travail de base
      //ajout de l'ajustement du coach

      if (operator === '-') {
        customTarget = +customTarget - adjust
      } else {
        customTarget = +customTarget + adjust
      }

      customTarget = moment('1900-01-01 00:00:00')
        .add(customTarget, 'seconds')
        .format('mm:ss')
      customTarget =
        '//timetable-' +
        JSON.stringify({
          baseDistance: base,
          baseTime: baseTime,
          interval: interval,
          customTarget: customTarget
        })
      //  console.log(customTarget)
      return customTarget
    })

    var vals = { finalString: result, classPaces }

    // console.log(vals)
    return vals
  }
  computeSpeedPace(d, time) {
    let v = (parseInt(d) * 3600) / parseInt(time) / 1000
    v = Math.ceil(v * 100) / 100

    let p = (1000 * time) / d

    p = moment('1900-01-01 00:00:00')
      .add(p, 'seconds')
      .format('mm:ss')
    return { pace: p, speed: v }
  }

  roadmap(distance, time, limit) {
    if (distance === '' + 400) {
      let time100m = time / 4
      const roadmapArray = [1, 2, 3]

      const timesheet = (
        <Fragment key={distance}>
          {roadmapArray.map(d => {
            if (limit && d * 100 > limit) {
              return null
            }
            return (
              <div key={d * 100}>
                <strong>{d * 100}m:</strong>{' '}
                {moment('1900-01-01 00:00:00')
                  .add(d * time100m, 'seconds')
                  .format('mm:ss')}
              </div>
            )
          })}
        </Fragment>
      )

      const { speed, pace } = this.computeSpeedPace(distance, time)
      return { timesheet, speed, pace }
    }
    if (distance === '' + 1600) {
      let time100m = time / 16
      const roadmapArray = [1, 2, 3, 4, 6, 8, 10, 12, 14]

      const timesheet = (
        <Fragment key={distance}>
          {roadmapArray.map(d => {
            if (limit && d * 100 > limit) {
              return null
            }
            return (
              <div key={d * 100}>
                <strong>{d * 100}m:</strong>{' '}
                {moment('1900-01-01 00:00:00')
                  .add(d * time100m, 'seconds')
                  .format('mm:ss')}
              </div>
            )
          })}
        </Fragment>
      )
      const { speed, pace } = this.computeSpeedPace(distance, time)
      return { timesheet, speed, pace }
    }
  }

  formatLinks = link => {
    // link = link.split('http://timetable-').join('')
    const { polyglot } = this.props
    //return "voir"
    if (link && link.indexOf('timetable') !== -1) {
      link = link.split('//timetable-').join('')

      const { baseTime, baseDistance, interval, customTarget } = JSON.parse(
        link
      )

      const roadmap = this.roadmap(baseDistance, baseTime, interval)
      //  console.log(this.roadmap(baseDistance, baseTime, interval))

      return (
        <Popup
          trigger={
            <span>
              <span
                style={{
                  textDecoration: 'underline',
                  color: '#db2828',
                  fontWeight: 'bold'
                }}
              >
                {customTarget}
              </span>
              <Icon
                name="stopwatch"
                size="small"
                style={{ verticalAlign: 'super', color: '#db2828' }}
              />
            </span>
          }
          content={
            <Fragment>
              <div>{roadmap.timesheet}</div>

              <Divider />
              <div>
                <span> {polyglot.t('usertraining.course.pace')} : </span>
                <span>{roadmap.pace}</span>
                <span>min/k</span>
              </div>
              <div>
                <span> {polyglot.t('usertraining.course.speed')} : </span>
                <span>{roadmap.speed}</span>
                <span>km/h</span>
              </div>
            </Fragment>
          }
          on="click"
          position="bottom center"
          hideOnScroll
        />
      )
    }
  }
  handleAnalysisUrl = event => {
    event.preventDefault()
  }
  render() {
    if (Object.keys(this.state.myClass).length === 0) {
      return null
    }
    const { activeIndex } = this.state
    const { polyglot } = this.props

    var times = []
    var {
      hasWarmup,
      hasDrills,
      hasTraining,
      hasWarmupOrDrills,
      hasCoolDown
    } = this.state

    var courseWarmupDrills = (
      <Segment basic>
        <List inverted relaxed>
          {this.state.myClass.warmupDrills.map(drill => (
            <List.Item key={drill._id}>
              <Link
                to={{
                  pathname: '/drills/' + drill._id,
                  state: {
                    drills: this.state.myClass.warmupDrills,
                    trainingMode: true
                  }
                }}
              >
                <div>
                  <Icon
                    name="chevron right"
                    size="small"
                    className="floated right color-grey top-20"
                  />
                  {drill.image ? (
                    <Image src={'/drills/' + drill.image} avatar />
                  ) : (
                    <Image src={'/icons/icon-72x72.png'} avatar />
                  )}

                  {drill.name}
                </div>
              </Link>
            </List.Item>
          ))}
        </List>
      </Segment>
    )
    var courseDrills = (
      <Segment basic>
        <List inverted relaxed>
          {this.state.myClass.drills.map(drill => (
            <List.Item key={drill._id}>
              <Link
                to={{
                  pathname: '/drills/' + drill._id,
                  state: {
                    drills: this.state.myClass.drills,
                    trainingMode: true
                  }
                }}
              >
                <div>
                  <Icon
                    name="chevron right"
                    size="small"
                    className="floated right color-grey top-30"
                  />
                  {drill.image ? (
                    <Image src={'/drills/' + drill.image} avatar />
                  ) : (
                    <Image src={'/icons/icon-72x72.png'} avatar />
                  )}

                  {drill.name}
                </div>
              </Link>
            </List.Item>
          ))}
        </List>
      </Segment>
    )

    Object.keys(this.state.myClassPaces).forEach(e => {
      times.push(
        <Grid.Column key={e}>
          <Card
            key={e}
            header={e}
            description={this.roadmap(e, this.state.myClassPaces[e])}
            style={{ background: 'transparent', width: '40%' }}
            centered
          />
        </Grid.Column>
      )
    })
    // const timeSheet = times

    const swipeableHeader = []
    const swipeableContent = []
    const iconClassLeft = 'floated left color-grey top-10'
    const iconClassRight = 'floated right color-grey top-10'
    const transparentClassLeft = 'floated left  top-10 transparent'
    const transparentClassRight = 'floated right  top-10 transparent'

    const slidesMaxIndex =
      (hasWarmup ? 1 : 0) +
      (hasDrills ? 1 : 0) +
      (hasTraining ? 1 : 0) +
      (hasCoolDown ? 1 : 0) -
      1
    var showLeftIcon = () => {
      return this.state.activeIndex !== 0
    }
    var showRightIcon = () => {
      return this.state.activeIndex < slidesMaxIndex
    }

    if (hasWarmup) {
      swipeableHeader.push(
        <div key="warmup">
          <Icon
            name="chevron left"
            size="large"
            className={showLeftIcon() ? iconClassLeft : transparentClassLeft}
          />
          <Icon
            name="chevron right"
            size="large"
            className={showRightIcon() ? iconClassRight : transparentClassRight}
            onClick={showRightIcon() ? this.nextView : () => {}}
          />
          <Header as="h3" textAlign="center" className="bottom-10">
            {polyglot.t('usertraining.course.warmup')}
          </Header>{' '}
        </div>
      )

      swipeableContent.push(
        <List inverted relaxed key="warmup">
          {this.state.myClass.publicWarmUp && (
            <List.Item>
              <List.Content>
                <Segment.Group>
                  <Segment
                    basic
                    textAlign="left"
                    style={{ padding: '10px', fontSize: '1.2em' }}
                  >
                    {this.state.myClass.publicWarmUp}
                  </Segment>
                </Segment.Group>
              </List.Content>
            </List.Item>
          )}
          {this.state.myClass.warmupDrills.length !== 0 && (
            <List.Item>
              <List.Content>
                <Segment.Group>{courseWarmupDrills}</Segment.Group>
              </List.Content>
            </List.Item>
          )}
        </List>
      )
    }
    if (hasDrills) {
      swipeableHeader.push(
        <div key="drills">
          <Icon
            name="chevron left"
            size="large"
            className={showLeftIcon() ? iconClassLeft : transparentClassLeft}
            onClick={showLeftIcon() ? this.prevView : () => {}}
          />

          <Icon
            name="chevron right"
            size="large"
            className={showRightIcon() ? iconClassRight : transparentClassRight}
            onClick={showRightIcon() ? this.nextView : () => {}}
          />
          <Header as="h3" textAlign="center" className="bottom-10">
            {polyglot.t('usertraining.course.technique')}
          </Header>
        </div>
      )
      swipeableContent.push(
        <List inverted relaxed key="drills">
          {this.state.myClass.drills.length !== 0 && (
            <List.Item>
              <List.Content>
                <Segment.Group>{courseDrills}</Segment.Group>
              </List.Content>
            </List.Item>
          )}
          {this.state.myClass.publicSkills && (
            <List.Item>
              <List.Content>
                <Segment.Group>
                  <Segment basic textAlign="left" style={{ padding: '10px' }}>
                    {this.state.myClass.publicSkills}
                  </Segment>
                </Segment.Group>
              </List.Content>
            </List.Item>
          )}
        </List>
      )
    }
    if (hasTraining) {
      swipeableHeader.push(
        <div key="training">
          <Icon
            name="chevron left"
            size="large"
            className={showLeftIcon() ? iconClassLeft : transparentClassLeft}
            onClick={showLeftIcon() ? this.prevView : () => {}}
          />

          <Icon
            name="chevron right"
            size="large"
            className={showRightIcon() ? iconClassRight : transparentClassRight}
            onClick={showRightIcon() ? this.nextView : () => {}}
          />

          <Header as="h3" textAlign="center" className="bottom-10">
            {polyglot.t('usertraining.course.training')}
          </Header>
        </div>
      )
      swipeableContent.push(
        <List inverted relaxed key="training">
          {this.state.myClass.publicDescription && (
            <List.Item>
              <List.Content>
                <List.Description>
                  <Segment.Group>
                    <Segment
                      basic
                      textAlign="left"
                      style={{ padding: '10px', fontSize: '1.2em' }}
                    >
                      <Linkify
                        options={{
                          attributes: {
                            onClick: event => {
                              this.handleAnalysisUrl(event)
                            }
                          },
                          format: value => {
                            return this.formatLinks(value)
                          }
                        }}
                      >
                        {this.state.myClass.publicDescription}
                      </Linkify>
                    </Segment>
                  </Segment.Group>
                </List.Description>
              </List.Content>
            </List.Item>
          )}
          {this.state.myClass.publicFocus && (
            <List.Item>
              <List.Content></List.Content>
              <List.Content>
                <List.Description>
                  <Segment.Group>
                    <Segment
                      basic
                      textAlign="left"
                      style={{ padding: '10px', fontSize: '1.2em' }}
                    >
                      <Label
                        className="bottom-5"
                        color="red"
                        style={{ textTransform: 'uppercase' }}
                      >
                        {polyglot.t('usertraining.course.focus')}
                      </Label>
                      <div>{this.state.myClass.publicFocus}</div>
                    </Segment>
                  </Segment.Group>
                </List.Description>
              </List.Content>
            </List.Item>
          )}
          {/**
					 * 
          <List.Item>
            <List.Content>
              <List.Description>{timeSheet}</List.Description>
            </List.Content>
          </List.Item>
					 */}
        </List>
      )
    }
    if (hasCoolDown) {
      swipeableHeader.push(
        <div key="cooldown">
          <Icon
            name="chevron left"
            size="large"
            className={showLeftIcon() ? iconClassLeft : transparentClassLeft}
            onClick={this.prevView}
          />
          <Icon
            name="chevron right"
            size="large"
            className={showRightIcon() ? iconClassRight : transparentClassRight}
            onClick={showRightIcon() ? this.nextView : () => {}}
          />
          <Header as="h3" textAlign="center" className="bottom-10">
            {polyglot.t('usertraining.course.cooldown')}{' '}
          </Header>
        </div>
      )
      swipeableContent.push(
        <List inverted relaxed key="cooldown">
          {this.state.myClass.publicCoolDown && (
            <List.Item>
              <List.Content>
                <Segment.Group>
                  <Segment
                    basic
                    textAlign="left"
                    style={{ padding: '10px', fontSize: '1.2em' }}
                  >
                    {this.state.myClass.publicCoolDown}
                  </Segment>
                </Segment.Group>
              </List.Content>
            </List.Item>
          )}
        </List>
      )
    }

    return this.state.isLoading ? (
      <div className="ui active centered inline loader" />
    ) : this.state.hasError ? (
      <AppError
        message={this.state.error.problem ? this.state.error.problem : ''}
      />
    ) : (
      <div className="page ">
        <Container text>
          <style>{`
							.next-class .item .content .header{
						/*	border-bottom : 1px solid #636363;*/
						}
						.next-class .item .content .description{
							padding-left:5px;
						}
						.segments{
							background: rgba(0, 0, 0, 0.6) !important;
						}
					.page	.segments .segment {
						 color: #fff;
							border-top: 1px solid #3a3939 !important;
							border-right: 1px solid #3a3939 !important;
							border-left: 1px solid #3a3939 !important;
							
						}
						
						.segments .segment:last-child 					{
							border-bottom: 1px solid #3a3939 !important;
						}
						.page	.segments .segment a{
						 color: #ababab;
						}
			
	
						.segments .segment:last-child 					{
							border-bottom: 1px solid #3a3939 !important;
						}
						.segment .image {
							margin-right:1em !important;
						}
	
								
						.page	.ui.avatar.image {
							width: 3.5em;
							height: 3.5em;
							margin-top: 0.2rem;
							margin-bottom: 0.2rem;
						}
						.page .ui.segment 
						{
							padding:0.2em;
						}
						.ui.secondary.inverted.pointing.menu {
							border: none;
					}
					.ui.header{
						margin-top:5px;
					}
					.ui.list .list>.item>.content, .ui.list>.item>.content {
						line-height: normal;
				}
				
						`}</style>
          <Header as="h2" className="color-white center-text uppercase  top-10">
            {this.state.myClass.label}
          </Header>
          <Divider />
          <div className="next-class pad-bottom-10">
            <Card.Content
              className="bottom-20"
              style={{ position: 'relative' }}
            >
              <Link
                to={'/userTrainingLog/' + this.state.myClass.id}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '-5px'
                }}
              >
                <Icon circular inverted name="edit" className="app-button" />
              </Link>
              <div
                style={{
                  marginTop: '-5px',
                  fontSize: '0.8rem',
                  color: '#c3c3c3'
                }}
              >
                {this.state.myClass.date &&
                  moment(this.state.myClass.date).format('ddd DD/MM/YYYY')}
              </div>

              <div style={{ clear: 'both' }} />

              <Card.Description style={{ textAlign: 'left' }}>
                <SwipeableViews
                  enableMouseEvents={true}
                  index={activeIndex}
                  onChangeIndex={this.handleChangeIndex}
                  disabled={!hasWarmupOrDrills && !hasCoolDown}
                  children={swipeableHeader}
                  onTransitionEnd={this.handleOnSwitching}
                ></SwipeableViews>

                <SwipeableViews
                  enableMouseEvents={true}
                  index={activeIndex}
                  onChangeIndex={this.handleChangeIndex}
                  disabled={!hasWarmupOrDrills && !hasCoolDown}
                  children={swipeableContent}
                ></SwipeableViews>
              </Card.Description>
            </Card.Content>

            <Modal
              basic
              centered={false}
              open={this.state.analysisModalIsOpen}
              onClose={this.analysisModalClose}
              closeIcon
            >
              <Modal.Content className="top-30">
                <Embed
                  autoplay={true}
                  brandedUI
                  hd={false}
                  id={this.state.analysisModalUrl}
                  active
                  source="youtube"
                />
              </Modal.Content>
              <Modal.Actions></Modal.Actions>
            </Modal>
          </div>
        </Container>
      </div>
    )
  }
}
UserTraining = withPolyglot()(UserTraining)
export default UserTraining
