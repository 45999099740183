import React, { Component, Fragment } from 'react'
import {
  Dimmer,
  Loader,
  Icon,
  Header,
  Divider,
  Modal,
  Segment,
  Container
} from 'semantic-ui-react'
import { getMe, getMyRealPerfsAndFactor, getMyAnalyses } from '../services/Api'
import { withPolyglot } from 'polyglot-react'
import moment from 'moment'
import AppError from '../components/AppError'
import 'react-datepicker/dist/react-datepicker.css'
import UserAnalysis from '../components/UserAnalysis'
import { Config } from '../config'
import ReactGA from 'react-ga'
ReactGA.initialize(Config.gaId)

class RunnerProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      advice: '',
      hasError: false,
      error: false,
      isLoading: true,
      slowingFactor: null,
      slowingFactorFiability: null,
      showHeader: true,
      userPicForceReload: '',
      perfs: [],
      analysis: {}
    }
  }
  async componentDidMount() {
    var user = await this.getCurrentUser()
    var perfs_factor = await this.getSlowingFactor()
    var myAnalysis = await this.call_getMyAnalyses()

    this.setState(
      Object.assign(user, perfs_factor, myAnalysis, {
        hasError: false,
        isLoading: false
      })
    )
    // console.log(this.state)
    ReactGA.pageview(window.location.pathname + window.location.search)
    /* this.props.notificationRefresh()*/
  }
  async call_getMyAnalyses() {
    let response = await getMyAnalyses()
    if (response.ok) {
      return {
        analysis: response.data[0]
      }
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
    }
  }
  async getSlowingFactor() {
    let response = await getMyRealPerfsAndFactor()
    if (response.ok) {
      var data = response.data

      return data
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
    }
  }
  async getCurrentUser() {
    let response = await getMe()
    if (response.ok) {
      const data = response.data
      const user = {
        name: data.name
      }
      return { user }
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
    }
  }

  render() {
    const { polyglot } = this.props

    const hasPerfs = this.state.perfs.join('') !== ''
    console.log(this.state.analysis)
    const { analysis } = this.state

    return (
      <div className="page">
        <Container text>
          <Dimmer active={this.state.isLoading}>
            <Loader />
          </Dimmer>
          {this.state.hasError && (
            <AppError
              message={this.state.error.problem ? this.state.error.problem : ''}
            />
          )}
          {!this.state.hasError && (
            <div>
              <style>{`
						.profilePicture img.ui.image{
							display: inline-block;
						}
						
						.slowing-factor-fiability-1 .icon,
						.slowing-factor-fiability-2 .icon,
						.slowing-factor-fiability-3 .icon
						{
					
							position: relative;
							top: -15px;
							font-size: 0.7em;
						}
            .slowing-factor-fiability-1{
              color:green
            }
            .slowing-factor-fiability-2{
              color:#ef7f1d;
            }
            .slowing-factor-fiability-3{
              color:red
						}
					
				

						 .page	.segment {
						 border-top: 1px solid grey !important;
						 border-right: 1px solid grey !important;
						 border-left: 1px solid grey !important;
				
						 border-bottom: 1px solid grey!important;
						 background: rgba(0, 0, 0, 0.6) !important;
						 border-radius: 0.28571429rem;
						 }

						 .page	.segment .segment {
							color: #ababab;
							 border-top: 1px solid #3a3939  !important;
							 border-right: 1px solid #3a3939 !important;
							 border-left: 1px solid #3a3939 !important;
					
							 border-bottom: 1px solid #3a3939 !important;
						 }

						 .perf-label{
							 width:80px;
							 float:left;
						
						 }
						 
						 .perf-data{
							width:80px;
							 float:left;
						}
						.perf-data .date{
							position:absolute;
							top:5px;
							right:5px
						}
				
						`}</style>

              <Header
                as="h2"
                className="color-white center-text uppercase  top-10"
              >
                {polyglot.t('runnerprofile.runner.profile')}
              </Header>
              <Divider />
              {analysis && analysis.fault && (
                <Segment>
                  <Header className="no-capitalize">
                    {polyglot.t('runnerprofile.main.deviation')}
                  </Header>
                  <h4 className=" bold ">
                    <Fragment>
                      <span
                        style={{
                          color: 'red',
                          fontSize: '20px',
                          fontWeight: 'bold'
                        }}
                      >
                        {' '}
                        {polyglot.t('faults.' + analysis.fault.code)}
                      </span>
                      {/*
											 {this.state.user.lastAdvice}
											*/}
                      <Modal
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{ marginLeft: '15px' }}
                          />
                        }
                        onOpen={() =>
                          ReactGA.event({
                            category: 'User',
                            action: 'OpenFaultDetail'
                          })
                        }
                        onClose={() =>
                          ReactGA.event({
                            category: 'User',
                            action: 'CloseFaultDetail'
                          })
                        }
                        closeIcon
                      >
                        <Modal.Header className="no-capitalize">
                          {polyglot.t('faults.' + analysis.fault.code)}
                        </Modal.Header>
                        <Modal.Content image scrolling>
                          <Modal.Description
                            style={{ color: 'rgba(0,0,0,.87)' }}
                          >
                            <p>
                              {polyglot.t(
                                'help.faults.' +
                                  analysis.fault.code +
                                  '.content'
                              )}
                            </p>
                          </Modal.Description>
                        </Modal.Content>
                      </Modal>
                    </Fragment>
                  </h4>
                </Segment>
              )}

              {analysis && analysis.focus && (
                <Segment>
                  <Header className="no-capitalize">
                    {polyglot.t('runnerprofile.technique.focus')}
                  </Header>
                  <h4 className=" bold ">
                    <Fragment>
                      <span
                        style={{
                          color: 'grey',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          textTransform: 'uppercase'
                        }}
                      >
                        {analysis.focus}
                      </span>
                      {/*
											 {this.state.user.lastAdvice}
											*/}
                    </Fragment>
                  </h4>
                </Segment>
              )}

              {this.state.perfs && (
                <Segment>
                  <Header className="no-capitalize">
                    {polyglot.t('runnerprofile.perf.all')}
                  </Header>
                  {!hasPerfs && (
                    <Fragment>
                      {polyglot.t('runnerprofile.noPerfs')}
                      <div className="actions-placeholder">
                        <div
                          className="call-to-action"
                          style={{ width: '80px' }}
                        >
                          <span
                            className="action top-20"
                            onClick={() =>
                              this.props.history.push('/userPerfs')
                            }
                          >
                            <Icon name="stopwatch" />
                            <em> {polyglot.t('home.action.times')} </em>
                          </span>
                        </div>
                      </div>
                    </Fragment>
                  )}
                  {this.state.perfs.map(
                    e =>
                      e[0] &&
                      e[0].length !== 0 && (
                        <Segment
                          key={e[0]._id}
                          compact
                          basic
                          style={{ margin: '0px auto 10px' }}
                        >
                          <div
                            className="perf-label top-10"
                            style={{
                              fontSize: '25px',
                              fontWeight: 'bold'
                            }}
                          >
                            {e[0].distance}
                            <span
                              style={{
                                fontSize: '15px'
                              }}
                            >
                              {e[0].unit === 'meter' ? 'm' : ''}
                            </span>
                          </div>
                          <div className="perf-data ">
                            <div
                              className="date"
                              style={{
                                fontSize: '12px',
                                color: 'grey'
                              }}
                            >
                              {moment(e[0].date).format('LL')}
                            </div>
                            <div
                              className=" top-15"
                              style={{
                                fontSize: '20px'
                              }}
                            >
                              {moment('1900-01-01 00:00:00')
                                .add(e[0].time, 'seconds')
                                .format('mm:ss')}
                            </div>
                          </div>
                        </Segment>
                      )
                  )}
                </Segment>
              )}
              {this.state.slowingFactor && (
                <Segment>
                  <Header className="no-capitalize">
                    {polyglot.t('runnerprofile.fatigueFactor')}{' '}
                    <Modal
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            marginTop: '-15px',
                            marginLeft: '15px',
                            fontSize: '15px'
                          }}
                        />
                      }
                      onOpen={() =>
                        ReactGA.event({
                          category: 'User',
                          action: 'OpenSlowingFactorDetail'
                        })
                      }
                      onClose={() =>
                        ReactGA.event({
                          category: 'User',
                          action: 'CloseSlowingFactorDetail'
                        })
                      }
                      closeIcon
                    >
                      <Modal.Header className="no-capitalize">
                        {polyglot.t('help.slowingFactorAndProfile.header')}
                      </Modal.Header>
                      <Modal.Content image scrolling>
                        <Modal.Description style={{ color: 'rgba(0,0,0,.87)' }}>
                          <p>
                            {polyglot.t('help.slowingFactorAndProfile.content')}
                          </p>
                        </Modal.Description>
                      </Modal.Content>
                    </Modal>
                  </Header>

                  <Fragment>
                    <div className={'color-white'} style={{ fontSize: '25px' }}>
                      {this.state.slowingFactor}{' '}
                      {this.state.slowingFactorFiability === 1 && (
                        <span
                          className={
                            'slowing-factor-fiability-' +
                            this.state.slowingFactorFiability
                          }
                        >
                          <Icon name="check" style={{}} />
                        </span>
                      )}
                      {this.state.slowingFactorFiability === 2 && (
                        <span
                          className={
                            'slowing-factor-fiability-' +
                            this.state.slowingFactorFiability
                          }
                        >
                          <Icon name="minus" />
                        </span>
                      )}
                      {this.state.slowingFactorFiability === 3 && (
                        <span
                          className={
                            'slowing-factor-fiability-' +
                            this.state.slowingFactorFiability
                          }
                        >
                          <Icon name="exclamation" />
                        </span>
                      )}
                    </div>
                    <span>
                      <span style={{ color: 'grey' }}>
                        {polyglot.t('runnerprofile.improvement.area')} :{' '}
                      </span>
                      {this.state.slowingFactor <= 20
                        ? polyglot.t('runnerprofile.improvement.area.speed')
                        : polyglot.t(
                            'runnerprofile.improvement.area.endurance'
                          )}
                    </span>
                  </Fragment>
                </Segment>
              )}
              {this.state.analysis && <UserAnalysis {...this.state.analysis} />}
              {!this.state.analysis && (
                <Segment>{polyglot.t('home.technique.analysis.none')}</Segment>
              )}
            </div>
          )}
        </Container>
      </div>
    )
  }
}
RunnerProfile = withPolyglot()(RunnerProfile)
export default RunnerProfile
