import React, { Component, Fragment } from "react"
import { Form, TextArea, Modal, Button, Header, Message, Icon } from "semantic-ui-react"
import { withPolyglot } from "polyglot-react"
import { createAdvice, updateAdvice } from "../services/Api"

class Edit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      message: this.props.cellContent,
      initialMessage: this.props.cellContent,
      adviceId: this.props.cellAdviceId,
      modalOpen: false,
      mode: "",
      status: ""
    }
    //this.call_create=this.call_create.bind(this)
  }
  handleClose = () => this.setState({ modalOpen: false })

  handleValue(e, key) {
    this.setState({ ...this.state, [key]: e.target.value })
  }

  handleOpenEdit = () => {
    const newState = Object.assign({}, this.state, {
      initialMessage: this.state.message,
      mode: "edit",
      modalOpen: true
    })
    this.setState(newState)
  }

  handleOpenNew = () => {
    const newState = Object.assign({}, this.state, {
      mode: "new",
      modalOpen: true
    })
    this.setState(newState)
  }

  displayStatus(status) {
    this.setState({ status: status })
    setTimeout(() => {
      this.setState({ status: "" })
    }, 2000)
  }

  async call_create() {
    this.handleClose()
    const created = await createAdvice({ user: this.props.userId, message: this.state.message })
    if (created.ok) {
      this.state.adviceId = created.data.id
      this.displayStatus("ok")
    } else {
      this.displayStatus("ko")
      this.setState({ message: this.state.initialMessage })
    }
  }

  async call_update() {
    this.handleClose()
    const updated = await updateAdvice({ advice: this.state.adviceId, message: this.state.message })
    if (updated.ok) {
      this.displayStatus("ok")
    } else {
      this.displayStatus("ko")
      this.setState({ message: this.state.initialMessage })
    }
  }

  submit = () => {
    const newState = Object.assign({}, this.state, {
      initialMessage: this.message
    })
    this.setState(newState)
    this.state.mode === "edit" ? this.call_update() : this.call_create()
  }
  discard = () => {
    const newState = Object.assign({}, this.state, {
      message: this.initialMessage,
      modalOpen: false
    })
    this.setState(newState)
  }

  render() {
    const { polyglot } = this.props
    return (
      <Fragment>
        {this.state.message}
        {this.state.status === "ok" && (
          <Message positive size="mini" style={{ textAlign: "center" }}>
            <Icon color="green" name="checkmark" size="large" style={{ marginLeft: "5px" }} />
          </Message>
        )}
        {this.state.status === "ko" && (
          <Message negative size="mini" style={{ textAlign: "center" }}>
            <Icon color="red" name="close" size="large" style={{ marginLeft: "5px" }} />
          </Message>
        )}
        <Icon name={"plus square outline"} onClick={this.handleOpenNew} className={"advice float-right"} />
        {this.state.message && (
          <Icon name={"edit outline"} onClick={this.handleOpenEdit} className={"advice float-right"} />
        )}
        <Modal closeIcon open={this.state.modalOpen}>
          <Modal.Content image>
            <Modal.Description>
              <Header> {polyglot.t("AdviceEdit.title")} </Header>
              <Form>
                <TextArea
                  placeholder=""
                  defaultValue={this.state.mode === "edit" ? this.state.message : ""}
                  onChange={(e) => this.handleValue(e, "message")}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={this.discard}>
              <Icon name="remove" />
            </Button>
            <Button color="green" onClick={this.submit}>
              <Icon name="checkmark" />
            </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }
}

Edit = withPolyglot()(Edit)
export default Edit
