import React, { Component, Fragment } from 'react'
import { Sidebar, Icon, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withPolyglot } from 'polyglot-react'
import ReactGA from 'react-ga'
try {
  localStorage.getItem && (window.storage = localStorage)
} catch (exception) {}
if (window.storage) {
  ReactGA.set({ dimension1: localStorage.RWJUserName })
}

class AppSidebar extends Component {
  render() {
    const { polyglot } = this.props
    return (
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        onHide={this.props.handleSidebarHide}
        vertical
        visible={this.props.visible}
        width="thin"
        style={{ paddingTop: '30px', color: 'grey' }}
      >
        <Icon
          name="close"
          style={{ position: 'absolute', top: '10px', right: '10px' }}
          onClick={() => this.props.handleSidebarHide()}
        />
        {/*
				<Menu.Item as="a" href="/" onClick={() => this.props.handleSidebarHide()}>
					<Icon name="home" />
					{polyglot.t("sidebar.home")}
				</Menu.Item>
		*
				<Menu.Item as={Link} to="/profile" onClick={() => this.props.handleSidebarHide()}>
					<Icon name="user" />
					{polyglot.t("sidebar.profile")}
				</Menu.Item>
				<Menu.Item as={Link} to="/userTrainings" onClick={() => this.props.handleSidebarHide()}>
					<Icon corner name="heartbeat" />
					{polyglot.t("sidebar.trainings")}
				</Menu.Item>
				 */}

        {localStorage.getItem('RWJRole') === 'coach' && (
          <Fragment>
            <Menu.Item
              as={Link}
              to="/notification-center"
              onClick={() => this.props.handleSidebarHide()}
            >
              <Icon name="bell" />
              {polyglot.t('sidebar.notification.center')}
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/classes"
              onClick={() => this.props.handleSidebarHide()}
            >
              <Icon name="calendar alternate outline" />
              {polyglot.t('sidebar.courses')}
            </Menu.Item>

            <Menu.Item
              as={Link}
              to="/athletes"
              onClick={() => this.props.handleSidebarHide()}
            >
              <Icon name="group" />
              {polyglot.t('sidebar.athletes')}
            </Menu.Item>

            <Menu.Item
              as={Link}
              to="/analyses"
              onClick={() => this.props.handleSidebarHide()}
            >
              <Icon name="video play" />
              {polyglot.t('sidebar.analyses')}
            </Menu.Item>

            <Menu.Item
              as={Link}
              to="/faults"
              onClick={() => this.props.handleSidebarHide()}
            >
              <Icon name="frown outline" />
              {polyglot.t('sidebar.faults')}
            </Menu.Item>

            {/*
            <Menu.Item as='a' href="/clues"  >
              <Icon name='smile outline' />
              {polyglot.t('sidebar.clues')}
            </Menu.Item>
          */}

            <Menu.Item
              as={Link}
              to="/drills"
              onClick={() => this.props.handleSidebarHide()}
            >
              <Icon name="wizard" />
              {polyglot.t('sidebar.drills')}
            </Menu.Item>
          </Fragment>
        )}
        {localStorage.getItem('RWJRole') === 'admin' && (
          <Fragment>
            <Menu.Item
              as={Link}
              to="/protocols"
              onClick={() => this.props.handleSidebarHide()}
            >
              <Icon name="wizard" />
              {polyglot.t('sidebar.protocols')}
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/drills"
              onClick={() => this.props.handleSidebarHide()}
            >
              <Icon name="list ul" />
              {polyglot.t('sidebar.drills')}
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/drills"
              onClick={() => this.props.handleSidebarHide()}
            >
              <Icon name="frown outline" />
              {polyglot.t('sidebar.faults')}
            </Menu.Item>
          </Fragment>
        )}
        <Menu.Item
          as={'a'}
          onClick={() => {
            ReactGA.event({ category: 'User', action: 'Refresh' })
            window.location.reload(true)
          }}
        >
          <Icon name="refresh" />
          {polyglot.t('sidebar.update.check')}
        </Menu.Item>

        <Menu.Item
          as={Link}
          to="/"
          onClick={() => {
            localStorage.removeItem('RWJUserIsActive')
            localStorage.removeItem('RWJToken')
            localStorage.removeItem('RWJRole')
            this.props.handleSidebarHide()
            ReactGA.event({
              category: 'User',
              action: 'Logout'
            })
          }}
        >
          <Icon name="sign-out alternate" />
          {polyglot.t('sidebar.sign_out')}
        </Menu.Item>
      </Sidebar>
    )
  }
}

AppSidebar = withPolyglot()(AppSidebar)
export default AppSidebar
