import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  Header,
  Grid,
  Card,
  Dimmer,
  Loader,
  Tab,
  Icon,
  Feed,
  List,
  Image,
  Table,
  Button
} from 'semantic-ui-react'
import {
  getCourse,
  getMyActiveAthletes,
  AddPlayerToClass,
  RemovePlayerFromClass,
  UpdateClass,
  getDrills,
  addDrillToClass,
  removeDrillFromClass,
  addWarmupDrillToClass,
  removeWarmupDrillFromClass,
  createNotifications,
  copyCourse,
  pickProtocol,
  getMyGroups
} from '../services/Api'
import { withPolyglot } from 'polyglot-react'
import { differenceBy, filter } from 'lodash'
import { RIEInput, RIETextArea, RIEToggle, RIESelect } from '@attently/riek'
import DatePicker, { registerLocale } from 'react-datepicker'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'

import AppError from '../components/AppError'

import enUS from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'
registerLocale('fr', fr)
registerLocale('en-US', enUS)

class Course extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      players: [],
      myAthletes: [],
      myDrills: [],
      courseDrills: [],
      courseWarmupDrills: [],
      courseData: {},
      notificationData: {},
      currentDrillBloc: 'warmup',
      groups: []
    }
    const { polyglot } = props
    this.state.notificationData.message = polyglot.t(
      'course.notification.default.message'
    )
    this.state.notificationData.title = polyglot.t(
      'course.notification.default.title'
    )

    this.call_updateClass = this.call_updateClass.bind(this)
    this.updateNotification = this.updateNotification.bind(this)
  }
  componentDidMount() {
    if (this.call_getCourse()) {
      this.call_getMyActiveAthletes()
      this.call_getDrills()
    }
  }
  call_getMyGroups = async () => {
    var response = await getMyGroups()
    if (response.ok) {
      let groups = response.data

      return groups
    } else {
      // Error
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }
  async call_getCourse() {
    const response = await getCourse(this.props.match.params.id)
    const groups = await this.call_getMyGroups()

    let myGroups = groups.map(x => {
      return { id: x, text: x }
    })
    // myGroups.unshift({ id: '', text: '-' })

    if (response.ok) {
      // store the new state object in the component's state
      this.setState({
        isLoading: false,
        hasError: false,
        players: response.data.players || [],
        courseDrills: response.data.drills || [],
        courseWarmupDrills: response.data.warmupDrills || [],
        courseData: response.data,
        groups: myGroups
      })
      return true
    } else {
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response
      })
      // store the new state object in the component's state
      this.setState(newState)
      return false
    }
  }

  call_pickProtocol = async type => {
    const response = await pickProtocol('neutral')
    if (response.ok) {
      this.setState({
        courseDrills: response.data.drills,
        courseWarmupDrills: response.data.warmupDrills.length
          ? response.data.warmupDrills
          : this.state.courseWarmupDrills,
        protocolLabel: response.data.label
      })
      this.call_updateClass({
        drills: this.state.courseDrills.map(drill => drill._id),
        warmupDrills: this.state.courseWarmupDrills.map(drill => drill._id)
      })
      return false
    } else {
      // store the new state object in the component's state
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
      return false
    }
  }

  call_copyCourse = async () => {
    const response = await copyCourse(this.props.match.params.id)
    if (response.ok) {
      if (response.data && response.data.id) {
        this.props.history.push('/class/' + response.data.id)
        window.location.reload()
      }

      // store the new state object in the component's state

      return false
    } else {
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response
      })
      // store the new state object in the component's state
      this.setState(newState)
      return false
    }
  }

  async call_getMyActiveAthletes() {
    const response = await getMyActiveAthletes()
    if (response.ok) {
      let data = response.data.rows
      const newState = Object.assign({}, this.state, {
        myAthletes: data,
        isLoading: false,
        hasError: false
      })
      // store the new state object in the component's state
      this.setState(newState)
    } else {
      // Error
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }
  async call_getDrills() {
    const response = await getDrills()
    if (response.ok) {
      const data = response.data.rows

      this.setState({
        myDrills: data,
        isLoading: false,
        hasError: false,
        redirectToReferrer: true
      })
    } else {
      // Error
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
    }
  }

  async addToClass(type, object) {
    switch (type) {
      case 'player':
        const player = object
        AddPlayerToClass(this.props.match.params.id, { userId: player.id })
          .then(response => {
            if (response.ok) {
              const newArray = this.state.players
              newArray.push(player)
              this.setState({ players: newArray })
            }
          })
          .catch()
        break
      case 'drill':
        const drill = object
        var response
        if (this.state.currentDrillBloc === 'warmup') {
          response = await addWarmupDrillToClass(this.props.match.params.id, {
            drillId: drill.id
          })
        } else {
          response = await addDrillToClass(this.props.match.params.id, {
            drillId: drill.id
          })
        }
        if (response.ok) {
          this.setState({
            courseDrills: response.data.drills,
            courseWarmupDrills: response.data.warmupDrills
          })
        }
        break
      default:
        break
    }
  }
  removeFromClass(type, object) {
    switch (type) {
      case 'player':
        const player = object
        RemovePlayerFromClass(this.props.match.params.id, {
          userId: player._id ? player._id : player.id
        })
          .then(response => {
            if (response.ok) {
              const newArray = response.data.players
              this.setState({ players: newArray })
            }
          })
          .catch()
        break
      case 'drill':
        const drill = object
        removeDrillFromClass(this.props.match.params.id, {
          drillId: drill._id || drill.id
        })
          .then(response => {
            if (response.ok) {
              this.setState({ courseDrills: response.data.drills })
            }
          })
          .catch()
        break
      case 'warmupDrill':
        const warmupDrill = object
        removeWarmupDrillFromClass(this.props.match.params.id, {
          drillId: warmupDrill._id || warmupDrill.id
        })
          .then(response => {
            if (response.ok) {
              this.setState({ courseWarmupDrills: response.data.warmupDrills })
            }
          })
          .catch()
        break
      default:
        break
    }
  }
  onDatePickerChange(date) {
    this.call_updateClass({ date: date })
  }
  call_updateClass(prop) {
    if (prop && prop.hasOwnProperty('date')) {
      prop['date'] = moment(prop.date, 'DD-MM-YYYY')
    }

    if (prop && prop.hasOwnProperty('group')) {
      console.log(prop.group)
      if (prop.group === 'CLICK TO EDIT') {
        prop = { group: '' }
      }
      if (typeof prop.group === 'object') {
        prop = { group: prop.group.id }
      }
    }

    // console.log(prop.group)
    // return
    let newCourseData = this.state.courseData
    Object.assign(newCourseData, prop)
    var valuesToUpdate = {
      date: newCourseData.date,
      isAvailable: newCourseData.isAvailable,
      type: newCourseData.type
    } //permet d'éviter que ces valeurs soit réinitalisées par la route server/bodymen
    Object.assign(valuesToUpdate, prop)
    UpdateClass(this.props.match.params.id, valuesToUpdate)
      .then(response => {
        if (response.ok) {
          this.setState({
            courseData: newCourseData
          })
        }
      })
      .catch()
  }
  updateNotification(prop) {
    let newNotificationData = this.state.notificationData
    Object.assign(newNotificationData, prop)
    const newState = Object.assign({}, this.state, {
      notificationData: newNotificationData
    })
    this.setState(newState)
  }
  call_createNotifications() {
    const { polyglot } = this.props
    let body = this.state.notificationData
    let sendTos = []
    sendTos = this.state.players.map(player => {
      return player._id ? player._id : player.id
    })
    body.sendTos = sendTos
    createNotifications(body)
      .then(response => {
        if (response.ok) {
          //this.setState(newState)
          toast.success(polyglot.t('generic.message.update'), {})
        } else {
          toast.error(polyglot.t('generic.message.error'), {})
        }
      })
      .catch()
  }
  render() {
    const { polyglot } = this.props
    var _this = this
    let myAthletes = this.state.myAthletes
    let players = this.state.players
    let myDrills = this.state.myDrills
    let courseDrills = this.state.courseDrills
    let courseWarmupDrills = this.state.courseWarmupDrills
    myAthletes.sort(function(a, b) {
      if (!a.name && !b.name) return 0
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })
    players.sort(function(a, b) {
      if (!a.name && !b.name) return 0
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })

    const playerList = players.map(function(player) {
      return (
        <List.Item key={player._id || player.id}>
          <List.Content>
            <List.Header>
              <Image avatar src={player.picture} />
              <Link to={'/athlete/' + player._id}>{player.name}</Link>
              <Icon
                name="close"
                color="red"
                onClick={() => _this.removeFromClass('player', player)}
                style={{ marginLeft: '10px' }}
              />
            </List.Header>
            <List.Description />
          </List.Content>
        </List.Item>
      )
    })
    const playerComments = players.map(function(player) {
      return (
        player.trainingLog && (
          <List.Item key={player._id ? player._id : player.id}>
            <List.Content>
              <List.Header>
                <Image avatar src={player.picture} />
                <Link to={'/athlete/' + player._id}>{player.name}</Link>
              </List.Header>
              <List.Description
                style={{
                  marginTop: '5px',
                  marginLeft: '30px',
                  marginBottom: '5px'
                }}
              >
                {player.trainingLog.comment}
              </List.Description>
            </List.Content>
          </List.Item>
        )
      )
    })

    /*on enleve le coach courant de la liste des athetes*/
    myAthletes = differenceBy(
      myAthletes,
      [{ id: localStorage.RWJUserId }],
      'id'
    )
    let athletesList = differenceBy(myAthletes, players, 'email').map(function(
      player
    ) {
      return (
        <List.Item key={player.id}>
          <Image avatar src={player.picture} />
          <List.Content>
            <List.Header>
              <Link to={'/athlete/' + player.id}>{player.name}</Link>
              <Icon
                name="check"
                color="green"
                onClick={() => _this.addToClass('player', player)}
                style={{ marginLeft: '10px' }}
              />
            </List.Header>
            <List.Description>
              {player.faults[0]
                ? polyglot.t('faults.' + player.faults[0].fault.code)
                : ''}
            </List.Description>
          </List.Content>
        </List.Item>
      )
    })
    const courseWarmupDrillsList = courseWarmupDrills.map(function(drill) {
      return (
        <List.Item key={drill._id || drill.id}>
          <List.Content>
            <List.Header>
              {drill.name}
              <Icon
                name="close"
                color="red"
                onClick={() => _this.removeFromClass('warmupDrill', drill)}
                style={{ marginLeft: '10px' }}
              />
            </List.Header>
            <List.Description />
          </List.Content>
        </List.Item>
      )
    })
    const courseDrillsList = courseDrills.map(function(drill) {
      return (
        <List.Item key={drill._id || drill.id}>
          <List.Content>
            <List.Header>
              {drill.name}
              <Icon
                name="close"
                color="red"
                onClick={() => _this.removeFromClass('drill', drill)}
                style={{ marginLeft: '10px' }}
              />
            </List.Header>
            <List.Description />
          </List.Content>
        </List.Item>
      )
    })
    let drillsList = category =>
      filter(
        differenceBy(myDrills, courseDrills.concat(courseWarmupDrills), 'name'),
        function(o) {
          if (category === 'warmup' || category === 'strength') {
            return o.category === category
          } else {
            return o.category !== 'warmup' && o.category !== 'strength'
          }
        }
      ).map(function(drill) {
        return (
          <Table.Row key={drill.id}>
            <Table.Cell>
              {drill.image && (
                <Image src={'/drills/' + drill.image} avatar size="tiny" />
              )}
            </Table.Cell>
            <Table.Cell>{drill.name}</Table.Cell>
            <Table.Cell>{drill.analysis}</Table.Cell>
            <Table.Cell>{drill.category}</Table.Cell>
            <Table.Cell>{drill.description}</Table.Cell>
            <Table.Cell>{drill.duration}</Table.Cell>
            <Table.Cell>
              <Icon
                name="check"
                color="green"
                onClick={() => _this.addToClass('drill', drill)}
                style={{ marginLeft: '10px' }}
              />
            </Table.Cell>
          </Table.Row>
        )
      })

    return (
      <Fragment>
        <div className="main-container course">
          <Dimmer active={this.state.isLoading}>
            <Loader />
          </Dimmer>
          {this.state.hasError && !this.state.isLoading && (
            <AppError
              message={this.state.error.problem ? this.state.error.problem : ''}
            />
          )}
          {!this.state.hasError && !this.state.isLoading && (
            <div>
              <style>{`
            .user-list{text-align:left }
            .riek {
              display: block;
              width: 100%;
              min-height: 12px;
            }
            textarea.riek {
              min-height: 200px;
            }
            .react-datepicker__input-container input {
              border:none;
						}
						.ui.header>.icon.small
						{
							font-size: .75em!important;
							cursor:pointer;

						}

						`}</style>

              <Grid stackable columns={2} divided style={{ height: '100%' }}>
                <Grid.Row
                  style={{
                    paddingLeft: '2rem'
                  }}
                >
                  <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                    <div style={{ float: 'right' }}>
                      <RIEToggle
                        value={this.state.courseData.isAvailable || ''}
                        className={this.state.highlight ? 'editable' : ''}
                        change={this.call_updateClass}
                        classLoading="loading"
                        propName="isAvailable"
                        textTrue={polyglot.t('course.isAvailable')}
                        textFalse={polyglot.t('course.isAvailable.not')}
                      />
                    </div>
                    <Header as="h2">
                      <RIEInput
                        value={this.state.courseData.label || ''}
                        change={this.call_updateClass}
                        propName="label"
                        className={this.state.courseData.label ? ' ' : ' riek'}
                        classLoading="loading"
                        classInvalid="invalid"
                      />{' '}
                      - {moment(this.state.courseData.date).format('ddd ')}
                      {/*


                    <RIEInput
                    value=  {	moment(this.state.courseData.date).format('DD/MM/YYYY')}
                    change={this.call_updateClass}
                    propName="date"
                    className={this.state.courseData.label ? " " : " riek"}
                    classLoading="loading"
                    classInvalid="invalid"

                    />
                       */}
                      <DatePicker
                        id="date"
                        dateFormat="dd/MM/yyyy"
                        selected={moment(this.state.courseData.date).toDate()}
                        onChange={this.onDatePickerChange.bind(this)}
                        propName="date"
                        locale={polyglot.t('locale')}
                      />
                      <Icon
                        name="copy"
                        size="small"
                        onClick={this.call_copyCourse}
                        style={{ marginLeft: '10px', float: 'right' }}
                      />
                    </Header>
                    <Grid stackable columns={2}>
                      <Grid.Column className="card-white pad-bottom-30 pad-top-15 card-description">
                        <Card
                          fluid
                          style={
                            this.state.currentDrillBloc === 'warmup'
                              ? { border: '1px solid grey' }
                              : {}
                          }
                          onClick={() =>
                            this.setState({ currentDrillBloc: 'warmup' })
                          }
                        >
                          <Card.Content textAlign="left">
                            <Card.Header>
                              <Icon
                                name="magic"
                                size="small"
                                onClick={this.call_pickProtocol}
                                style={{ marginLeft: '10px', float: 'right' }}
                              />
                              {polyglot.t('course.warmup')}
                              {this.state.protocolLabel && (
                                <span> ({this.state.protocolLabel})</span>
                              )}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <Feed>
                              <RIETextArea
                                value={this.state.courseData.publicWarmUp || ''}
                                change={this.call_updateClass}
                                propName="publicWarmUp"
                                defaultValue=""
                                className={
                                  this.state.highlight
                                    ? 'editable riek'
                                    : 'riek'
                                }
                                classLoading="loading"
                                classInvalid="invalid"
                              />
                            </Feed>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <List style={{ textAlign: 'left' }}>
                              {courseWarmupDrillsList}
                            </List>
                          </Card.Content>
                        </Card>
                        <Card
                          fluid
                          style={
                            this.state.currentDrillBloc === 'technique'
                              ? { border: '1px solid grey' }
                              : {}
                          }
                          onClick={() =>
                            this.setState({ currentDrillBloc: 'technique' })
                          }
                        >
                          <Card.Content textAlign="left">
                            <Card.Header>
                              {polyglot.t('course.technique')}
                            </Card.Header>
                          </Card.Content>{' '}
                          <Card.Content textAlign="left">
                            <List style={{ textAlign: 'left' }}>
                              {courseDrillsList}
                            </List>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <Feed>
                              <RIETextArea
                                value={this.state.courseData.publicSkills || ''}
                                change={this.call_updateClass}
                                propName="publicSkills"
                                defaultValue=""
                                className={
                                  this.state.highlight
                                    ? 'editable riek'
                                    : 'riek'
                                }
                                classLoading="loading"
                                classInvalid="invalid"
                              />
                            </Feed>
                          </Card.Content>
                        </Card>

                        <Card fluid>
                          <Card.Content textAlign="left">
                            <Card.Header></Card.Header>
                          </Card.Content>
                        </Card>

                        <Card fluid>
                          <Card.Content textAlign="left">
                            <Card.Header>
                              {polyglot.t('course.publicDescription')}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <Feed>
                              <RIETextArea
                                value={
                                  this.state.courseData.publicDescription || ''
                                }
                                change={this.call_updateClass}
                                propName="publicDescription"
                                className={
                                  this.state.highlight
                                    ? 'editable riek'
                                    : 'riek'
                                }
                                defaultValue=""
                                classLoading="loading"
                                classInvalid="invalid"
                              />
                            </Feed>
                          </Card.Content>
                        </Card>
                        <Card fluid>
                          <Card.Content textAlign="left">
                            <Card.Header>
                              {polyglot.t('course.focus')}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <RIETextArea
                              value={this.state.courseData.publicFocus || ''}
                              change={this.call_updateClass}
                              propName="publicFocus"
                              className={
                                this.state.highlight ? 'editable riek' : 'riek'
                              }
                              classLoading="loading"
                              classInvalid="invalid"
                            />
                          </Card.Content>
                        </Card>
                        <Card fluid>
                          <Card.Content textAlign="left">
                            <Card.Header>
                              {polyglot.t('course.cooldown')}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <RIETextArea
                              value={this.state.courseData.publicCoolDown || ''}
                              change={this.call_updateClass}
                              propName="publicCoolDown"
                              className={
                                this.state.highlight ? 'editable riek' : 'riek'
                              }
                              classLoading="loading"
                              classInvalid="invalid"
                              defaultValue=""
                            />
                          </Card.Content>
                        </Card>
                      </Grid.Column>
                      <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                        <Card fluid>
                          <Card.Content textAlign="left">
                            <Card.Header>
                              {polyglot.t('course.description')}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <Feed>
                              <RIETextArea
                                value={this.state.courseData.description || ''}
                                change={this.call_updateClass}
                                propName="description"
                                className={
                                  this.state.highlight
                                    ? 'editable riek'
                                    : 'riek'
                                }
                                classLoading="loading"
                                classInvalid="invalid"
                              />
                            </Feed>
                          </Card.Content>
                        </Card>
                        <Card fluid>
                          <Card.Content textAlign="left">
                            <Card.Header>
                              {polyglot.t('course.group')}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content textAlign="left">
                            <div>
                              <span style={{ fontWeight: 'bold' }}>
                                {polyglot.t('generic.select.label')} :{''}
                              </span>
                              <span
                                style={{
                                  display: 'inline-block',
                                  minWidth: '50px',
                                  marginLeft: '5px'
                                }}
                              >
                                <RIESelect
                                  value={{
                                    id: this.state.courseData.group,
                                    text: this.state.courseData.group
                                  }}
                                  className={
                                    this.state.courseData.group ? ' ' : ' riek'
                                  }
                                  change={this.call_updateClass}
                                  options={this.state.groups}
                                  classLoading="loading"
                                  propName="group"
                                  style={{
                                    display: 'inline-block',
                                    width: 'auto'
                                  }}
                                />
                              </span>
                            </div>
                            <div style={{ width: '50%' }}>
                              <span style={{ fontWeight: 'bold' }}>
                                {polyglot.t('course.group.or.new')}
                              </span>
                              <RIEInput
                                value={''}
                                change={this.call_updateClass}
                                propName="group"
                                className={
                                  this.state.courseData.group ? ' ' : ' riek'
                                }
                                classLoading="loading"
                                classInvalid="invalid"
                              />
                            </div>
                          </Card.Content>
                        </Card>
                        <Card fluid>
                          <Card.Content textAlign="left">
                            <Card.Header>
                              {polyglot.t('course.attendees')}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content>
                            <List style={{ textAlign: 'left' }}>
                              {playerList}
                            </List>
                          </Card.Content>
                        </Card>
                        <Card fluid>
                          <Card.Content textAlign="left">
                            <Card.Header>
                              {polyglot.t('course.trainingLogs')}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content>
                            <List style={{ textAlign: 'left' }}>
                              {playerComments}
                            </List>
                          </Card.Content>
                        </Card>
                        <Card fluid>
                          <Card.Content>
                            <Card.Header>
                              {polyglot.t('notification')}
                            </Card.Header>
                          </Card.Content>
                          <Card.Content>
                            <Feed>
                              <Feed.Summary>
                                <strong>
                                  {polyglot.t('notification.title')}
                                </strong>
                                <RIEInput
                                  value={this.state.notificationData.title}
                                  change={this.updateNotification}
                                  propName="title"
                                  className={
                                    this.state.highlight
                                      ? 'editable riek'
                                      : 'riek'
                                  }
                                  classLoading="loading"
                                  classInvalid="invalid"
                                />
                              </Feed.Summary>
                              <Feed.Extra text>
                                <strong>
                                  {polyglot.t('notification.message')}
                                </strong>
                                <RIETextArea
                                  value={this.state.notificationData.message}
                                  change={this.updateNotification}
                                  propName="message"
                                  className={
                                    this.state.highlight
                                      ? 'editable riek'
                                      : 'riek'
                                  }
                                  classLoading="loading"
                                  classInvalid="invalid"
                                />
                              </Feed.Extra>
                            </Feed>
                          </Card.Content>
                          <Card.Content extra>
                            <Button
                              color="black"
                              onClick={this.call_createNotifications.bind(this)}
                            >
                              Envoyer
                            </Button>
                          </Card.Content>
                        </Card>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                    {true ? (
                      <Fragment>
                        <Tab
                          menu={{ secondary: true, pointing: true }}
                          panes={[
                            {
                              menuItem: polyglot.t('course.attendees'),
                              render: () => (
                                <Tab.Pane attached={true}>
                                  <Card>
                                    <Card.Content>
                                      <Card.Header>
                                        {polyglot.t('course.athletes.my')}
                                      </Card.Header>
                                    </Card.Content>
                                    <Card.Content>
                                      <List style={{ textAlign: 'left' }}>
                                        {athletesList}
                                      </List>
                                    </Card.Content>
                                  </Card>
                                </Tab.Pane>
                              )
                            },
                            {
                              menuItem: polyglot.t('course.warmup'),
                              render: () => (
                                <Tab.Pane attached={false}>
                                  <Table basic="very" celled collapsing>
                                    <Table.Header>
                                      <Table.Row>
                                        <Table.HeaderCell> </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.name')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.video.url')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.category')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t(
                                            'course.drill.description'
                                          )}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.duration')}
                                        </Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                      {drillsList('warmup')}
                                    </Table.Body>
                                  </Table>
                                </Tab.Pane>
                              )
                            },
                            {
                              menuItem: polyglot.t('course.drills'),
                              render: () => (
                                <Tab.Pane attached={false}>
                                  <Table basic="very" celled collapsing>
                                    <Table.Header>
                                      <Table.Row>
                                        <Table.HeaderCell> </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.name')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.video.url')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.category')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t(
                                            'course.drill.description'
                                          )}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.duration')}
                                        </Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Header>
                                    <Table.Body>{drillsList()}</Table.Body>
                                  </Table>
                                </Tab.Pane>
                              )
                            },
                            {
                              menuItem: polyglot.t('course.strength'),
                              render: () => (
                                <Tab.Pane attached={false}>
                                  <Table basic="very" celled collapsing>
                                    <Table.Header>
                                      <Table.Row>
                                        <Table.HeaderCell> </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.name')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.video.url')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.category')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t(
                                            'course.drill.description'
                                          )}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.duration')}
                                        </Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                      {drillsList('strength')}
                                    </Table.Body>
                                  </Table>
                                </Tab.Pane>
                              )
                            },
                            {
                              menuItem: polyglot.t('course.mobility'),
                              render: () => (
                                <Tab.Pane attached={false}>
                                  <Table basic="very" celled collapsing>
                                    <Table.Header>
                                      <Table.Row>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.name')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.category')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t(
                                            'course.drill.description'
                                          )}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {polyglot.t('course.drill.duration')}
                                        </Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Header>
                                    <Table.Body />
                                  </Table>
                                </Tab.Pane>
                              )
                            }
                          ]}
                        />
                      </Fragment>
                    ) : null}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )}
          <ToastContainer autoClose={3000} />
        </div>
      </Fragment>
    )
  }
}

Course = withPolyglot()(Course)
export default withRouter(Course)
