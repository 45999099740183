import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import { Header, Grid, Card, Dimmer, Loader, Tab, Feed, List, Container, Button, Segment } from "semantic-ui-react"
import { getAthlete, getAthletesCourses, createCourse } from "../services/Api"
import { withPolyglot } from "polyglot-react"
import ReactGA from "react-ga"
//import { RIEInput, RIETextArea  } from '@attently/riek'
import moment from "moment"
import AppError from "../components/AppError"
class Athlete extends Component {
	// default State object
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
			error: false,
			isLoading: true,
			players: [],

			athleteData: {},
			athleteCourses: []
		}
		// this.call_updateClass=this.call_updateClass.bind(this)
	}
	componentDidMount() {
		if (this.call_getAthlete()) {
			this.call_getAthletesCourses()
			ReactGA.pageview(window.location.pathname + window.location.search)
			//  this.call_getDrills()
		}
	}

	async call_getAthlete() {
		const response = await getAthlete(this.props.match.params.id)
		if (response.ok) {
			const newState = Object.assign({}, this.state, {
				isLoading: false,
				hasError: false,

				athleteData: response.data
			})
			// store the new state object in the component's state
			this.setState(newState)
			return true
		} else {
			const newState = Object.assign({}, this.state, {
				isLoading: false,
				hasError: true,
				error: response
			})
			// store the new state object in the component's state
			this.setState(newState)
			return false
		}
	}
	async call_getAthletesCourses() {
		const response = await getAthletesCourses(this.props.match.params.id)
		if (response.ok) {
			let data = response.data.rows
			console.log(data)
			const newState = Object.assign({}, this.state, {
				athleteCourses: data,
				isLoading: false,
				hasError: false
			})
			// store the new state object in the component's state
			this.setState(newState)
		} else {
			// Error
			const newState = Object.assign({}, this.state, {
				isLoading: false,
				hasError: true,
				error: response
			})
			// store the new state object in the component's state
			this.setState(newState)
		}
	}
	call_createCourse() {
		const { polyglot } = this.props
		const defaultParams = {
			label: polyglot.t("courses.new"),
			type: "",
			description: "",
			publicDescription: "",
			players: [this.state.athleteData.id]
		}
		createCourse(defaultParams)
			.then((res) => {
				if (res.ok) {
					const newId = res.data._id
					this.props.history.push("/class/" + newId)
				}
			})
			.catch()
	}

	render() {
		const { polyglot } = this.props
		let athleteCourses = this.state.athleteCourses
		const coursesList = athleteCourses.map(function(course) {
			return (
				<List.Item key={course.id} className="pad-bottom-5">
					<List.Content floated="right">{moment(course.date).format("ddd DD/MM/YYYY")}</List.Content>
					<List.Content>
						<List.Header className={"bottom-5"}>
							{" "}
							<Link to={"/class/" + course.id}> {course.label} </Link>
							{/*  <Icon name='close' color='red' onClick={()=>_this.removeFromClass('drill',drill)}  style={{marginLeft:'10px'}} />*/}
						</List.Header>
						<List.Description className={"bottom-5"} style={{ fontWeight: "600" }}>
							{course.description}
						</List.Description>
					</List.Content>
					<List.Content>
						<List.Description>{course.publicDescription}</List.Description>
						{course.trainingLog && (
							<Segment>
								{course.trainingLog.done && (
									<Fragment>
										<b>{polyglot.t("traininglog.done.label")}</b> &nbsp; {polyglot.t("traininglog.date.label")} &nbsp;
										{moment(course.trainingLog.date).format("ddd DD/MM/YYYY")}
									</Fragment>
								)}
								<br />

								<b>{polyglot.t("traininglog.comments.label")}</b>
								<br />

								{course.trainingLog.comment}
							</Segment>
						)}
					</List.Content>
				</List.Item>
			)
		})

		return (
			<Fragment>
				<div className="main-container course">
					<Dimmer active={this.state.isLoading}>
						<Loader />
					</Dimmer>
					{this.state.hasError && !this.state.isLoading && (
						<div>
							<AppError message={this.state.error.problem ? this.state.error.problem : ""} />
						</div>
					)}
					{!this.state.hasError && !this.state.isLoading && (
						<div>
							<style>{`
            .user-list{text-align:left }
            .riek {
              display: block;
              width: 100%;
              min-height: 12px;
            }
            textarea.riek {
              min-height: 200px;

            }

						`}</style>
							<Container>
								<Grid stackable columns={2} divided style={{ height: "100%" }}>
									<Grid.Row>
										<Grid.Column className="card-white pad-bottom-30 pad-top-15">
											<Header as="h2">{this.state.athleteData.name}</Header>
											<Grid stackable columns={2}>
												<Grid.Column className="card-white pad-bottom-30 pad-top-15 card-description">
													<Card className="card-description">
														<Card.Content>
															<Card.Header> {polyglot.t("course.description")}</Card.Header>
														</Card.Content>
														<Card.Content>
															<Feed />
														</Card.Content>
													</Card>
												</Grid.Column>
												<Grid.Column className="card-white pad-bottom-30 pad-top-15">
													<Card>
														<Card.Content>
															<Card.Header> {polyglot.t("course.warmup")}</Card.Header>
														</Card.Content>
														<Card.Content>
															<List style={{ textAlign: "left" }} />
														</Card.Content>
													</Card>
												</Grid.Column>
											</Grid>
										</Grid.Column>
										<Grid.Column className="card-white pad-bottom-30 pad-top-15">
											{true ? (
												<Fragment>
													<Tab
														menu={{ secondary: true, pointing: true }}
														panes={[
															{
																menuItem: polyglot.t("athlete.courses"),
																render: () => (
																	<Tab.Pane attached={true}>
																		<Button circular icon="plus" onClick={() => this.call_createCourse()} />

																		<List divided style={{ textAlign: "left" }}>
																			{coursesList}
																		</List>
																	</Tab.Pane>
																)
															}
														]}
													/>
												</Fragment>
											) : null}
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Container>
						</div>
					)}
				</div>
			</Fragment>
		)
	}
}

Athlete = withPolyglot()(Athlete)
export default Athlete
