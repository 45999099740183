import React, { Component, Fragment } from 'react'
import {
  Header,
  Container,
  Grid,
  Card,
  Dimmer,
  Loader,
  Form,
  Embed,
  Table,
  Button,
  Radio
} from 'semantic-ui-react'
import { getAnalysis, updateAnalysis, getFaults } from '../services/Api'
import { withPolyglot } from 'polyglot-react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { RIEInput, RIETextArea, RIEToggle, RIESelect } from '@attently/riek'
import moment from 'moment'
import ComputedAnalysis from '../components/ComputedAnalysis'

import AppError from '../components/AppError'

const fpsOptions = [
  { id: '30', text: '30fps' },
  { id: '60', text: '60fps' },
  { id: '120', text: '120fps' }
]

const focus = ['neutral', 'pose', 'fall', 'pull']

class Analysis extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: false,
      isLoading: true,
      analysisData: {},
      faults: [],
      analysisFault: {}
    }
    this.call_updateAnalysis = this.call_updateAnalysis.bind(this)
  }
  componentDidMount() {
    this.call_getAnalysis()
    this.callGetFaults()
  }

  async call_getAnalysis() {
    const { polyglot } = this.props
    const response = await getAnalysis(this.props.match.params.id)
    if (response.ok) {
      //    console.log(response)

      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: false,
        analysisData: response.data,
        analysisFault: response.data.fault
          ? {
              id: response.data.fault._id,
              text: polyglot.t('faults.' + response.data.fault.code)
            }
          : { id: '-', text: polyglot.t('generic.select.empty') }
      })
      // store the new state object in the component's state
      this.setState(newState)
      // console.log(this.state.analysisData)
      return true
    } else {
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response
      })
      // store the new state object in the component's state
      this.setState(newState)
      return false
    }
  }

  async callGetFaults() {
    const response = await getFaults()
    if (response.ok) {
      const data = response.data.rows

      const { polyglot } = this.props
      var data_translated = data.map(obj => {
        return { id: obj.id, text: polyglot.t('faults.' + obj.code) }
      })

      data_translated.unshift({
        id: '-',
        text: polyglot.t('generic.select.empty')
      })

      const newState = Object.assign({}, this.state, {
        faults: data_translated
      })

      this.setState(newState)
    }
  }

  handleRadio = (e, { value, propname }) => {
    this.call_updateAnalysis({ [propname]: value })
  }

  call_updateAnalysis(prop) {
    const { polyglot } = this.props
    if (prop.hasOwnProperty('date')) {
      prop['date'] = moment(prop.date, 'DD-MM-YYYY')
    }
    ;[
      'initialContactTime',
      'inPoseTime',
      'fallEndTime',
      'lastContactTime',
      'cadenceMark0',
      'cadenceMark1',
      'cadenceMark2',
      'cadenceMark3',
      'cadenceMark4'
    ].map(propname => {
      if (prop.hasOwnProperty(propname)) {
        // prop['initialContactTime'] = moment(prop.date, 'DD-MM-YYYY')
        //let t=moment("")

        let propValue = prop[propname]
        let t =
          propValue.indexOf(':') !== -1
            ? moment(propValue, 'm:s.S')
            : moment(propValue, 's.S')

        let tms =
          t.milliseconds() + t.seconds() * 1000 + t.minutes() * 60 * 1000

        prop[propname] = tms
      }
    })

    let newAnalysisData = this.state.analysisData
    if ('urlCode' in prop) {
      var code = prop['urlCode']
      if (code.indexOf('http') !== -1) {
        var codearray = code.split('/')
        code = codearray[codearray.length - 1]
        prop['urlCode'] = code
      }
    }
    Object.assign(newAnalysisData, prop)
    if ('fault' in prop) {
      Object.assign(newAnalysisData, { analysisFault: prop.fault })
    }
    const newState = Object.assign({}, this.state, {
      analysisData: newAnalysisData
    })
    this.setState(newState)
    //newAnalysisData.user=newAnalysisData.user instanceof Object ? newAnalysisData.user._id:newAnalysisData.user
    newAnalysisData.fps =
      newAnalysisData.fps instanceof Object
        ? newAnalysisData.fps.id
        : newAnalysisData.fps

    newAnalysisData.fault =
      newAnalysisData.fault instanceof Object
        ? newAnalysisData.fault.id
        : newAnalysisData.fault

    if (newAnalysisData.fault === '-') {
      newAnalysisData.fault = null
    }
    updateAnalysis(this.props.match.params.id, newAnalysisData)
      .then(response => {
        if (response.ok) {
          //this.setState(newState)
          toast.success(polyglot.t('generic.message.update'), {})
        } else {
          toast.error(polyglot.t('generic.message.error'), {})
          this.call_getAnalysis()
        }
      })
      .catch()
  }
  formatms = ms => {
    return moment('1900-01-01 00:00:00')
      .add(ms, 'milliseconds')
      .format('mm:ss.SSS')
  }
  msToFrames = (msStart, msEnd) => {
    let msDelta = msEnd - msStart
    let count = msDelta / 1000 / (1 / 60)
    return Math.round(count)
  }
  countFrames = () => {
    const {
      initialContactTime,
      inPoseTime,
      fallEndTime,
      lastContactTime
    } = this.state.analysisData

    let framesToPose = this.msToFrames(initialContactTime, inPoseTime)
    let framesFromPose = this.msToFrames(inPoseTime, lastContactTime)
    let framesFalling = this.msToFrames(inPoseTime, fallEndTime)

    this.call_updateAnalysis({ framesToPose, framesFromPose, framesFalling })
  }
  computeCadence = () => {
    const {
      cadenceMark0,
      cadenceMark1,
      cadenceMark2,
      cadenceMark3,
      cadenceMark4
    } = this.state.analysisData

    let cadence = cadenceMark4
      ? (240 / (cadenceMark4 - cadenceMark0)) * 1000
      : (180 / (cadenceMark3 - cadenceMark0)) * 1000

    cadence = Math.round(cadence)
    this.call_updateAnalysis({ cadence })
  }

  render() {
    const { polyglot } = this.props
    return (
      <Fragment>
        <div className="main-container course">
          <Dimmer active={this.state.isLoading}>
            <Loader />
          </Dimmer>
          {this.state.hasError && !this.state.isLoading && (
            <AppError
              message={this.state.error.problem ? this.state.error.problem : ''}
            />
          )}
          {!this.state.hasError && !this.state.isLoading && (
            <div>
              <style>{`
            .user-list{text-align:left }
            .riek {
              display: block;
              width: 100%;
              min-height: 12px;
            }
            textarea.riek {
              min-height: 200px;
            }
            .number{
              width:10px;
						}
						.time{
              width:70px;
            }
            .fluid .ui.card { width:100% }
            .color1{
              color:red;
            }
            .color2{
              color:#fbbd08;
            }
            .color3{
              color:green;
						}
						.ui.form .inline.fields>label {
							min-width: 10em!important;
					}

						`}</style>
              <Container>
                <Grid stackable columns={2} divided style={{ height: '100%' }}>
                  <Grid.Row>
                    <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                      <div style={{ float: 'right' }}>
                        <RIEToggle
                          value={this.state.analysisData.isAvailable || ''}
                          className={this.state.highlight ? 'editable' : ''}
                          change={this.call_updateAnalysis}
                          classLoading="loading"
                          propName="isAvailable"
                          textTrue={polyglot.t('analysis.isAvailable')}
                          textFalse={polyglot.t('analysis.isAvailable.not')}
                        />
                      </div>
                      <Header as="h2">
                        <RIEInput
                          value={this.state.analysisData.title || ''}
                          change={this.call_updateAnalysis}
                          propName="title"
                          className={
                            this.state.analysisData.title ? ' ' : ' riek'
                          }
                          classLoading="loading"
                          classInvalid="invalid"
                        />{' '}
                        -
                        {this.state.analysisData.user &&
                          this.state.analysisData.user.name}
                        <div>
                          {moment(this.state.analysisData.date).format('ddd ')}
                          <RIEInput
                            value={moment(this.state.analysisData.date).format(
                              'DD/MM/YYYY'
                            )}
                            change={this.call_updateAnalysis}
                            propName="date"
                            className={
                              this.state.analysisData.date ? ' ' : ' riek'
                            }
                            classLoading="loading"
                            classInvalid="invalid"
                          />
                        </div>
                      </Header>
                      <Grid stackable>
                        <Grid.Column className="card-white pad-bottom-30 pad-top-15 card-description fluid ">
                          <Card>
                            <Card.Content>
                              <Card.Header>
                                {polyglot.t('analysis.framecount')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content>
                              <Table basic="very" celled>
                                <Table.Header>
                                  <Table.Row textAlign="center">
                                    <Table.HeaderCell>
                                      {polyglot.t(
                                        'analysis.time.initialContact'
                                      )}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                      {polyglot.t('analysis.time.inPose')}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                      {polyglot.t('analysis.time.endFall')}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                      {polyglot.t('analysis.time.lastContact')}
                                    </Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  <Table.Row textAlign="center">
                                    <Table.Cell>
                                      <RIEInput
                                        value={this.formatms(
                                          this.state.analysisData
                                            .initialContactTime
                                        )}
                                        change={this.call_updateAnalysis}
                                        propName="initialContactTime"
                                        editProps={{
                                          placeholder: 'mm:ss.000',
                                          width: '25px'
                                        }}
                                        defaultValue={'mm:ss.000'}
                                        defaultProps={{ value: 'mm:ss.000' }}
                                        className={'time'}
                                        classLoading="loading"
                                        classInvalid="invalid"
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <RIEInput
                                        value={this.formatms(
                                          this.state.analysisData.inPoseTime
                                        )}
                                        change={this.call_updateAnalysis}
                                        propName="inPoseTime"
                                        editProps={{
                                          placeholder: 'mm:ss.000',
                                          width: '25px'
                                        }}
                                        defaultValue={'mm:ss.000'}
                                        defaultProps={{ value: 'mm:ss.000' }}
                                        className={'time'}
                                        classLoading="loading"
                                        classInvalid="invalid"
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <RIEInput
                                        value={this.formatms(
                                          this.state.analysisData.fallEndTime ||
                                            ''
                                        )}
                                        change={this.call_updateAnalysis}
                                        propName="fallEndTime"
                                        editProps={{
                                          placeholder: 'mm:ss.000',
                                          width: '25px'
                                        }}
                                        defaultValue={'mm:ss.000'}
                                        className={'time'}
                                        classLoading="loading"
                                        classInvalid="invalid"
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <RIEInput
                                        value={this.formatms(
                                          this.state.analysisData
                                            .lastContactTime || ''
                                        )}
                                        change={this.call_updateAnalysis}
                                        propName="lastContactTime"
                                        editProps={{
                                          placeholder: 'mm:ss.000',
                                          width: '25px'
                                        }}
                                        defaultValue={'mm:ss.000'}
                                        defaultProps={{ value: 'mm:ss.000' }}
                                        className={'time'}
                                        classLoading="loading"
                                        classInvalid="invalid"
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Body>
                              </Table>
                              <Button
                                className={'app-button	'}
                                onClick={this.countFrames}
                              >
                                {polyglot.t('analysis.count.frames')}
                              </Button>
                            </Card.Content>
                            <Card.Content>
                              <div
                                style={{
                                  float: 'right',
                                  marginTop: '-10px'
                                }}
                              >
                                <RIESelect
                                  value={{
                                    id: this.state.analysisData.fps,
                                    text: this.state.analysisData.fps + 'fps'
                                  }}
                                  className={
                                    this.state.highlight ? 'editable' : ''
                                  }
                                  options={fpsOptions}
                                  change={this.call_updateAnalysis}
                                  classLoading="loading"
                                  propName="fps"
                                  isDisabled={this.state.isDisabled}
                                />
                              </div>

                              <div
                                className="top-15 "
                                style={{
                                  fontSize: '1.5rem',
                                  fontWeight: 'bold'
                                }}
                              >
                                <RIEInput
                                  value={
                                    this.state.analysisData.framesToPose || ''
                                  }
                                  change={this.call_updateAnalysis}
                                  propName="framesToPose"
                                  className={'number'}
                                  classLoading="loading"
                                  classInvalid="invalid"
                                  style={{ width: '10px' }}
                                />{' '}
                                :
                                <RIEInput
                                  value={
                                    this.state.analysisData.framesFromPose || ''
                                  }
                                  change={this.call_updateAnalysis}
                                  propName="framesFromPose"
                                  className={'number'}
                                  classLoading="loading"
                                  classInvalid="invalid"
                                  style={{ width: '10px' }}
                                />
                              </div>
                            </Card.Content>
                          </Card>
                          <Card>
                            <Card.Content>
                              <Card.Header>
                                {' '}
                                {polyglot.t('analysis.execution.cadence')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content>
                              <Table basic="very" celled>
                                <Table.Header>
                                  <Table.Row textAlign="center">
                                    {[0, 1, 2, 3, 4].map(x => (
                                      <Table.HeaderCell key={x}>
                                        {polyglot.t('analysis.cadence.mark')}
                                        {' ' + x}
                                      </Table.HeaderCell>
                                    ))}
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  <Table.Row>
                                    {[0, 1, 2, 3, 4].map(x => (
                                      <Table.Cell key={x}>
                                        <RIEInput
                                          value={this.formatms(
                                            this.state.analysisData[
                                              'cadenceMark' + x
                                            ]
                                          )}
                                          change={this.call_updateAnalysis}
                                          propName={'cadenceMark' + x}
                                          editProps={{
                                            placeholder: 'mm:ss.000',
                                            width: '25px'
                                          }}
                                          defaultValue={'mm:ss.000'}
                                          defaultProps={{ value: 'mm:ss.000' }}
                                          className={'time'}
                                          classLoading="loading"
                                          classInvalid="invalid"
                                        />
                                      </Table.Cell>
                                    ))}
                                  </Table.Row>
                                </Table.Body>
                              </Table>
                              <Button
                                className={'app-button	'}
                                onClick={this.computeCadence}
                              >
                                {polyglot.t('analysis.cadence.compute')}
                              </Button>
                            </Card.Content>
                            <Card.Content>
                              {' '}
                              <div
                                className="top-15 "
                                style={{
                                  fontSize: '1.5rem',
                                  fontWeight: 'bold'
                                }}
                              >
                                {this.state.analysisData.cadence}
                              </div>{' '}
                            </Card.Content>
                          </Card>

                          <Card>
                            <Card.Content>
                              <Card.Header>
                                {' '}
                                {polyglot.t('analysis.execution.pose')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content style={{ textAlign: 'left' }}>
                              <Form style={{ marginTop: '10px' }}>
                                <Form.Group inline style={{ marginTop: '5px' }}>
                                  <label>
                                    {' '}
                                    {polyglot.t(
                                      'analysis.execution.poseExecutionLanding'
                                    )}{' '}
                                  </label>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.poseExecutionLanding.1'
                                      )}
                                      value="1"
                                      propname="poseExecutionLanding"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .poseExecutionLanding
                                        ) === 1
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.poseExecutionLanding.2'
                                      )}
                                      value="2"
                                      propname="poseExecutionLanding"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .poseExecutionLanding
                                        ) === 2
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.poseExecutionLanding.3'
                                      )}
                                      value="3"
                                      propname="poseExecutionLanding"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .poseExecutionLanding
                                        ) === 3
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                              <Form style={{ marginTop: '10px' }}>
                                <Form.Group inline style={{ marginTop: '5px' }}>
                                  <label>
                                    {' '}
                                    {polyglot.t(
                                      'analysis.execution.poseExecutionKnee'
                                    )}{' '}
                                  </label>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.poseExecutionKnee.1'
                                      )}
                                      value="1"
                                      propname="poseExecutionKnee"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .poseExecutionKnee
                                        ) === 1
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.poseExecutionKnee.3'
                                      )}
                                      value="3"
                                      propname="poseExecutionKnee"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .poseExecutionKnee
                                        ) === 3
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                              <Form style={{ marginTop: '10px' }}>
                                <Form.Group inline style={{ marginTop: '5px' }}>
                                  <label>
                                    {' '}
                                    {polyglot.t(
                                      'analysis.execution.poseExecutionVertical'
                                    )}{' '}
                                  </label>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.poseExecutionVertical.1'
                                      )}
                                      value="1"
                                      propname="poseExecutionVertical"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .poseExecutionVertical
                                        ) === 1
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.poseExecutionVertical.2'
                                      )}
                                      value="2"
                                      propname="poseExecutionVertical"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .poseExecutionVertical
                                        ) === 2
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.poseExecutionVertical.3'
                                      )}
                                      value="3"
                                      propname="poseExecutionVertical"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .poseExecutionVertical
                                        ) === 3
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                            </Card.Content>
                          </Card>
                          <Card>
                            <Card.Content>
                              <Card.Header>
                                {' '}
                                {polyglot.t('analysis.execution.fall')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content style={{ textAlign: 'left' }}>
                              <Form style={{ marginTop: '10px' }}>
                                <Form.Group inline style={{ marginTop: '5px' }}>
                                  <label>
                                    {polyglot.t(
                                      'analysis.execution.fallExecutionAlignment'
                                    )}
                                  </label>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.fallExecutionAlignment.1'
                                      )}
                                      value="1"
                                      propname="fallExecutionAlignment"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .fallExecutionAlignment
                                        ) === 1
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.fallExecutionAlignment.2'
                                      )}
                                      value="2"
                                      propname="fallExecutionAlignment"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .fallExecutionAlignment
                                        ) === 2
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>

                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.fallExecutionAlignment.3'
                                      )}
                                      value="3"
                                      propname="fallExecutionAlignment"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .fallExecutionAlignment
                                        ) === 3
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                              <Form style={{ marginTop: '10px' }}>
                                <Form.Group inline style={{ marginTop: '5px' }}>
                                  <label>
                                    {' '}
                                    {polyglot.t(
                                      'analysis.execution.fallExecutionTime'
                                    )}{' '}
                                  </label>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.fallExecutionTime.1'
                                      )}
                                      value="1"
                                      propname="fallExecutionTime"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .fallExecutionTime
                                        ) === 1
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.fallExecutionTime.2'
                                      )}
                                      value="2"
                                      propname="fallExecutionTime"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .fallExecutionTime
                                        ) === 2
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.fallExecutionTime.3'
                                      )}
                                      value="3"
                                      propname="fallExecutionTime"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .fallExecutionTime
                                        ) === 3
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                </Form.Group>
                                <Form.Group inline style={{ marginTop: '5px' }}>
                                  <Form.Field>
                                    <label>
                                      {polyglot.t(
                                        'analysis.execution.fallExecutionFrames'
                                      )}
                                    </label>

                                    <RIEInput
                                      value={
                                        this.state.analysisData.framesFalling ||
                                        ''
                                      }
                                      change={this.call_updateAnalysis}
                                      propName="framesFalling"
                                      className={'number'}
                                      classLoading="loading"
                                      classInvalid="invalid"
                                      style={{ width: '10px' }}
                                    />
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                            </Card.Content>
                          </Card>
                          <Card>
                            <Card.Content>
                              <Card.Header>
                                {' '}
                                {polyglot.t('analysis.execution.pull')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content style={{ textAlign: 'left' }}>
                              <Form style={{ marginTop: '10px' }}>
                                <Form.Group inline style={{ marginTop: '5px' }}>
                                  <label>
                                    {' '}
                                    {polyglot.t(
                                      'analysis.execution.pullExecutionTiming'
                                    )}{' '}
                                  </label>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.pullExecutionTiming.1'
                                      )}
                                      value="1"
                                      propname="pullExecutionTiming"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .pullExecutionTiming
                                        ) === 1
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.pullExecutionTiming.2'
                                      )}
                                      value="2"
                                      propname="pullExecutionTiming"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .pullExecutionTiming
                                        ) === 2
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.pullExecutionTiming.3'
                                      )}
                                      value="3"
                                      propname="pullExecutionTiming"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .pullExecutionTiming
                                        ) === 3
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                              <Form style={{ marginTop: '10px' }}>
                                <Form.Group inline style={{ marginTop: '5px' }}>
                                  <label>
                                    {' '}
                                    {polyglot.t(
                                      'analysis.execution.pullExecutionLegBent'
                                    )}{' '}
                                  </label>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.pullExecutionLegBent.1'
                                      )}
                                      value="1"
                                      propname="pullExecutionLegBent"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .pullExecutionLegBent
                                        ) === 1
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.pullExecutionLegBent.3'
                                      )}
                                      value="3"
                                      propname="pullExecutionLegBent"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .pullExecutionLegBent
                                        ) === 3
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                              <Form style={{ marginTop: '10px' }}>
                                <Form.Group inline style={{ marginTop: '5px' }}>
                                  <label>
                                    {' '}
                                    {polyglot.t(
                                      'analysis.execution.pullExecutionDirection'
                                    )}{' '}
                                  </label>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.pullExecutionDirection.1'
                                      )}
                                      value="1"
                                      propname="pullExecutionDirection"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .pullExecutionDirection
                                        ) === 1
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <Radio
                                      label={polyglot.t(
                                        'analysis.execution.pullExecutionDirection.3'
                                      )}
                                      value="3"
                                      propname="pullExecutionDirection"
                                      checked={
                                        Number(
                                          this.state.analysisData
                                            .pullExecutionDirection
                                        ) === 3
                                      }
                                      onChange={this.handleRadio}
                                    />
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                            </Card.Content>
                          </Card>
                          <Card>
                            <Card.Content>
                              <Card.Header>
                                {polyglot.t('analysis.fault.focus')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content style={{ textAlign: 'left' }}>
                              <Form style={{ marginTop: '10px' }}>
                                <Form.Group inline style={{ marginTop: '5px' }}>
                                  <label>
                                    {' '}
                                    {polyglot.t('analysis.focus')}{' '}
                                  </label>

                                  {focus.map(x => (
                                    <Form.Field key={x}>
                                      <Radio
                                        label={
                                          x.slice(0, 1).toUpperCase() +
                                          x.slice(1, x.length)
                                        }
                                        value={x}
                                        propname="focus"
                                        checked={
                                          this.state.analysisData.focus === x
                                        }
                                        onChange={this.handleRadio}
                                      />
                                    </Form.Field>
                                  ))}
                                </Form.Group>
                                <Form.Group inline style={{ marginTop: '5px' }}>
                                  <Form.Field>
                                    <label>
                                      {' '}
                                      {polyglot.t('analysis.fault')}
                                    </label>

                                    <RIESelect
                                      value={this.state.analysisFault}
                                      className={
                                        this.state.highlight ? 'editable' : ''
                                      }
                                      options={this.state.faults}
                                      change={this.call_updateAnalysis}
                                      classLoading="loading"
                                      propName="fault"
                                    />
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                            </Card.Content>
                          </Card>
                          <Card>
                            <Card.Content>
                              <Card.Header>
                                {polyglot.t('analysis.analysis')}
                              </Card.Header>
                            </Card.Content>
                            <Card.Content style={{ textAlign: 'left' }}>
                              <RIETextArea
                                value={this.state.analysisData.analysis || ''}
                                change={this.call_updateAnalysis}
                                propName="analysis"
                                className={
                                  this.state.highlight
                                    ? 'editable riek'
                                    : 'riek'
                                }
                                classLoading="loading"
                                classInvalid="invalid"
                              />
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>

                    <Grid.Column className="card-white pad-bottom-30 pad-top-15">
                      {true ? (
                        <Fragment>
                          <strong>
                            {polyglot.t('analysisCreate.urlCode')}
                          </strong>{' '}
                          :
                          <RIEInput
                            value={this.state.analysisData.urlCode || ''}
                            change={this.call_updateAnalysis}
                            propName="urlCode"
                            className={
                              this.state.analysisData.urlCode ? ' ' : ' riek'
                            }
                            classLoading="loading"
                            classInvalid="invalid"
                          />
                          {this.state.analysisData.urlCode && (
                            <Embed
                              id={this.state.analysisData.urlCode}
                              placeholder={
                                'http://img.youtube.com/vi/' +
                                this.state.analysisData.urlCode +
                                '/0.jpg'
                              }
                              brandedUI={true}
                              source="youtube"
                            />
                          )}
                        </Fragment>
                      ) : null}
                      <Card>
                        <Card.Content>
                          <Card.Header>
                            {' '}
                            {polyglot.t('analysis.analysis')}
                          </Card.Header>
                        </Card.Content>
                        <Card.Content style={{ textAlign: 'left' }}>
                          <ComputedAnalysis
                            polyglot={this.props.polyglot}
                            {...this.state.analysisData}
                          />
                        </Card.Content>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </div>
          )}
        </div>
        <ToastContainer autoClose={3000} />
      </Fragment>
    )
  }
}

Analysis = withPolyglot()(Analysis)
export default Analysis
