import React, { Component, Fragment } from 'react'
import { countNotificationsForMe } from '../services/Api'
import { Icon, Label } from 'semantic-ui-react'

class NotificationBell extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      notificationCount: false
    }
  }
  componentDidMount() {
    if (localStorage.getItem('RWJToken')) {
      this.call_countNotificationsForMe()
      this.interval = setInterval(() => {
        this.call_countNotificationsForMe()
      }, 2 * 60 * 1000)
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  shouldComponentUpdate(nextprops, nextState) {
    if (this.state.notificationCount === nextState.notificationCount) {
      return false
    }
    return true
  }
  async call_countNotificationsForMe() {
    const response = await countNotificationsForMe()
    if (response.ok) {
      const newState = Object.assign({}, this.state, {
        notificationCount: response.data.unreadCount,
        isLoading: false,
        hasError: false,
        redirectToReferrer: true
      })
      this.setState(newState)
    } else {
      const newState = Object.assign({}, this.state, {
        isLoading: false,
        hasError: true,
        error: response
      })
      // store the new state object in the component's state
      this.setState(newState)
    }
  }
  render() {
    return (
      <Fragment>
        <Icon
          name={
            window.location.href.indexOf('/notifications') !== -1
              ? 'bell '
              : 'bell outline'
          }
          size="large"
          className="menu-btn"
        />
        {this.state.notificationCount && this.state.notificationCount !== 0 ? (
          <Label color="red" floating className="menu-btn">
            {this.state.notificationCount}
          </Label>
        ) : null}
      </Fragment>
    )
  }
}
export default NotificationBell
