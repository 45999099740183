import React, { PureComponent } from 'react'
import { withPolyglot } from 'polyglot-react'
class Footer extends PureComponent {
  render() {
    const { polyglot } = this.props
    return (
      <div className="footer top-40">
        <a
          href="//posemethod.com/running/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>{polyglot.t('app.proud.partner')}</div>
          <img src="/pose_method_logo.png" alt="Pose Method Logo" />
        </a>
      </div>
    )
  }
}

Footer = withPolyglot()(Footer)
export default Footer
