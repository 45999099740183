import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Container, Grid, Segment } from "semantic-ui-react"
import { getDrillsByCategory } from "../services/Api"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"

import { withPolyglot } from "polyglot-react"
import AppError from "../components/AppError"

class DrillsByCategory extends Component {
	constructor(props) {
		super(props)
		this.state = {
			categories: [],
			hasError: 0,
			error: false,
			isLoading: true
		}
	}

	async getData() {
		const response = await getDrillsByCategory()
		if (response.ok) {
			const data = response.data

			// store the new state object in the component's state
			this.setState({
				categories: data,
				isLoading: false,
				hasError: false
			})
			//	this.setState({ redirectToReferrer: true })
		} else {
			// Error
			this.setState({
				isLoading: false,
				hasError: true,
				error: response
			})
		}
	}
	componentDidMount() {
		this.getData()
	}

	render() {
		const { polyglot } = this.props
		return this.state.isLoading ? (
			<div className="ui active centered inline loader" />
		) : this.state.hasError ? (
			<AppError message={this.state.error.problem ? this.state.error.problem : ""} />
		) : (
			<div className="page ">
				<Container text className="page ">
					<style>{`
					
					.category-link{
						margin: 0;
						width: 100%;

						position: absolute;
						top: 50%;  /* position the top  edge of the element at the middle of the parent */
						left: 50%; /* position the left edge of the element at the middle of the parent */
				
						transform: translate(-50%, -50%); 
					}

					
						`}</style>
					{/**

					<Header as="h2" className="color-white center-text uppercase  top-10">
						{polyglot.t("drillByCategory.title")}
					</Header>
					<Divider />
						 */}

					<Grid style={{ minHeight: "90vh", marginTop: "0px" }} relaxed>
						<Grid.Row stretched style={{ paddingTop: "0px" }}>
							<Grid.Column>
								<Segment
									basic
									vertical
									className="uppercase bold spaced category"
									style={{ borderBottom: "1px solid #767676" }}>
									<Link
										to={"/drills/category/warmup"}
										className="color-white category-link"
										style={{ display: "block" }}>
										{polyglot.t("drillByCategory.warmup")}
									</Link>
								</Segment>
								<Segment
									basic
									vertical
									className="uppercase bold spaced category"
									style={{ borderBottom: "1px solid #767676" }}>
									<Link to={"/drills/category/pose"} className="color-white category-link">
										{polyglot.t("drillByCategory.pose")}
									</Link>
								</Segment>
								<Segment
									basic
									vertical
									className="uppercase bold spaced category"
									style={{ borderBottom: "1px solid #767676" }}>
									<Link to={"/drills/category/fall"} className="color-white category-link">
										{polyglot.t("drillByCategory.fall")}
									</Link>
								</Segment>
								<Segment
									basic
									vertical
									className="uppercase bold spaced category"
									style={{ borderBottom: "1px solid #767676" }}>
									<Link to={"/drills/category/pull"} className="color-white category-link">
										{polyglot.t("drillByCategory.pull")}
									</Link>
								</Segment>
								{/**
								<Segment
									basic
									vertical
									className="uppercase bold spaced"
									style={{ borderBottom: "1px solid #767676", paddingTop: "2.3rem" }}>
									<Link to={"/drills/category/combined"} className="color-white">
										{polyglot.t("drillByCategory.combined")}
									</Link>
								</Segment>
								 */}
								<Segment
									basic
									vertical
									className="uppercase bold spaced category"
									style={{ borderBottom: "1px solid #767676" }}>
									<Link to={"/drills/category/strength"} className="color-white category-link">
										{polyglot.t("drillByCategory.strength")}
									</Link>
								</Segment>
								<Segment
									basic
									vertical
									className="uppercase bold spaced category"
									style={{ borderBottom: "1px solid #767676" }}>
									<Link to={"/drills/category/correction"} className="color-white category-link">
										{polyglot.t("drillByCategory.correction")}
									</Link>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</div>
		)
	}
}

DrillsByCategory = withPolyglot()(DrillsByCategory)
export default DrillsByCategory
