import React, { Component, Fragment } from 'react'
import { Grid, Dimmer, Icon, Card, Label, Transition } from 'semantic-ui-react'
import { getMe, getNextClass } from '../services/Api'
import { withPolyglot } from 'polyglot-react'

import ProfilePic from '../components/ProfilePic'
import Footer from '../components/Footer'
import AppError from '../components/AppError'
import 'react-datepicker/dist/react-datepicker.css'
//import {Line} from 'react-chartjs-2';

import { Config } from '../config'
import _ from 'lodash'
import ReactGA from 'react-ga'
ReactGA.initialize(Config.gaId)

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      advice: '',
      hasError: false,
      error: false,
      isLoading: true,

      showHeader: true,
      userPicForceReload: '',
      myNextClasses: [],
      myNextClass: {},
      myNextClassPaces: {},
      frameCount: '',
      analysisModalIsOpen: false,
      analysisModalUrl: '',
      analysisModalCode: '',
      visible: false
    }

    this.refreshAfterNewPic = this.refreshAfterNewPic.bind(this)
  }
  async componentDidMount() {
    this.setState({
      visible: true
    })

    const user = await this.getCurrentUser()

    const classes = await this.call_getNextClass()

    if (user) {
      this.setState(
        Object.assign(user, classes, {
          hasError: false,
          isLoading: false,
          visible: true
        })
      )
    }

    ReactGA.pageview(window.location.pathname + window.location.search)
    /* this.props.notificationRefresh()*/
  }

  async call_getNextClass() {
    var response = await getNextClass()
    if (response.ok && response.data) {
      var myNextClasses = response.data.rows
      myNextClasses = _.filter(myNextClasses, function(o) {
        if (!o.trainingLog || o.trainingLog.done === false) {
          return true
        }
        return false
      })
      // store the new state object in the component's state

      return {
        myNextClasses: myNextClasses,
        myNextClass: myNextClasses[0]
      }
    } else {
      return false
    }
  }

  async getCurrentUser() {
    const response = await getMe()

    if (response.ok) {
      const data = response.data
      const user = {
        name: data.name,
        picture: data.picture,

        coach: data.coach && data.coach.name ? data.coach.name : null,
        role: data.role,
        email: data.email,
        createdAt: data.createdAt,
        lastAdvice: data.lastAdvice ? data.lastAdvice.message : ''
      }
      if (!localStorage.RWJUserName) {
        localStorage.RWJUserName = data.name
      }

      return {
        user
      }
    } else {
      this.setState({
        isLoading: false,
        hasError: true,
        error: response
      })
      return false
    }
  }

  refreshAfterNewPic(newPicUrl) {
    const updatedUser = this.state.user
    updatedUser.picture = newPicUrl
    const newState = Object.assign({}, this.state, {
      user: updatedUser,
      userPicForceReload: '?fr=' + Date.now().toString()
    })
    this.setState(newState)
  }

  render() {
    const { polyglot } = this.props

    const { visible } = this.state

    return (
      <Transition visible={visible} animation="scale" duration={100}>
        <div className="Home">
          <Dimmer active={this.state.isLoading && false}></Dimmer>
          {this.state.hasError && (
            <AppError
              message={this.state.error.problem ? this.state.error.problem : ''}
            />
          )}
          {!this.state.hasError && (
            <div>
              <style>{`
						.profilePicture img.ui.image{
							display: inline-block;
            }
            .slowing-factor-fiability-1{
              color:green
            }
            .slowing-factor-fiability-2{
              color:#ef7f1d;
            }
            .slowing-factor-fiability-3{
              color:red
						}
						.next-class .item .content .header{
							border-bottom : 1px solid #636363;
						}
				
						`}</style>
              <Grid
                textAlign="center"
                style={{ height: '100%' }}
                verticalAlign="middle"
                className="top-10"
              >
                <div className="color-white">
                  {
                    <Fragment>
                      {this.state.showHeader && (
                        <Fragment>
                          <div className="card home-header">
                            <div className="profilePic-placeholder">
                              <ProfilePic
                                currentPic={
                                  this.state.user.picture +
                                  this.state.userPicForceReload
                                }
                                mustRefresh={this.refreshAfterNewPic}
                              />
                            </div>
                            <Card
                              className="color-white center-text "
                              style={{
                                width: '100%',
                                maxWidth: '300px',
                                margin: '0 auto',
                                marginTop: '-15px'
                              }}
                            >
                              <Card.Content>
                                <h2 className="profile-heading capitalize thin">
                                  {this.state.user.name || (
                                    <div style={{ color: 'transparent' }}>
                                      loading{' '}
                                    </div>
                                  )}
                                </h2>
                                {/*<Card.Meta> 	{this.state.user.coach &&  polyglot.t('home.coach') } <strong>{this.state.user.coach} </strong></Card.Meta>*/}
                              </Card.Content>
                            </Card>
                          </div>
                        </Fragment>
                      )}
                      <Grid className=" actions-placeholder">
                        <Grid.Column>
                          <div className="call-to-action">
                            {/*</div><span className= {this.state.myNextClass?"action":"action disabled"} onClick={()=>this.toggleShowNextClassFull()}>*/}
                            <span
                              className={
                                this.state.myNextClass
                                  ? 'action top-20'
                                  : 'action  top-20 disabled'
                              }
                              onClick={() =>
                                this.props.history.push(
                                  '/todayTraining/' + this.state.myNextClass.id
                                )
                              }
                            >
                              <Icon
                                disabled={this.state.myNextClass ? false : true}
                                name="heartbeat"
                              />
                              {this.state.myNextClass && (
                                <Label color="grey" circular floating>
                                  {' '}
                                  <Icon name="exclamation" />{' '}
                                </Label>
                              )}
                              <em>
                                {' '}
                                {polyglot.t('home.action.training.next')}{' '}
                              </em>
                            </span>
                            <span
                              className="action top-20 highlight"
                              onClick={() =>
                                this.props.history.push('/runnerProfile')
                              }
                            >
                              <Icon name="bullseye" />
                              <em>
                                {' '}
                                {polyglot.t('home.action.runner.profile')}{' '}
                              </em>
                            </span>
                            <a
                              href={Config.bookingUrl}
                              target="blank"
                              className="action top-20"
                            >
                              <Icon name="calendar plus" />
                              <em> {polyglot.t('home.action.book')}</em>
                            </a>{' '}
                            <span
                              className="action top-20"
                              onClick={() =>
                                this.props.history.push('/userPerfs')
                              }
                            >
                              <Icon name="stopwatch" />
                              <em> {polyglot.t('home.action.times')} </em>
                            </span>
                            <span
                              className="action top-20"
                              onClick={() =>
                                this.props.history.push('/paceConverter')
                              }
                            >
                              <Icon name="calculator" />
                              <em> {polyglot.t('home.action.paceSpeed')} </em>
                            </span>
                            <span
                              className="action top-20"
                              onClick={() =>
                                this.props.history.push('/userAnalyses')
                              }
                            >
                              <Icon name="play circle" />
                              <em> {polyglot.t('home.action.analysis')} </em>
                            </span>
                            <span
                              className="action top-20"
                              onClick={() =>
                                this.props.history.push('/drills/category')
                              }
                            >
                              <Icon name="list ul" />
                              <em> {polyglot.t('home.action.drills')}</em>
                            </span>
                            {/*
                            <span className="action"   onClick={()=>this.toggleShowStats()} ><Icon name='line graph'  /><em>	{ polyglot.t('home.action.stats') } </em></span>
                          */}
                            {/** <span
                          className="action"
                          style={{ background: 'none', boxShadow: 'none' }}
                        ></span>
												*/}
                            <span
                              className="action top-20 "
                              onClick={() =>
                                this.props.history.push('/userProtocol')
                              }
                            >
                              <Icon name="wizard" />
                              <em>
                                {' '}
                                {polyglot.t('home.action.warmup.technique')}
                              </em>
                            </span>
                            <span
                              className="action"
                              style={{ background: 'none', boxShadow: 'none' }}
                            ></span>
                          </div>
                        </Grid.Column>
                      </Grid>
                    </Fragment>
                  }
                </div>
              </Grid>
            </div>
          )}
          <Footer />
        </div>
      </Transition>
    )
  }
}
Home = withPolyglot()(Home)
export default Home
