const fr_fr = {
  undefined: '',
  locale: 'fr',
  'generic.message.update': 'Enregistrement effectué',
  'generic.message.created': 'Création reussie',
  'generic.message.error': "Echec de l'opération",
  'generic.message.done': 'Modification effectuée',
  'generic.message.empty': "Rien à afficher pour l'instant",
  'generic.message.delete': "Ceci supprimera l'élément !",
  'generic.message.notfound':
    "Désolé, impossible de trouver l'information demandée ...",
  'app.proud.partner': 'Partenaire de ',
  'generic.select.empty': '- Sélectionner -',
  'generic.select.label': 'Selectionner',

  'error.oops': 'Oups!',
  'error.message': 'Petit souci ...',
  'error.retry': 'Réessayer',

  'sidebar.home': 'Accueil',
  'sidebar.trainings': 'Entrainements',
  'sidebar.profile': 'Profil',
  'sidebar.notification.center': 'Centre de Notification',
  'sidebar.courses': 'Séances',
  'sidebar.athletes': 'Athlètes',
  'sidebar.analyses': 'Analyse Technique',
  'sidebar.faults': 'Erreurs',
  'sidebar.clues': 'Conseils',
  'sidebar.drills': 'Educatifs',
  'sidebar.protocols': 'Protocoles',
  'sidebar.update.check': 'Mise à jour',
  'sidebar.sign_out': 'Déconnexion',

  'athlete.courses': 'Entrainements',

  'app.ios.prompt.title': `Ajouter à l'écran d'accueil`,
  'app.ios.prompt.body': `Ce site est une Application Légère(PWA).
	 Ajoutez la à votre écran d'accueil pour un accès rapide, en plein écran.`,
  'app.ios.prompt.shareButtonLabel': `1) Activer le bouton "Partager"`,
  'app.ios.prompt.addHomeButtonLabel': `2) Sur l'écran d'accueil" `,
  'app.ios.prompt.closePrompt': 'Annuler',

  'login.login': 'Connexion',
  'login.email': 'Adresse email',
  'login.password': 'Mot de passe',
  'login.signup': "S'inscrire",
  'login.password.forgot': 'Mot de passe oublié',

  'login.message.error': "Echec d'authentification",

  'forgot.email': 'Adresse email',
  'forgot.reset': 'Reinitialiser',
  'forgot.login': "S'identifier",
  'forgot.password.new': 'Nouveau mot de passe',
  'forgot.message.error': 'Cette adresse est inconnue!',
  'forgot.message.mail.sent': `Un email vous a été envoyé à l'adresse %{email} afin de réinitialiser votre mot de passe.
Vérifiez si il n'est pas arrivé dans votre dossier "Indésirables".`,
  'forgot.message.password.changed':
    'Votre mot de passe a bien été changé, vous pouvez maintenant vous reconnecter',
  'forgot.message.error.invalid.link': 'Lien de réinitialisation non valide !',
  'forgot.password.change': 'Changer',
  'forgot.message.fields.password.short':
    'Votre mot de passe devrait contenir au moins 6 caractères',

  'signup.name': 'Prénom Nom',
  'signup.email': 'Adresse email',
  'signup.password': 'Mot de passe',
  'signup.password.confirm': 'Mot de passe (encore)',
  'signup.register': "S'inscrire",
  'signup.login.go': 'Déjà inscrit ? Connectez vous ici',

  'signup.message.fields.mandatory': 'Veuillez renseigner tous les champs',
  'signup.message.fields.email.wrong': 'Email non valide',
  'signup.message.fields.email.exists': 'Email déja enregistré',
  'signup.message.fields.password.short':
    'Votre mot de passe devrait contenir au moins 6 caractères',

  'profile.name': 'Prénom Nom',
  'profile.email': 'Adresse email',
  'profile.gender': 'Sexe',
  'profile.country': 'Pays',
  'profile.language': 'Langue',
  M: 'Homme',
  F: 'Femme',
  O: 'Autre',
  'profile.message.update.done': 'Profil mis à jour',

  'pending.label': 'Bienvenue %{name} !',
  'pending.content': `Ton inscription est en cours de validation par ton coach.
Encore un peu de patience !`,

  'home.coach': 'Coach :',
  'home.slowingFactor': 'Coeff ralentissement :',
  'home.improvement.area': "Axe d'amélioration :",
  'home.improvement.area.speed': 'Vitesse',
  'home.improvement.area.endurance': 'Endurance',

  'usertraining.course.warmup': 'Echauffement',
  'usertraining.course.technique': 'Technique',
  'usertraining.course.warmup.technique': 'Echauffement & Technique',
  'usertraining.course.training': 'Entrainement',
  'usertraining.course.focus': 'Focus / Commentaires',
  'usertraining.course.cooldown': 'Retour au calme',
  'usertraining.course.speed': 'Vitesse',
  'usertraining.course.pace': 'Allure',

  'home.action.times': 'Mes chronos',
  'home.action.times.add': 'Ajouter',
  'home.action.training.next': 'prochaine séance',
  'home.action.runner.profile': 'Profil coureur',

  'home.action.course': 'Séance',
  'home.action.book': 'Réserver',
  'home.action.analysis': 'Analyse',
  'home.action.drills': 'Educatifs',
  'home.action.stats': 'Stats',
  'home.action.paceSpeed': 'Convertisseur',
  'home.action.warmup.technique': 'Echauffement & Technique',

  'home.class.next': 'Prochaine séance',
  'home.class.next.none': "Pas d'entrainement à afficher pour l'instant",

  'home.perf.time': 'temps',
  'home.perf.date': 'date',
  'home.perf.distance': 'distance',

  'home.perf.new': 'Nouveau chrono',
  'home.perf.isReal': 'Réalisé',
  'home.perf.isReal.not': 'Allure de travail',
  'home.perf.new.hour': 'heures',
  'home.perf.new.minute': 'minutes',
  'home.perf.new.seconds': 'secondes',
  'home.perf.all': 'Derniers chronos',
  'home.perf.log': 'Performances précédentes',
  'home.perf.none': "Aucun chrono à afficher pour l'instant !",
  'home.perf.error.submission': 'Veuillez renseigner les champs obligatoires',

  'home.stats.all': 'Statistiques',
  'home.stats.6months': '6 derniers mois',

  'home.technique.analysis': 'Analyse technique',
  'home.technique.analysis.none': `Aucune analyse technique ?
   Demande la au coach !`,

  'paceSpeed.minutes': 'minutes',
  'paceSpeed.seconds': 'secondes',

  'faults.label': "Axe d'amélioration :",
  'faults.scratching_landing': "Frottements à l'atterrissage",
  'faults.overpulling': 'Tirage exagéré',
  'faults.excessive_arm_movement': 'Mouvement excessif des bras',
  'faults.vertical_oscillation': 'Oscillation verticale',
  'faults.resistance_to_falling': 'Resistance à la chute',
  'faults.active_landing': 'Atterrissage actif',
  'faults.heel_striking': 'Attaque talon',
  'faults.bending_waist': 'Flexion à la hanche',
  'faults.overstriding': 'Amplitude de foulée exagérée',
  'faults.pulling_late': 'Tirage tardif',
  'faults.noisy_landing': 'Atterrissage bruyant',
  'AdviceEdit.title': 'Le conseil du coach',

  'runnerprofile.runner.profile': 'Profil coureur',
  'runnerprofile.fatigueFactor': 'Facteur de fatigue',
  'runnerprofile.noPerfs': `Données non disponibles,
 saissez vos temps sur 400m and 1600m`,
  'runnerprofile.improvement.area': "Axe d'amélioration",
  'runnerprofile.improvement.area.speed': 'Vitesse',
  'runnerprofile.improvement.area.endurance': 'Endurance',
  'runnerprofile.main.deviation': `Ecart technique identifié`,
  'runnerprofile.technique.focus': `Focus technique configuré`,

  'runnerprofile.perf.all': 'Derniers chronos',

  'courses.new': 'Nouvelle séance',
  'course.isAvailable': 'Publié',
  'course.isAvailable.not': 'Brouillon',
  'course.attendees': 'Participants',
  'course.group': 'Groupe',
  'course.group.or.new': ' Ou créer ',
  'course.warmup': 'Echauffement',
  'course.warmup.drills': 'Echauffement & Technique',
  'course.training': 'Entrainement',
  'course.drills': 'Educatifs',
  'course.technique': 'Technique',
  'course.strength': 'Renforcement',
  'course.mobility': 'Mobilité',

  'course.focus': 'Focus/Commentaires',
  'course.cooldown': 'Retour au calme',
  'course.athletes.my': 'Mes athlètes',
  'course.description': 'Description',
  'course.publicDescription': 'Description publique',
  'course.trainingLogs': 'Commentaires',
  'course.drill.name': 'Nom',
  'course.drill.category': 'Categorie',
  'course.drill.description': 'Description',
  'course.drill.duration': 'Durée',
  'course.drill.video.url': 'Video',
  'course.notification.default.title': 'Nouvelle séance mise en ligne',
  'course.notification.default.message':
    'Bonjour, la prochaine séance est disponible!',

  'help.slowingFactorAndProfile.header':
    'Coefficient de ralentissement et axe de travail',
  'help.slowingFactorAndProfile.content': `Le coefficient de ralentissement est calculé avec vos dernières performances réalisées sur 400 et 1600m.
  Si elles sont espacées de:
  - moins de 30 jours, le coefficient est considéré comme fiable(coche verte),
  - moins de 60 jours, moyennement fiable (signe moins orange),
  - au dela de 60 jours, peu fiable (point d'exclamation rouge).
  Un coefficient de 20 et en dessous indique un manque vitesse.
  Un coefficient au dessus de 20 indique un manque d'endurance.
`,
  'help.slowingFactorAndProfile.content.bak': `Le coefficient de ralentissement est calculé avec vos dernières performances réalisées sur 400 et 1600m.
  Si elles sont espacées de:
  - moins de 30 jours, le coefficient est considéré comme fiable(coche verte),
  - moins de 60 jours, moyennement fiable (signe moins orange),
  - au dela de 60 jours, peu fiable (point d'exclamation rouge).

  `,

  'help.framecount.header': "Décompte d'image",
  'help.framecount.content': `Le décompte d'image permet d'estimer le niveau technique d'une foulée.
  L'idéal pour une caméra capturant à 60 image par secondes est de 2:2
  `,

  'help.faults.overstriding.content': `Une amplitude trop grande, diminue l'efficacité.
  Un point de contact avec le sol trop en avant provoque un effet de frein.
  Une tentative de poussée, augmente le temps nécessaire à revenir en Pose `,

  'help.faults.scratching_landing.content': "Frottements à l'atterrissage",
  'help.faults.overpulling.content': `Le tirage exagéré est une erreur normale, induite par la volonté de bien faire en tirant le pied du sol.
Toutefois la hauteur du tirage doit dépendre de l'angle de chute. Un tirage excessif provoquera une fatigue précoce et inutile`,
  'help.faults.excessive_arm_movement.content': 'Mouvement excessif des bras',

  'help.faults.vertical_oscillation.content': `L'oscillation est une perte d'efficacité dans un mouvement qui se veut le plus linéaire possible.
  Elle peut etre dûe à une tentative de poussée soit au niveau du pied, soit au niveau du quadriceps.
  Une autre raison possible est une tension continue du pied avec le talon qui ne touche jamais le sol`,

  'help.faults.resistance_to_falling.content': `La Resistance à la chute est le principal frein à la progression en vitesse.
  Le travail technique sur la chute en avant est primordial, car la peur de la chute est naturelle chez l'adulte.`,

  'help.faults.active_landing.content': `L'atterrissage actif est une tentative d'éviter l'attaque talon en posant l'avant du pied de façon
exagérée, cela entraine en général une tension inutile du pied et un atterrissage souvent en avant du corps induisant une tension inutile
de la voute plantaire, et un temps de support inutilement long`,

  'help.faults.heel_striking.content': `L'attaque par le talon, provoque un frein à l'avancement,
  provoque une charge excessive au niveau des articulations du genou, de la hanche, et des disques intervertébraux.
  C'est la cause majoritaire des blessures chez le coureur.`,

  'help.faults.bending_waist.content': `La position idéale pour utiliser la gravité est POSE où sont alignés, l'avant pied, la hanche épaules.
   La flexion à la hanche brise cet alignement, diminue le potentiel de chute, et donc la vitesse`,

  'help.faults.pulling_late.content': `Un tirage tardif, indique que l'action de tirage intervient trop tard dans le timing de la foulée, indiquant soit:
  - une tentative de poussée
  - soit une trop grande attention sur l'atterrisage`,

  'help.faults.noisy_landing.content': 'Atterrissage bruyant',

  'notifications.none': "Pas de notification à afficher pour l'instant !",
  'notification.athletes.my': 'Mes athlètes',
  'notification.sendTo': 'Destinataires',
  notification: 'Notification',
  'notification.content': 'Contenu',
  'notification.title': 'Titre',
  'notification.message': 'Message',

  'drillByCategory.title': 'Educatifs',
  'drillByCategory.warmup': 'Echauffement',
  'drillByCategory.pose': 'Pose',
  'drillByCategory.fall': 'Fall',
  'drillByCategory.pull': 'Pull',
  'drillByCategory.combined': 'Combinés',
  'drillByCategory.strength': 'Renforcement',
  'drillByCategory.correction': 'Correction',

  'drillCreate.name': "Nom de l'éducatif",
  'drillCreate.category': 'Categorie',
  'drillCreate.description': 'Description',
  'drillCreate.image.url': "Url de l'image",
  'drillCreate.video.url': 'Url de la vidéo',
  'drillCreate.duration': 'Durée',
  'drillCreate.gear': 'Equipement',

  'drillVideo.setup': 'Position de départ',
  'drillVideo.execution': 'Exécution',
  'drillVideo.focus': "Point d'attention",
  'drillVideo.instructions': 'Instructions',

  'usertrainings.none': "Pas d'entrainement à afficher pour l'instant !",

  'usertrainings.nextWeeks': 'Prochaines semaines',

  'traininglog.comments.label': 'Commentaires',
  'traininglog.comments.placeholder': 'Chronos, sensations ...',
  'traininglog.done.label': 'Réalisée',
  'traininglog.done.not.label': ' Non réalisée',
  'traininglog.date.label': 'le',
  'analysis.fps': 'images/s',

  'protocol.warmup': 'Echauffement',
  'protocol.drills': 'Technique',
  'protocol.focus': 'Focus',
  'protocol.new': 'Nouveau protocole',
  'protocol.isAvailable.not': 'Brouillon',
  'protocol.isAvailable': 'Publié',
  'protocol.type': 'Type',

  'analysisCreate.athlete': "Nom de l'athlète",
  'analysisCreate.urlCode': 'Code de la vidéo Youtube',

  'analysis.analysis': 'Analyse',
  'analysis.isAvailable.not': 'Brouillon',
  'analysis.isAvailable': 'Publié',
  'analysis.framecount': "Décompte d'image",
  'analysis.frameToPose': "Images jusqu'à Pose",
  'analysis.frameFromPose': 'Images en Pose',
  'analysis.analysis.display': "Afficher l'analyse",
  'analysis.score.display': 'Retour aux scores',
  'analysis.technique.score': 'Score Technique',

  'analysis.time.initialContact': 'Temps au contact initial',
  'analysis.time.inPose': 'Temps à  Pose',
  'analysis.time.endFall': 'Temps en fin de chute',
  'analysis.time.lastContact': 'Temps au dernier contact',
  'analysis.count.frames': 'Décompter les images',

  'analysis.execution.cadence': 'Cadence',
  'analysis.cadence.mark': 'Repère',
  'analysis.cadence.compute': 'Calculer',

  'analysis.execution.pose': 'POSE',
  'analysis.execution.poseExecutionLanding': 'Contact initial',
  'analysis.execution.poseExecutionLanding.1': 'Loin du corps',
  'analysis.execution.poseExecutionLanding.2': 'Proche du corps',
  'analysis.execution.poseExecutionLanding.3': 'Sous le corps',
  'analysis.execution.poseExecutionKnee': 'Genou',
  'analysis.execution.poseExecutionKnee.1': 'Vérouillé',
  'analysis.execution.poseExecutionKnee.3': 'Déverouillé',
  'analysis.execution.poseExecutionVertical': 'Verticalité en Pose',
  'analysis.execution.poseExecutionVertical.1': 'Tres en avant ',
  'analysis.execution.poseExecutionVertical.2': 'Un peu en avant',
  'analysis.execution.poseExecutionVertical.3': 'Verticale',

  'analysis.execution.fall': 'FALL',
  'analysis.execution.fallExecutionAlignment': 'Alignement du corps',
  'analysis.execution.fallExecutionAlignment.1': 'Fléchi à la hanche',
  'analysis.execution.fallExecutionAlignment.2':
    'Légèrement fléchi à la hanche',
  'analysis.execution.fallExecutionAlignment.3': 'Conservé',
  'analysis.execution.fallExecutionTime': 'Maintient de Pose',
  'analysis.execution.fallExecutionTime.1': 'Court',
  'analysis.execution.fallExecutionTime.2': 'Moyen',
  'analysis.execution.fallExecutionTime.3': 'Long',
  'analysis.execution.fallExecutionFrames': `Nombre d'images de Fall`,
  'analysis.execution.pull': 'PULL',
  'analysis.execution.pullExecutionTiming': 'Timing',
  'analysis.execution.pullExecutionTiming.1': 'Tardif',
  'analysis.execution.pullExecutionTiming.2': 'Moyen',
  'analysis.execution.pullExecutionTiming.3': 'Bon',
  'analysis.execution.pullExecutionLegBent': 'Jambe arrière',
  'analysis.execution.pullExecutionLegBent.1': 'Tendue',
  'analysis.execution.pullExecutionLegBent.3': 'Fléchie',

  'analysis.execution.pullExecutionDirection': 'Direction',
  'analysis.execution.pullExecutionDirection.1': "Vers l'arrière",
  'analysis.execution.pullExecutionDirection.3': 'Vers la hanche',

  'analysis.execution.pullBreakingAngle': 'Angle de Freinage',

  'analysis.fault.focus': 'Erreur & Focus technique',
  'analysis.fault': 'Erreur principale',
  'analysis.focus': 'Axe de travail principal',

  'drills.details': '********************',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.label': 'Banded run',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.setup': 'Pose',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.setup.2':
    'Genou de la jambe de support déverrouillé',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.setup.3':
    "Poids du corps sur l'avant-pied",
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.execution.1': 'Courir sur place',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.execution.2':
    "Pousser le bassin vers l'avant",
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.execution.3': '',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.focus.1':
    "Conserver l'alignement du corps ",
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.focus.2': 'Garder une cadence élevée',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.focus.3':
    'Tirer sur le pied, éviter de pousser',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.repetitions.1': "30s avec l'élastique",
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.repetitions.2': 'Courir 10 mètres',
  'w1rBnZDg4sW4hlOA00OpQDXSj02eeGoe69.repetitions.3': '',

  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.label': 'Bodyweight perception',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.setup': 'Ressort',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.setup.1': 'Genoux fléchis',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.setup.2': "Poids du corps sur l'avant-pied",
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.setup.3': 'Coudes fléchis à 90°',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.execution.1':
    "Déplacer le poids du corps alternativement vers l'avant et vers l'arrière",
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.execution.2':
    "Maintenir la position vers l'avant puis vers l'arrière",
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.execution.3': '',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.focus.1':
    'Percevoir les changements de pression au sol en fonction de la position du corps ',
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.focus.2':
    "Conserver l'alignement du corps ",
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.focus.3': "Garder l'équilibre",
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.repetitions.1':
    "10 vers l'avant / vers l'arrière",
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.repetitions.2':
    "20s vers l'avant/20s vers l'arrière",
  'JnInSyVtYM79UBt4DpndeOuMulKAclJc.repetitions.3': 'Courir 10m ',

  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.label': 'Bodyweight perception in Pose',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.setup': 'Pose',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.setup.2':
    'Genou de la jambe de support déverrouillé',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.setup.3':
    "Poids du corps sur l'avant-pied",
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.execution.1':
    "Déplacer le poids du corps alternativement vers l'avant et vers l'arrière",
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.execution.2':
    "Maintenir la position vers l'avant puis vers l'arrière",
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.execution.3':
    "Réaliser l'exercice du côté opposé.",
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.focus.1':
    'Percevoir les changements de pression au sol en fonction de la position du corps ',
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.focus.2':
    "Conserver l'alignement du corps ",
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.focus.3': "Garder l'équilibre",
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.repetitions.1':
    "10x vers l'avant et vers l'arrière",
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.repetitions.2':
    "Alterner 10x 20s vers l'avant, 20s vers l'arrière",
  'kptr012FUaBZei501T2ZeFYI3nkxHRrZWY.repetitions.3':
    'Changer de coté puis courir 10m ',

  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.label': 'Bunny Hops',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.setup': 'Ressort',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.setup.1': 'Genoux fléchis',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.setup.2': "Poids du corps sur l'avant-pied",
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.setup.3': 'Coudes fléchis à 90°',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.execution.1':
    "Sautiller sur place puis se laisser tomber vers l'avant ",
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.execution.2': '',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.execution.3': '',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.focus.1':
    'Maintenir la même cadence de saut, sur place et en avançant',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.focus.2':
    "Chercher la plus petite inclinaison possible permettant d'avancer",
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.focus.3':
    'Minimiser les efforts musculaires',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.repetitions.1':
    'Alterner 3 sauts sur place et 3 sauts en avançant',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.repetitions.2': 'Courir 10 mètres',
  'VIaxmmoAenwdkfR3D5NmvqN9VpAlAc7u.repetitions.3': '',

  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.label': 'Bunny hops backward',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.setup': 'Ressort',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.setup.1': 'Genoux fléchis',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.setup.2': "Poids du corps sur l'avant-pied",
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.setup.3': 'Coudes fléchis à 90°',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.execution.1':
    'Sautiller sur place puis se laisser tomber légèrement vers arriere',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.execution.2': '',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.execution.3': '',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.focus.1':
    'Maintenir la même cadence de saut, sur place et en reculant',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.focus.2':
    'Chercher la plus petite inclinaison possible permettant de reculer',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.focus.3':
    'Minimiser les efforts musculaires',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.repetitions.1':
    'Alterner 3 sauts sur place, 3 sauts en reculant',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.repetitions.2':
    'Se retourner et Courir 10 mètres',
  'ko1uEq1ZSZYUdivsWxJxI7u3mZ5noGW1.repetitions.3': '',

  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.label': 'Carioca',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.setup': 'Ressort',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.setup.1': 'Genoux fléchis',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.setup.2': "Poids du corps sur l'avant-pied",
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.setup.3': 'Coudes fléchis à 90°',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.execution.1':
    'Se laisser tomber légèrement sur le côté',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.execution.2':
    'Poser le pied alternativement devant puis derrière',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.execution.3': '',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.focus.1':
    'Limiter le mouvement des épaules',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.focus.2':
    "Percevoir le poids du corps sur l'avant-pied",
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.focus.3': '',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.repetitions.1': '10 foulées vers la droite',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.repetitions.2': '10 foulées vers la gauche',
  'QOOrgLrgkuDrknYy3ZuPzepmShhZnS4F.repetitions.3': 'Courir 10 mètres',

  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.label': 'Change of support',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.setup': 'Pose',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.setup.2':
    'Genou de la jambe de support déverrouillé',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.setup.3':
    "Poids du corps sur l'avant-pied",
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.execution.1':
    'Faire un saut sur place puis tirer le pied au sol directement vers le haut, en Pose',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.execution.2': 'Alterner droite et gauche',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.execution.3': '',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.focus.1':
    'Tirer le pied directement sous le bassin',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.focus.2':
    'Laisser le pied en Pose tomber et atterrir naturellement',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.focus.3':
    "Lors du saut sur place s'assurer que la cheville en Pose est au niveau du genou opposé",
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.repetitions.1':
    '10 sur place, 10 avançant',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.repetitions.2': 'Courir 10 mètres',
  'eXK4gxBYeS9u5ykOI1CXK5h4G02OIiik4.repetitions.3': '',

  'z004qJvt36cySVe24kDnbcG960100qzIAbg.label': 'Criss-Cross',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.setup': 'Ressort',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.setup.1': 'Genoux fléchis',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.setup.2':
    "Poids du corps sur l'avant-pied",
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.setup.3': 'Coudes fléchis à 90°',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.execution.1':
    'Sautiller en se laisser tomber en avant',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.execution.2':
    'Croiser alternativement les pieds',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.execution.3': '',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.focus.1':
    'Le talon doit brièvement se poser au sol',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.focus.2': '',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.focus.3': '',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.repetitions.1':
    '10 sur place, 10 avançant',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.repetitions.2': 'Courir 10 mètres',
  'z004qJvt36cySVe24kDnbcG960100qzIAbg.repetitions.3': '',

  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.label': 'Double lunge',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.setup': 'Petite fente',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.setup.1':
    "Un pied devant l'autre, un pied d'écart environ",
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.setup.2': 'Genoux déverrouillés',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.setup.3':
    "Poids du corps sur l'avant-pied",
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.execution.1':
    'Amener alternativement le pied avant puis le pied arriere sous le bassin',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.execution.2': '',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.execution.3': '',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.focus.1':
    'Tirer le pied avant et le pied arrière en ligne droite sous le bassin ',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.focus.2': '',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.focus.3': '',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.repetitions.1':
    '10 foulées pied droit en avant, 10 foulées pied gauche en avant',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.repetitions.2': 'Courir 10 mètres',
  'uSqhCewelT02vCwgKLrqzBitXoE00B01K2H.repetitions.3': '',

  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.label': 'Double pull',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.setup': 'Ressort',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.setup.1': 'Genoux fléchis',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.setup.2':
    "Poids du corps sur l'avant-pied",
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.setup.3': 'Coudes fléchis à 90°',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.execution.1':
    'Tirer simultanément les deux pieds sous le bassin, en haussant activement les épaules',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.execution.2': '',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.execution.3': '',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.focus.1':
    'Accentuer le tirage, minimiser la poussée sur les jambes',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.focus.2':
    'Minimiser les oscillations verticales',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.focus.3': '',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.repetitions.1': '5 sur place',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.repetitions.2': 'Courir 10 mètres',
  'JTOiGpeTTLWxyn5tsYVX2Sh102i5IDjng.repetitions.3': '',

  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.label': 'Exagerated high pull one foot',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.setup':
    'Pose, un bras tendu le long du corps',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.setup.2':
    'Genou de la jambe de support déverrouillé',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.setup.3':
    "Poids du corps sur l'avant-pied",
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.execution.1':
    'Trottiner en amenant la cheville au contact de la main',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.execution.2': '',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.execution.3': '',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.focus.1':
    "Garder le buste droit, sans incliner l'incliner vers l'avant ou sur le côté",
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.focus.2': '',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.focus.3': '',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.repetitions.1':
    '10 sur place, 10 en avançant puis ',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.repetitions.2': 'Courir 10 mètres',
  'Y0010063d7CiWzCZTa8jxv3c00iUVv4601FX.repetitions.3': 'Changer de coté',

  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.label': 'Exagerated pull one foot',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.setup': 'Pose',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.setup.2':
    'Genou de la jambe de support déverrouillé',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.setup.3':
    "Poids du corps sur l'avant-pied",
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.execution.1':
    'Trottiner en amenant une cheville à hauteur du genou opposé',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.execution.2': '',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.execution.3': '',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.focus.1':
    'Après le tirage, ramener le pied directement au sol en ligne droite',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.focus.2': '',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.focus.3': '',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.repetitions.1':
    '10 sur place, 10 en avançant ',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.repetitions.2': 'Courir 10 mètres',
  'GAat6MFvWY25gHtrepwVm02oyCZn5jgOc.repetitions.3': 'Changer de coté',

  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.label':
    'Exagerated pull one foot 3 heights',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.setup': 'Pose',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.setup.2':
    'Genou de la jambe de support déverrouillé',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.setup.3':
    "Poids du corps sur l'avant-pied",
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.execution.1':
    'Trottiner en amenant une cheville à mi-tibia opposé, à hauteur du genou opposé puis à la fesse',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.execution.2': '',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.execution.3': '',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.focus.1':
    'Marquer clairement la différence entre les 3 hauteurs',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.focus.2':
    'Après le tirage, ramener le pied directement au sol en ligne droite',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.focus.3': '',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.repetitions.1':
    "5 fois d'un côté, 5 fois de l'autre",
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.repetitions.2': 'Courir 10 mètres',
  'K018e8b9Ud6YzVs15m0102zI9XD4loTG7dg.repetitions.3': '',

  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.label': 'Face up hip dips',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.setup': 'Assis jambes tendues',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.setup.1':
    'Mains au sol en arriere du bassin',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.setup.2': 'Epaules basses',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.setup.3': '',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.execution.1':
    "Monter le bassin jusqu'à l'alignement épaules, bassin et chevilles",
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.execution.2': '',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.execution.3': '',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.focus.1': 'Garder les jambes tendues',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.focus.2': '',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.focus.3': '',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.repetitions.1': '3x 12 répétitions',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.repetitions.2':
    'Courir 10 mètres entre chaque série',
  'bB5GfS2sTLWv02vv02wm5zoSqzARRSDbnX.repetitions.3': '',

  'a65AlAKrDek7f81jVQ7G746lcb344X5F.label': 'Face up hip dips one leg up',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.setup':
    'Assis jambes tendues, un pied surélevé',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.setup.1':
    'Mains au sol en arriere du bassin',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.setup.2': 'Epaules basses',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.setup.3': '',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.execution.1':
    "Monter le bassin jusqu'à l'alignement épaules, bassin et chevilles",
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.execution.2': '',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.execution.3': '',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.focus.1': 'Garder les jambes tendues',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.focus.2': '',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.focus.3': '',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.repetitions.1':
    '3x10 répétitions de chaque côté',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.repetitions.2':
    'Courir 10 mètres entre chaque série',
  'a65AlAKrDek7f81jVQ7G746lcb344X5F.repetitions.3': '',

  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.label': 'Finger on belly button',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.setup': 'Pose',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.setup.2':
    'Genou de la jambe de support déverrouillé',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.setup.3':
    "Poids du corps sur l'avant-pied",
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.execution.1':
    'Courir sur place, placer deux doigts sur le nombril',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.execution.2':
    'Se laisser tomber en avant en appliquant une pression sur les doigts',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.execution.3': '',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.focus.1':
    'Ressentir la pression sur vos doigts en gardant le buste droit',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.focus.2':
    'Conserver la sensation de chute lors de la course',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.focus.3': '',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.repetitions.1':
    '10 foulées puis Courir 10 mètres',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.repetitions.2': '',
  '9zq4mFaHv02dYhbqTe00rIrHU24VfYxx2x.repetitions.3': '',

  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.label': 'Foot tapping',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.setup':
    'Pose, un bras tendu le long du corps',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.setup.2':
    'Genou de la jambe de support déverrouillé',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.setup.3': "Poids du corps sur l'avant-pied",
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.execution.1':
    'Amener la cheville contact de la main en sautillant',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.execution.2': '',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.execution.3': '',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.focus.1':
    "Garder le buste droit, sans incliner l'incliner vers l'avant ou sur le côté",
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.focus.2': '',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.focus.3': '',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.repetitions.1':
    '10 sur place, 10 en avançant ',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.repetitions.2': 'Courir 10 mètres',
  'giAxXT1iQeELkwQmrQ6VbaCvqdeiWQfP.repetitions.3': 'Changer de côté',

  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.label': 'Front lunge',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.setup': 'Petite fente',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.setup.1':
    "Un pied devant l'autre, un pied d'écart environ",
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.setup.2': 'Genoux déverrouillés',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.setup.3':
    "Poids du corps sur l'avant-pied",
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.execution.1':
    'Amener le pied avant sous le bassin',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.execution.2': '',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.execution.3': '',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.focus.1':
    'Tirer le pied en ligne droite sous le bassin',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.focus.2':
    "Maintenir l'écart entre les pieds",
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.focus.3': '',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.repetitions.1':
    '10 foulées pied droit en avant',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.repetitions.2':
    '10 foulées pied gauche en avant',
  'e4N0101ZxCeO0019hhPAhRB1R01Ny149rsL02.repetitions.3': 'Courir 10 mètres',

  'QNryITSngSojSEEpov5xKtIkCefYrsSA.label': 'Front to back Pendulum',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.setup': 'Ressort',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.setup.1': 'Genoux fléchis',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.setup.2': "Poids du corps sur l'avant-pied",
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.setup.3': 'Coudes fléchis à 90°',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.execution.1':
    "Monter une jambe tendue vers l'avant puis la ramener au centre",
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.execution.2':
    "Avant que le pied ne touche le sol, changer d'appui et amener l'autre jambe tendue vers l'arriere",
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.execution.3': '',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.focus.1':
    "Maintenir une cadence de saut identique sur place et lors de la réalisation de l'exercice",
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.focus.2': '',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.focus.3': '',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.repetitions.1':
    '10 foulées jambe gauche en avant',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.repetitions.2':
    '10 foulées jambe droite en avant',
  'QNryITSngSojSEEpov5xKtIkCefYrsSA.repetitions.3': 'Courir 10 mètres',

  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.label': 'Get in Pose',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.setup': 'Ressort',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.setup.1': 'Genoux fléchis',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.setup.2':
    "Poids du corps sur l'avant-pied",
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.setup.3': 'Coudes fléchis à 90°',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.execution.1':
    'Amener une cheville à hauteur du genou opposé',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.execution.2': 'Ramener le pied au sol',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.execution.3': '',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.focus.1': 'Garder la cheville relachée',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.focus.2':
    "Genou de la jambe d'appui déverrouillé",
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.focus.3':
    "Garder le poids du corps sur l'avant pied",
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.repetitions.1': '20 de chaque côté ',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.repetitions.2': 'Courir 10 mètres',
  'EoE27qj6SzK02600sV2Jn00f401Uc2rZhe2n.repetitions.3': '',

  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.label': 'Hands clapsed behind',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.setup':
    'Pose, bras tendus dans le dos, mains jointes et doigts entrelacés ',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.setup.2':
    'Genou de la jambe de support déverrouillé',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.setup.3':
    "Poids du corps sur l'avant-pied",
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.execution.1': 'Courir sur place',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.execution.2':
    'Se laisser tomber en éloignant le bassin des mains',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.execution.3': '',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.focus.1':
    'Conserver la sensation de chute lors de la course',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.focus.2': '',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.focus.3': '',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.repetitions.1': '10 foulées',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.repetitions.2': 'Courir 10 mètres',
  'dAp02PQfvhRpXJZQCPf6orlebkWnDRnxu.repetitions.3': '',

  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.label': 'Hands clapsed in front',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.setup':
    'Pose, bras tendus horizontalement, mains jointes doigts entrelacés',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.setup.2':
    'Genou de la jambe de support déverrouillé',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.setup.3':
    "Poids du corps sur l'avant-pied",
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.execution.1':
    'Courir sur place en limitant les oscillations des mains',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.execution.2':
    'Se laisser tomber en avant en gardant les mains le plus immobile possible',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.execution.3': '',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.focus.1':
    'Conserver la sensation de chute lors de la course',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.focus.2': '',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.focus.3': '',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.repetitions.1': '10 foulées',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.repetitions.2': 'Courir 10 mètres',
  'T0182gKAEZCYDw86P4iM6nsGZvERt5HcR.repetitions.3': '',

  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.label': 'Hip dips',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.setup':
    'Position de planche sur les mains',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.setup.1': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.setup.2': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.setup.3': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.execution.1':
    'Amener le basin vers le bas puis vers le haut ',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.execution.2': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.execution.3': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.focus.1': 'Garder les bras tendus',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.focus.2': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.focus.3': '',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.repetitions.1': '3x12 répétitions',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.repetitions.2':
    'Courir 10 mètres entre chaque série',
  '00Z9022NtXDgylM8pLjedRhl02yenPK69N5.repetitions.3': '',

  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.label': 'Hip dips one leg up',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.setup':
    'Position de planche sur les mains, un pied surélevé',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.setup.1': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.setup.2': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.setup.3': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.execution.1':
    'Amener le basin vers le bas puis vers le haut ',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.execution.2': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.execution.3': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.focus.1': 'Garder les bras tendus',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.focus.2': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.focus.3': '',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.repetitions.1':
    '3x 5 répétitions avec le pied droit surélevé/5 avec le pied gauche surélevé',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.repetitions.2':
    'Courir 10 mètres entre chaque série',
  '6Cd5AiDXNh9PF78ve6g6Mhtt602nQGybp.repetitions.3': '',

  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.label': 'Hip dips one arm',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.setup':
    'Position de planche, une main surélevée au niveau des épaules',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.setup.1': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.setup.2': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.setup.3': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.execution.1':
    'Amener le basin vers le bas puis vers le haut ',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.execution.2':
    '5 répétitions avec la main droite surélevée, 5 avec la main gauche surélevée',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.execution.3': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.focus.1': 'Garder le bras tendu',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.focus.2': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.focus.3': '',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.repetitions.1':
    '3x 5 répétitions avec la main droite surélevée/5 avec la main gauche surélevée',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.repetitions.2':
    'Courir 10 mètres entre chaque série',
  'YTfnWl2EXb60200rfnYXuE01VuQ3agGfNtd.repetitions.3': '',

  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.label': 'Hops in Pose',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.setup': 'Pose',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.setup.2':
    'Genou de la jambe de support déverrouillé',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.setup.3': "Poids du corps sur l'avant-pied",
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.execution.1':
    'Sautiller sur place, en Pose',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.execution.2': '',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.execution.3': '',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.focus.1':
    'Maintenir le pied en Pose, sous le bassin, cheville au niveau du genou opposé',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.focus.2': '',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.focus.3': '',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.repetitions.1': '10 à droite',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.repetitions.2': '10 à gauche',
  'QpZTBfsGlMgNYBSzMP8daSWMtCZRMpwR.repetitions.3': 'Courir 10 mètres',

  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.label': 'Jump rope change of support',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.setup': 'Pose, avec la corde',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.setup.2':
    'Genou de la jambe de support déverrouillé',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.setup.3':
    "Poids du corps sur l'avant-pied",
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.execution.1': 'Sauter à la corde en Pose',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.execution.2':
    'Changer de pied tous les deux sauts',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.execution.3': '',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.focus.1':
    'Garder le pied en Pose exactement sous le bassin',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.focus.2': '',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.focus.3': '',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.repetitions.1': '20 sauts',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.repetitions.2':
    'Poser la corde puis courir 10 mètres',
  'Jn7Bd5H02ACOd1sG6ziXjMZy3LtoXbAY1.repetitions.3': '',

  '5l02fmJyivrknTNR664tgkuICyPnBft5o.label': 'Jump rope double pull',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.setup': 'Ressort, avec la corde',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.setup.1': 'Genoux fléchis',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.setup.2':
    "Poids du corps sur l'avant-pied",
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.setup.3': 'Coudes fléchis à 90°',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.execution.1':
    'Sauter à la corde pieds joints',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.execution.2':
    'Amener les deux pieds simultanément à hauteur de Pose',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.execution.3': '',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.focus.1':
    'Garder la tête à la même  hauteur que lors de sauts à la cordes classiques',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.focus.2':
    'Pousser le moins possible, accentuer le tirage',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.focus.3': '',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.repetitions.1': '20 sauts',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.repetitions.2':
    'Poser la corde puis curir 10 mètres',
  '5l02fmJyivrknTNR664tgkuICyPnBft5o.repetitions.3': '',

  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.label': 'Jump rope in Pose',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.setup': 'Pose, avec la corde',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.setup.2':
    'Genou de la jambe de support déverrouillé',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.setup.3':
    "Poids du corps sur l'avant-pied",
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.execution.1': 'Sauter à la corde en Pose',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.execution.2': '',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.execution.3': '',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.focus.1':
    'Garder le pied en Pose exactement sous le bassin',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.focus.2': '',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.focus.3': '',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.repetitions.1':
    '3x 10 à droite/10 à gauche',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.repetitions.2':
    'Poser la corde puis courir 10 mètres',
  'pAqc9j3VrWabk4w9YyrOVmMtLr202Z4aR.repetitions.3': '',

  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.label': 'Jump rope pony',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.setup': 'Pony Pose, avec la corde',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.setup.1':
    'Pied en Pose sous le bassin, orteils en contact avec le sol',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.setup.2':
    'Genou de la jambe de support déverrouillé',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.setup.3':
    "Poids du corps sur l'avant-pied",
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.execution.1':
    'Sauter à la corde en Pony Pose',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.execution.2':
    'Changer de pied à chaque saut',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.execution.3': '',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.focus.1': 'Sauter le moins haut possible',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.focus.2': '',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.focus.3': '',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.repetitions.1': '20 sauts',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.repetitions.2':
    'Poser la corde puis courir 10 mètres',
  'L7i4CBP5nzli2bKjaLk01UD9ZXC58HTyM.repetitions.3': '',

  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.label': 'Jump rope run in place',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.setup': 'Pose, avec la corde',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.setup.2':
    'Genou de la jambe de support déverrouillé',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.setup.3':
    "Poids du corps sur l'avant-pied",
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.execution.1':
    'Courir sur place avec la corde',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.execution.2': '',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.execution.3': '',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.focus.1':
    "Tirer le pied du sol avant que celui en l'air ne commence à descendre",
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.focus.2': '',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.focus.3': '',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.repetitions.1': '20 foulées sur place',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.repetitions.2':
    'Poser la corde puis courir 10 mètres',
  '8joE01AydQP1if8Boj01HPVELGd02Hur02oa.repetitions.3': '',

  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.label': 'Jumpe rope simple under',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.setup': 'Ressort, avec la corde',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.setup.1': 'Genoux fléchis',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.setup.2':
    "Poids du corps sur l'avant-pied",
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.setup.3': 'Coudes fléchis à 90°',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.execution.1':
    'Sauter à la corde pieds joints',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.execution.2': '',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.execution.3': '',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.focus.1': 'Garder les genoux fléchis',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.focus.2':
    'Amener les talons au sol à chaque saut',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.focus.3': '',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.repetitions.1': '20 sauts',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.repetitions.2':
    'Poser la corde puis courir 10 mètres',
  '7iB7mp9PsW85A6juYxLTVz01UfprKJUVb.repetitions.3': '',

  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.label': 'Jumping jack to Pose',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.setup': 'Ressort, pieds écartés',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.setup.1': 'Genoux fléchis',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.setup.2': "Poids du corps sur l'avant-pied",
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.setup.3': 'Coudes fléchis à 90°',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.execution.1':
    'De la position pieds écartés, sauter sur place et atterrir en Pose',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.execution.2':
    'Alterner pied gauche et pied droit',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.execution.3': '',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.focus.1':
    "Bien atterrir en Pose à chaque saut, pied sous le bassin, genou vers l'avant",
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.focus.2':
    'Eviter les déplacements latéraux du corps',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.focus.3': '',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.repetitions.1':
    '10 sur place, 10 en avançant ',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.repetitions.2': 'Courir sur 10 mètres',
  'CmhgSrZYeTrqE2oOLpPafTBWmduYwimI.repetitions.3': '',

  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.label': 'Jumps feet together and appart',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.setup': 'Ressort,',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.setup.1': 'Genoux fléchis',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.setup.2':
    "Poids du corps sur l'avant-pied",
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.setup.3': 'Coudes fléchis à 90°',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.execution.1':
    'Sauter en écartant les pieds',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.execution.2': 'Atterrir pieds joints',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.execution.3': '',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.focus.1':
    'Garder la même cadence sur place et en avançant',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.focus.2':
    'Utiliser la gravité pour avancer, aucun effort musculaire',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.focus.3':
    'Le talon doit brièvement se poser au sol',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.repetitions.1':
    '10 sur place, 10 en avançant ',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.repetitions.2': 'Courir sur 10 mètres',
  'w7C6TsBi5Bb01mU6rmIrDuUULDrMzGelX.repetitions.3': '',

  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.label': 'Jumps feet appart and together',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.setup': 'Ressort, pieds écartés',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.setup.1': 'Genoux fléchis',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.setup.2':
    "Poids du corps sur l'avant-pied",
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.setup.3': 'Coudes fléchis à 90°',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.execution.1':
    "De la position pieds écartés, amener les pieds en contact en l'air ",
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.execution.2': 'Atterrir pieds écartés',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.execution.3': '',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.focus.1':
    'Garder la même cadence sur place et en avançant',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.focus.2':
    'Utiliser la gravité pour avancer, aucun effort musculaire',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.focus.3':
    'Le talon doit brièvement se poser au sol',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.repetitions.1':
    '10 sur place, 10 en avançant ',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.repetitions.2': 'Courir sur 10 mètres',
  'b00cnj1Mw98XBYJkyzTThv6y9SjgZkisi.repetitions.3': '',

  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.label': 'Palm on lower back',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.setup': 'Ressort',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.setup.1': 'Genoux fléchis',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.setup.2':
    "Poids du corps sur l'avant-pied",
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.setup.3': 'Coudes fléchis à 90°',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.execution.1':
    "Placer la paume d'une main sur les lombaires",
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.execution.2':
    "Se laisser tomber vers l'avant ",
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.execution.3': '',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.focus.1':
    'Ressentir la chute à partir du bassin',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.focus.2': '',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.focus.3': '',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.repetitions.1':
    '10 sur place, 10 en avançant ',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.repetitions.2': 'Courir sur 10 mètres',
  'Fn3xztuZC00ukHoEBBbk7olsOqkbblA8m.repetitions.3': '',

  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.label': 'Pony',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.setup': 'Pony Pose',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.setup.1':
    'Pied sous le bassin, orteils en contact avec le sol',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.setup.2':
    'Genou de la jambe de support déverrouillé',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.setup.3':
    "Poids du corps sur l'avant-pied",
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.execution.1':
    "Tirer le pied de support vers le haut, et atterrir en Pony Pose sur l'autre pied",
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.execution.2':
    'Faire un sursaut sur place et recommencer',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.execution.3': '',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.focus.1':
    'Tirer le pied de support le moins haut possible',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.focus.2':
    'Hausser activement les épaules pour faciliter le tirage',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.focus.3': '',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.repetitions.1': '10 en avançant ',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.repetitions.2': 'Courir sur 10 mètres',
  'h5P4RF5b3M00ipbE3re3xahPn4DleOLc6.repetitions.3': '',

  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.label': 'Running backward',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.setup': 'Pose',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.setup.2':
    'Genou de la jambe de support déverrouillé',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.setup.3':
    "Poids du corps sur l'avant-pied",
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.execution.1': 'Courir sur place',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.execution.2':
    "Se laisser tomber vers l'arriere",
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.execution.3': '',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.focus.1':
    "Le contact initial du pied se fait par l'avant pied",
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.focus.2':
    'Le talon se pose brievement au sol à chaque foulée',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC2.focus.3': '',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC3.repetitions.1': '10 en reculant',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC4.repetitions.2': 'Courir sur 10 mètres',
  'SseNmLmDHF47n00D1xg8YhYSaX5iDRqC5.repetitions.3': '',

  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.label': 'Running hands overhead',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.setup':
    'Pose, bras tendus au dessus de la tête',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.setup.2':
    'Genou de la jambe de support déverrouillé',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.setup.3':
    "Poids du corps sur l'avant-pied",
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.execution.1': 'Courir sur place',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.execution.2':
    "Se laisser tomber vers l'avant",
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.execution.3': '',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.focus.1':
    'Le buste est vertical, les épaules au dessus du bassin',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.focus.2': '',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.focus.3': '',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.repetitions.1':
    '10 sur place, 10 en avançant ',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.repetitions.2': 'Courir sur 10 mètres',
  '02x0285JwDTdMkwxMD8u01rsDRnG93phTmk.repetitions.3': '',

  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.label': 'Running in place',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.setup': 'Pose',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.setup.2':
    'Genou de la jambe de support déverrouillé',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.setup.3':
    "Poids du corps sur l'avant-pied",
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.execution.1': 'Courir sur place',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.execution.2':
    '10 foulées sur place, puis Courir 10 mètres',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.execution.3': '',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.focus.1':
    "Le contact initial du pied se fait par l'avant pied",
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.focus.2':
    'Le talon se pose brievement au sol à chaque foulée',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.focus.3': '',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.repetitions.1': '10 sur place',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.repetitions.2': 'Courir sur 10 mètres',
  '6i8dSgrovedAb7Gj01ePja01mKChyllWW2.repetitions.3': '',

  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.label': 'Running Pose',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.setup': 'Ressort',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.setup.1': 'Genoux fléchis',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.setup.2': "Poids du corps sur l'avant-pied",
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.setup.3': 'Coudes fléchis à 90°',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.execution.1':
    'Monter un pied directement sous le bassin, cheville au niveau du genou opposé',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.execution.2':
    'Garder le genou de la jambe de support fléchi',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.execution.3':
    "Conserver le poids du corps sur l'avant pied",
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.focus.1':
    'Se focaliser sur la trajectoire rectiligne du pied',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.focus.2':
    'Ne pas monter activement le genou',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.focus.3': '',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.repetitions.1': '30s à droite',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.repetitions.2': '30s à gauche',
  'IyDqLFhmbpFwpMGLZlKoXauxl5rOjZyv.repetitions.3': '',

  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.label': 'Side hip dips',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.setup': 'Position de planche sur le côté',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.setup.1': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.setup.2': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.setup.3': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.execution.1':
    'Amener le basin vers le bas puis vers le haut ',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.execution.2': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.execution.3': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.focus.1':
    'Corps aligné, les fesses ne doivent pas reculer',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.focus.2': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.focus.3': '',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.repetitions.1': '3x 5 à droite/5 à gauche',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.repetitions.2':
    'Courir 10 mètres entre chaque série',
  'cwt7oNiCmj6qqltGCTE4vsS9029DUGgAd.repetitions.3': '',

  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.label': 'Side to side pendulum',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.setup': 'Ressort',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.setup.1': 'Genoux fléchis',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.setup.2':
    "Poids du corps sur l'avant-pied",
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.setup.3': 'Coudes fléchis à 90°',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.execution.1':
    'Monter une jambe gauche tendue sur le côté en sautillant puis la ramener au centre',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.execution.2':
    "Avant que le pied touche le sol, amener l'autre jambe tendue de l'autre côté",
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.execution.3': '',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.focus.1':
    'Maintenir la même cadence de saut sur place et en avançant',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.focus.2': '',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.focus.3': '',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.repetitions.1':
    '10 sur place, 10 en avançant ',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.repetitions.2': 'Courir sur 10 mètres',
  'ea4zeELV600oP02dHphPQyJGk6QgnwxDIO.repetitions.3': '',

  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.label': 'Skip',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.setup': 'Ressort',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.setup.1': 'Genoux fléchis',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.setup.2':
    "Poids du corps sur l'avant-pied",
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.setup.3': 'Coudes fléchis à 90°',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.execution.1':
    'Sauter en tirant activement un pied vers le haut, sous le bassin',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.execution.2':
    'Atterrir simultanément avec les deux pieds ',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.execution.3': '',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.focus.1':
    'Tirer rapidement le pied en ligne droite et verticale',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.focus.2':
    "Laisser tomber le pied en l'air naturellement vers le sol",
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.focus.3': '',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.focus': '',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.repetitions.1':
    '10 sur place, 10 en avançant ',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.repetitions.2': 'Courir sur 10 mètres',
  'Cq2oXCRrSsccSsn02Bbcp2CzAPvM7C6Zm.repetitions.3': 'Changer de coté',

  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.label': 'Skip alternating feet',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.setup': 'Ressort',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.setup.1': 'Genoux fléchis',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.setup.2': "Poids du corps sur l'avant-pied",
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.setup.3': 'Coudes fléchis à 90°',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.execution.1':
    'Sauter en tirant alternativement un pied vers le haut, sous le bassin',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.execution.2':
    'Atterrir simultanément avec les deux pieds ',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.execution.3': '',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.focus.1':
    'Tirer rapidement le pied en ligne droite et verticale',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.focus.2':
    'Laisser tomber le pied naturellement vers le sol',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.focus.3': '',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.focus': '',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.repetitions.1':
    '10 sur place, 10 en avançant ',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.repetitions.2': 'Courir sur 10 mètres',
  '9ULb5MfWLmGOuEutyEdp7HDesHdPdJqm.repetitions.3': '',

  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.label': 'Support leg pull',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.setup': 'Pose',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.setup.2':
    'Genou de la jambe de support déverrouillé',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.setup.3':
    "Poids du corps sur l'avant-pied",
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.execution.1':
    "Sauter sur le pied de support en l'amenant à hauteur du pied en Pose",
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.execution.2': '',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.execution.3': '',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.focus.1':
    'Hausser activement les épaules pour faciliter le tirage',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.focus.2':
    'Accentuer le tirage du pied, limiter la poussée',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.focus.3':
    'Pendant la course, se focaliser sur le tirage actif du pied',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.repetitions.1': '5 à droite/5 à gauche ',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.repetitions.2': 'Courir 10 mètres',
  'XXHscFEPdTBcjFvtZO3M01zK733em1IdB.repetitions.3': '',

  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.label': 'Timber drill',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.setup': 'Ressort',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.setup.1': 'Genoux fléchis',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.setup.2': "Poids du corps sur l'avant-pied",
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.setup.3': 'Coudes fléchis à 90°',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.execution.1':
    "Se laisser tomber vers l'avant et se rattrapper au dernier moment",
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.execution.2':
    '5 à droite, 5 à gauche puis Courir 10 mètres',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.execution.3': '',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.focus.1':
    'Ressentir la chute à partir du bassin',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.focus.2': 'Garder le buste de droit',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.focus.3': '',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.repetitions.1': ' 5 à droite/5 à gauche ',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.repetitions.2': 'Courir 10 mètres',
  'CKhpZYudiaRSZwmVBZlertuYzCoWDMuy.repetitions.3': '',

  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.label': 'Timber drill in Pose',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.setup': 'Pose',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.setup.2':
    'Genou de la jambe de support déverrouillé',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.setup.3':
    "Poids du corps sur l'avant-pied",
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.execution.1':
    "Se laisser tomber vers l'avant et se rattrapper au dernier moment",
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.execution.2': '',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.execution.3': '',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.focus.1':
    'Ressentir la chute à partir du bassin',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.focus.2': 'Garder le buste de droit',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.focus.3': '',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.repetitions.1': ' 5 à droite/5 à gauche ',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.repetitions.2': 'Courir 10 mètres',
  '6aBkTVTxiMje1oATOsL2b2az02liBTgjJ.repetitions.3': '',

  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.label':
    'Timber Drill with change of support',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.setup': 'Pose',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.setup.2':
    'Genou de la jambe de support déverrouillé',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.setup.3':
    "Poids du corps sur l'avant-pied",
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.execution.1':
    "Se laisser tomber vers l'avant ",
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.execution.2':
    "Tirer sur le pied de support pour atterrir en Pose de l'autre côté",
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.execution.3': '',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.focus.1':
    'Tirer activement le pied de support dès la fin de la chute',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.focus.2': '',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.focus.3': '',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.repetitions.1': ' 5 à droite/5 à gauche ',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.repetitions.2': 'Courir 10 mètres',
  '6fjBK5QrnZRJVjU2ODo02vaxJdwjwTNUG.repetitions.3': '',

  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.label': 'Toes in and Out',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.setup': 'Ressort',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.setup.1': 'Genoux fléchis',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.setup.2':
    "Poids du corps sur l'avant-pied",
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.setup.3': 'Coudes fléchis à 90°',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.execution.1':
    "Sautiller en orientant les orteils vers l'extérieur, vers l'avant puis vers l'intérieur",
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.execution.2': '',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.execution.3': '',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.focus.1':
    'Le talon doit brievement se poser au sol',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.focus.2': '',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.focus.3': '',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.repetitions.1': '10 en avançant ',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.repetitions.2': 'Courir sur 10 mètres',
  '00XJYaD02RDEXK1K202UBLhML00UAMKDHeaN.repetitions.3': '',

  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.label': 'Twist',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.setup': 'Ressort',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.setup.1': 'Genoux fléchis',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.setup.2':
    "Poids du corps sur l'avant-pied",
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.setup.3': 'Coudes fléchis à 90°',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.execution.1':
    "Sautiller en orientant alternativement le bassin d'un côté puis de l'autre",
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.execution.2': '',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.execution.3': '',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.focus.1':
    'Garder au maximum les épaules de face',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.focus.2':
    'Le talon doit brievement se poser au sol',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.focus.3': '',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.repetitions.1':
    '10 sur place, 10 en avançant ',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.repetitions.2': 'Courir sur 10 mètres',
  '01iFMh9KZx8UNAfc9Z6numeoXeFwBtuW4.repetitions.3': '',

  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.label': 'Wall fall',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.setup':
    'Ressort, à environ 1 mètre du mur',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.setup.1': 'Genoux fléchis',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.setup.2':
    "Poids du corps sur l'avant-pied",
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.setup.3': '',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.execution.1':
    "Se laisser tomber vers l'avant ",
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.execution.2':
    'Se rattrapper au dernier moment en posant les mains au mur',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.execution.3': '',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.focus.1': 'Chuter à partir du bassin',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.focus.2': 'Garder le buste de droit',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.focus.3': '',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.repetitions.1': '10 répétitions',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.repetitions.2': 'Courir sur 10 mètres',
  'GIpiFYX8iXU2y6HxeXhRT00I5Eb8SW02yw.repetitions.3': '',

  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.label': 'Wall fall in Pose',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.setup':
    'Ressort, à environ 1 mètre du mur',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.setup.1':
    'Pied sous le bassin, cheville au niveau du genou opposé',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.setup.2':
    'Genou de la jambe de support déverrouillé',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.setup.3':
    "Poids du corps sur l'avant-pied",
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.execution.1':
    "Se laisser tomber vers l'avant ",
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.execution.2':
    'Se rattrapper au dernier moment en posant les mains au mur',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.execution.3': '',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.focus.1': 'Chuter à partir du bassin',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.focus.2': 'Garder le buste de droit',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.focus.3': '',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.repetitions.1': '5 à droite, 5 à gauche ',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.repetitions.2': 'Courir sur 10 mètres',
  'Sp23bD0155fDhIcjVfvqi33lWx1DO1Xpv.repetitions.3': ''
}
export default fr_fr
