// showLastCommitMessageForThisLibrary.js
import { create } from 'apisauce'
import moment from 'moment'

let serverURL = ''

if (process.env.NODE_ENV === 'production') {
  //serverURL = "http://163.172.138.236:8080"
  serverURL = 'https://app-server.runwithjey.fr'
} else {
  serverURL = 'http://localhost:9000'
  //serverURL = "https://app-server.runwithjey.fr"
}
const access_token = 'DO40o7Hii4GqWCnBT17By9silRPIcE7F'
// define the api
var api
function main() {
  try {
    localStorage.getItem && (window.storage = localStorage)
  } catch (exception) {
    return
  }
  api = create({
    baseURL: serverURL,
    //baseURL: 'http://b97fb45c.ngrok.io',
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('RWJToken')
        ? 'Bearer ' + localStorage.getItem('RWJToken')
        : null
    }
  })
}
const setAuthHeaders = () => {
  api.setHeaders({
    Authorization: localStorage.getItem('RWJToken')
      ? 'Bearer ' + localStorage.getItem('RWJToken')
      : null
  })
}
//main permet de faire un return pour arretrer l'execution si pas de cookies / session
main()

export const login = (email, password) => {
  let tok = email + ':' + password
  let hash = btoa(tok)
  return api.post(
    '/auth',
    { access_token: access_token },
    {
      headers: {
        Authorization: 'Basic ' + hash,
        'Content-Type': 'application/json'
      }
    }
  )
}
export const signup = body => {
  api.setHeaders({
    Authorization: 'Bearer ' + access_token,
    'Content-Type': 'application/json'
  })
  return api.post('/users', body)
}

export const sendPasswordReset = email => {
  api.setHeaders({
    Authorization: 'Bearer ' + access_token,
    'Content-Type': 'application/json'
  })
  const link =
    window.location.protocol + '//' + window.location.host + '/forgot'
  const body = { email, link }
  return api.post('/password-resets', body)
}
export const PasswordResetTokenCheck = token => {
  return api.get(`/password-resets/${token}`)
}
export const PasswordReinit = (password, token) => {
  return api.put(`/password-resets/${token}/`, { password })
}

export const getMe = () => {
  setAuthHeaders()
  return api.get('/users/me')
}
export const getMyAthletes = () => {
  setAuthHeaders()
  return api.get('/users/my?&sort=name&limit=100')
}

export const getMyActiveAthletes = () => {
  setAuthHeaders()
  return api.get('/users/my-active?&sort=name')
}
export const getAllAthletes = () => {
  setAuthHeaders()
  return api.get('/users?&sort=name')
}
export const getAthlete = userId => {
  setAuthHeaders()
  return api.get(`/users/${userId}`)
}
export const getAthletesCourses = userId => {
  setAuthHeaders()
  return api.get(`/courses/user/${userId}?&sort=-date`)
}

export const getMeCourses = userId => {
  setAuthHeaders()
  return api.get(`/courses/user/me?&sort=-date`)
}

export const getFaults = () => {
  setAuthHeaders()
  return api.get('/faults/')
}
export const createFault = body => {
  setAuthHeaders()
  return api.post('/faults/', body)
}
export const getDrills = () => {
  setAuthHeaders()
  return api.get('/drills/?&sort=name&limit=100')
}
export const getDrillsByCategory = category => {
  setAuthHeaders()
  return api.get(`/drills/category/${category}?&sort=name`)
}
export const getDrill = id => {
  setAuthHeaders()
  return api.get('/drills/' + id)
}
export const createDrill = body => {
  setAuthHeaders()
  return api.post('/drills/', body)
}

export const updateDrill = (id, body) => {
  setAuthHeaders()
  return api.put('/drills/' + id, body)
}

export const getMyClues = () => {
  setAuthHeaders()
  return api.get('/clues/my')
}
export const updateUserFault = (userId, faultId) => {
  const faults = {
    faults: {
      fault: faultId
    }
  }
  setAuthHeaders()
  return api.put(`/users/${userId}/add-fault/`, faults)
}

export const updateUserClue = (userId, clueId) => {
  const faults = {
    clues: {
      clue: clueId
    }
  }
  setAuthHeaders()
  return api.put(`/users/${userId}/add-clue/`, faults)
}
export const updateUserIsActive = (userId, statusString) => {
  const status = {
    isActive: statusString
  }
  setAuthHeaders()
  return api.put(`/users/${userId}/status/`, status)
}
export const updateProfile = (userId, body) => {
  setAuthHeaders()
  return api.put(`/users/${userId}`, body)
}
export const createPerf = body => {
  setAuthHeaders()
  return api.post('/perfs/', body)
}
export const getMyLastPerfs = () => {
  setAuthHeaders()
  return api.get('/perfs/my/')
}
export const getAllMyPerfs = () => {
  setAuthHeaders()
  return api.get('/perfs/my/1000')
}
export const getMyRealPerfsAndFactor = () => {
  setAuthHeaders()
  return api.get('/perfs/my-real-factor')
}
export const deleteMyPerf = perfId => {
  setAuthHeaders()
  return api.delete(`/perfs/${perfId}`)
}
export const createAdvice = body => {
  setAuthHeaders()
  return api.post('/advices/', body)
}
export const updateAdvice = body => {
  setAuthHeaders()
  return api.put('/advices/', body)
}
export const uploadProfilePic = body => {
  setAuthHeaders()
  api.setHeaders({
    'Content-Type': 'multipart/form-data'
  })
  return api.post('/users/upload/', body)
}
export const getMyCourses = group => {
  setAuthHeaders()
  let groupParam = ''
  if (group) {
    groupParam = '&group=' + group
  }
  return api.get('/courses/my?&sort=-date&limit=100' + groupParam)
}

export const getMyGroups = () => {
  setAuthHeaders()

  return api.get('/courses/mygroups')
}

export const getAllCourses = () => {
  setAuthHeaders()
  return api.get('/courses?&sort=-date')
}
export const getCourse = id => {
  setAuthHeaders()
  return api.get('/courses/' + id)
}
export const copyCourse = id => {
  setAuthHeaders()
  return api.get('/courses/copy/' + id)
}
export const createCourse = body => {
  setAuthHeaders()
  return api.post('/courses/', body)
}
export const AddPlayerToClass = (classId, body) => {
  setAuthHeaders()
  return api.put('/courses/' + classId + '/add-user', body)
}
export const addDrillToClass = (classId, body) => {
  setAuthHeaders()
  return api.put('/courses/' + classId + '/add-drill', body)
}

export const removeDrillFromClass = (classId, body) => {
  setAuthHeaders()
  return api.put('/courses/' + classId + '/del-drill', body)
}

export const addWarmupDrillToClass = (classId, body) => {
  setAuthHeaders()
  return api.put('/courses/' + classId + '/add-warmupdrill', body)
}
export const removeWarmupDrillFromClass = (classId, body) => {
  setAuthHeaders()
  return api.put('/courses/' + classId + '/del-warmupdrill', body)
}

export const RemovePlayerFromClass = (classId, body) => {
  setAuthHeaders()
  return api.put('/courses/' + classId + '/del-user', body)
}
export const UpdateClass = (classId, body) => {
  setAuthHeaders()
  return api.put('/courses/' + classId, body)
}
export const getNextClass = () => {
  setAuthHeaders()
  return api.get(
    '/courses/imin/' +
      moment()
        .startOf('day')
        .local()
        .toISOString() +
      '?&sort=date'
  )
}
export const getMyClassById = id => {
  setAuthHeaders()
  return api.get('/courses/imin/id/' + id)
}

export const updateCourseIsAvailable = (courseId, statusString) => {
  const status = {
    isAvailable: statusString
  }
  setAuthHeaders()
  return api.put(`/courses/${courseId}/status/`, status)
}

export const updateGroup = (courseId, groupName) => {
  const body = {
    group: groupName
  }
  setAuthHeaders()
  return api.put(`/courses/${courseId}/group/`, body)
}

export const deleteCourse = courseId => {
  setAuthHeaders()
  return api.delete(`/courses/${courseId}`)
}

export const getNotificationsForMe = () => {
  setAuthHeaders()
  return api.get('/notifications/forme?&sort=-createdAt')
}
export const getNotificationsSent = () => {
  setAuthHeaders()
  return api.get('/notifications/sent?&sort=-createdAt')
}
export const countNotificationsForMe = () => {
  setAuthHeaders()
  return api.get('/notifications/forme-count')
}
export const setMyNotificationAsRead = () => {
  setAuthHeaders()
  return api.get('/notifications/set-read')
}
export const createNotifications = body => {
  setAuthHeaders()
  return api.post('/notifications/many', body)
}
export const getAllAnalyses = () => {
  setAuthHeaders()
  return api.get('/analyses/?&sort=-createdAt')
}
export const getMyAnalyses = () => {
  setAuthHeaders()
  return api.get('/analyses/my/1000?&sort=-createdAt')
}
export const getMyAnalysesCreated = () => {
  setAuthHeaders()
  return api.get('/analyses/my-created/1000?&sort=-createdAt')
}
export const createAnalysis = body => {
  setAuthHeaders()
  return api.post('/analyses/', body)
}
export const getAnalysis = id => {
  setAuthHeaders()
  return api.get('/analyses/' + id)
}
export const updateAnalysis = (analysisId, body) => {
  setAuthHeaders()
  return api.put('/analyses/' + analysisId, body)
}

export const createTrainingLog = body => {
  setAuthHeaders()
  return api.post('/training-log/', body)
}

export const updateTrainingLog = (logId, body) => {
  setAuthHeaders()
  return api.put('/training-log/' + logId, body)
}
export const getTrainingLog = id => {
  setAuthHeaders()
  return api.get('/training-log/' + id)
}
export const getTrainingLogForCourse = (courseId, userId) => {
  setAuthHeaders()
  return api.get('/training-log/course/' + courseId)
}

export const getTrainingLogForCourseUser = (courseId, userId) => {
  setAuthHeaders()
  return api.get('/training-log/course-user/' + courseId + '/' + userId)
}

export const pickProtocol = type => {
  setAuthHeaders()
  return api.get('/protocols/type/' + type)
}

export const getProtocol = id => {
  setAuthHeaders()
  return api.get('/protocols/' + id)
}

export const getAllProtocols = () => {
  setAuthHeaders()
  return api.get('/protocols/?&sort=label&limit=100')
}

export const createProtocol = body => {
  setAuthHeaders()
  return api.post('/protocols/', body)
}
export const addDrillToProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put('/protocols/' + protocolId + '/add-drill', body)
}
export const removeDrillFromProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put('/protocols/' + protocolId + '/del-drill', body)
}

export const addWarmupDrillToProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put('/protocols/' + protocolId + '/add-warmupdrill', body)
}
export const removeWarmupDrillFromProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put('/protocols/' + protocolId + '/del-warmupdrill', body)
}

export const updateProtocol = (protocolId, body) => {
  setAuthHeaders()
  return api.put('/protocols/' + protocolId, body)
}

export const updateProtocolIsAvailable = (protocolId, statusString) => {
  const status = {
    isAvailable: statusString
  }
  setAuthHeaders()
  return api.put(`/protocols/${protocolId}/status/`, status)
}

export const logDrills = body => {
  setAuthHeaders()
  return api.post('/users/log-drills/', body)
  //return api.post('/drills-history/', body)
}
