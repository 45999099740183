import React, { Component, Fragment } from 'react'
import {
  Button,
  Card,
  List,
  Label,
  Icon,
  Progress,
  Container,
  Transition
} from 'semantic-ui-react'
import { withPolyglot } from 'polyglot-react'
import { Config } from '../config'
import ReactGA from 'react-ga'

import ReactPlayer from 'react-player'

ReactGA.initialize(Config.gaId)

class DrillVideo extends Component {
  // default State object
  constructor(props) {
    super(props)
    this.state = {
      showSetupDetails: false,
      playing: true,
      loading: true,
      showInstructions: false,
      showFocus: false
    }
  }

  toggleSetupDetailsVisibility = () => {
    this.setState({ showSetupDetails: !this.state.showSetupDetails })
  }

  togglePlay = () => {
    this.setState({ playing: !this.state.playing })
  }
  showInstructions = () => {
    this.setState({
      showFocus: false
    })
    this.setState({
      showInstructions: !this.state.showInstructions
    })
  }
  showFocus = () => {
    this.setState({
      showInstructions: false
    })
    this.setState({
      showFocus: !this.state.showFocus
    })
  }
  render() {
    const { drill } = this.props
    const { polyglot } = this.props
    const { leftIcon, rightIcon, doneIcon, trainingMode } = this.props
    const iconClassLeft = 'floated left color-grey top-10'
    const iconClassRight = 'floated right color-grey top-10'
    const iconDoneClassRight = 'floated right app-button green'
    const transparentClassLeft = 'floated left  top-10 transparent'
    const transparentClassRight = 'floated right  top-10 transparent'
    const showLeftIcon = leftIcon ? leftIcon.show : false
    const showRightIcon = rightIcon ? rightIcon.show : false
    const showDoneIcon = doneIcon ? doneIcon.show : false

    let progressPercent = 0
    if (this.props.drillsTotalCount) {
      progressPercent =
        ((parseInt(this.props.drillIndex) + 1) /
          parseInt(this.props.drillsTotalCount)) *
        100
    }

    const prevView = () => {
      if (leftIcon) {
        leftIcon.action()
      } else {
        return false
      }
    }
    const nextView = () => {
      if (rightIcon) {
        rightIcon.action()
      } else {
        return false
      }
    }
    const done = () => {
      if (doneIcon) {
        doneIcon.action()
      } else {
        return false
      }
    }

    return (
      <Fragment>
        <style>
          {`
       .overlay-container {
				position: relative;
				width: 100%;
		
			}
			
				.overlay {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: opacity 13.25s ease-in-out;
					-moz-transition: opacity 13.25s ease-in-out;
					-webkit-transition: opacity 13.25s ease-in-out;
					transition: .3s ease;
			
				}
				.overlay.off {
					opacity: 0;
				}

				.overlay.on {
					opacity: 0.9;	
				}	
				.icon-container {
					color: white;
				
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					text-align: center;
				
				}
				.icon-container .icon
				{
				/*	color:#db2828*/
				}

		
        `}
        </style>
        {drill.video && (
          /*
					<Embed
						autoplay={true}
						brandedUI
						hd={false}
						id={videoId}
						defaultActive={true}
						active={true}
						source="youtube"
						color="#000000"
					/>
					 */
          <Fragment>
            <div className="overlay-container">
              <ReactPlayer
                url={'https://stream.mux.com/' + drill.video + '.m3u8'}
                playing={
                  this.state.playing &&
                  this.props.drillIndex === this.props.activeIndex
                }
                onClick={this.togglePlay}
                controls={false}
                muted
                volume={0}
                playsinline
                loop={false}
                width="100%"
                height="100%"
                className="react-player"
                onBuffer={() => {}}
                onBufferEnd={() => {}}
                onStart={() => this.setState({ loading: false })}
                onEnded={() => {
                  this.setState({ playing: false })
                }}
                config={{
                  file: {
                    attributes: {
                      poster:
                        'https://image.mux.com/' +
                        drill.video +
                        '/thumbnail.jpg?time=0&width=700'
                    }
                  }
                }}
              />
              <div
                className={this.state.playing ? 'overlay off' : 'overlay on'}
                onClick={this.togglePlay}
              >
                <span href="#" className="icon-container">
                  <Icon name="play" size="big" />
                </span>
              </div>
              {this.state.loading && (
                <div className={' overlay ui active centered  loader'}></div>
              )}
            </div>
          </Fragment>
        )}
        <style>{`
					.drill-video {
						min-height:300px !important;
					}
							.drill-video .item .content .header{
							border-bottom : 1px solid #636363;
						}
						.drill-video  .item .content .description{
							padding-left:5px;
							color: rgba(255,255,255,.7)!important;
						}
						.ui.tiny.progress .bar {
							height: .2em;
						}
						.ui.progress {
							
							background: #bcbdbd4a;}
						
					.ui.container.repetitions{
							font-size:1.2em!important;
							 margin: 30px auto !important;
							 
					}
						`}</style>

        <Card fluid className="dark drill-video">
          {this.props.drillsTotalCount && (
            <Progress
              percent={progressPercent}
              size="tiny"
              style={{ width: '33%', margin: '0 auto' }}
            />
          )}
          <Card.Content>
            <Icon
              name="chevron left"
              size="large"
              className={showLeftIcon ? iconClassLeft : transparentClassLeft}
              onClick={showLeftIcon ? prevView : () => {}}
            />
            {showRightIcon && (
              <Icon
                name="chevron right"
                size="large"
                className={
                  showRightIcon ? iconClassRight : transparentClassRight
                }
                onClick={showRightIcon ? nextView : () => {}}
              />
            )}
            {showDoneIcon && (
              <Button
                circular
                icon="check"
                className={iconDoneClassRight}
                onClick={showDoneIcon ? done : () => {}}
              />
            )}
            <Card.Header textAlign="center">{drill.name}</Card.Header>
            {/*
            <Container className="repetitions top-30 bottom-30 ">
              {polyglot.t(drill.video + '.execution.2') && (
                <List.Item>
                  <List.Content>
                    {polyglot.t(drill.video + '.execution.2')}
                  </List.Content>
                </List.Item>
              )}

              {polyglot.t(drill.video + '.execution.3') && (
                <List.Item>
                  <List.Content>
                    {polyglot.t(drill.video + '.execution.3')}
                  </List.Content>
                </List.Item>
              )}
            </Container>
						*/}

            <Card.Description textAlign="left" className="left-10">
              {trainingMode && (
                <Fragment>
                  <Container
                    textAlign="center"
                    className="bottom-10 repetitions"
                  >
                    <List>
                      {polyglot.t(drill.video + '.repetitions.1') && (
                        <List.Item>
                          <List.Content>
                            <List.Icon
                              name="dot circle"
                              size="mini"
                              style={{ verticalAlign: 'middle' }}
                            />
                            {polyglot.t(drill.video + '.repetitions.1')}
                          </List.Content>
                        </List.Item>
                      )}
                      {polyglot.t(drill.video + '.repetitions.2') && (
                        <List.Item>
                          <List.Content>
                            <List.Icon
                              name="dot circle"
                              size="mini"
                              style={{ verticalAlign: 'middle' }}
                            />
                            {polyglot.t(drill.video + '.repetitions.2')}
                          </List.Content>
                        </List.Item>
                      )}
                      {polyglot.t(drill.video + '.repetitions.3') && (
                        <List.Item>
                          <List.Content>
                            <List.Icon
                              name="dot circle"
                              size="mini"
                              style={{ verticalAlign: 'middle' }}
                            />
                            {polyglot.t(drill.video + '.repetitions.3')}
                          </List.Content>
                        </List.Item>
                      )}
                    </List>
                  </Container>
                  <Container textAlign="center" className="top-30 bottom-10">
                    <Label
                      as="a"
                      style={{
                        backgroundImage:
                          'linear-gradient(to bottom, #ef0202 0%, #720000 100%)',
                        backgroundColor: 'transparent',
                        color: '#fff',
                        margin: '10px'
                      }}
                      onClick={this.showFocus}
                    >
                      <Icon name={'exclamation'} size={'large'} />
                      <span style={{ fontWeight: 400 }}>
                        {polyglot.t('drillVideo.focus')}
                      </span>
                    </Label>
                    <Label
                      as="a"
                      style={{
                        backgroundImage:
                          'linear-gradient(to bottom, #403e3e 0%, #0e0e0e 100%)',
                        backgroundColor: 'transparent',
                        color: '#fff',
                        margin: '10px'
                      }}
                      onClick={this.showInstructions}
                    >
                      <Icon name={'list ul'} size="big" inverted />
                      <span style={{ fontWeight: 400 }}>
                        {polyglot.t('drillVideo.instructions')}
                      </span>
                    </Label>
                  </Container>
                </Fragment>
              )}
              <List>
                <Transition
                  visible={!trainingMode || this.state.showInstructions}
                  animation="fade down"
                  duration={{ hide: 0, show: 200 }}
                >
                  <Container>
                    <List.Item>
                      <List.Content>
                        <List.Header
                          className="bottom-5 no-capitalize"
                          style={{
                            fontSize: '1.0em'
                          }}
                        >
                          {polyglot.t('drillVideo.setup')}
                        </List.Header>
                        <List.Description>
                          {polyglot.t(drill.video + '.setup') && (
                            <span
                              onClick={this.toggleSetupDetailsVisibility}
                              style={{ marginTop: '2px', marginLeft: '25px' }}
                            >
                              {polyglot.t(drill.video + '.setup')}
                              {polyglot.t(drill.video + '.setup.1') && (
                                <Icon
                                  name={
                                    this.state.showSetupDetails
                                      ? 'caret down'
                                      : 'caret right'
                                  }
                                  className="color-grey"
                                  size="large"
                                  style={{ marginLeft: '10px' }}
                                />
                              )}
                            </span>
                          )}

                          {this.state.showSetupDetails &&
                            polyglot.t(drill.video + '.setup.1') && (
                              <List>
                                {polyglot.t(drill.video + '.setup.1') && (
                                  <List.Item>
                                    <List.Icon name="dot circle outline" />
                                    <List.Content>
                                      {polyglot.t(drill.video + '.setup.1')}
                                    </List.Content>
                                  </List.Item>
                                )}
                                {polyglot.t(drill.video + '.setup.2') && (
                                  <List.Item>
                                    <List.Icon name="dot circle outline" />
                                    <List.Content>
                                      {polyglot.t(drill.video + '.setup.2')}
                                    </List.Content>
                                  </List.Item>
                                )}

                                {polyglot.t(drill.video + '.setup.3') && (
                                  <List.Item>
                                    <List.Icon name="dot circle outline" />
                                    <List.Content>
                                      {polyglot.t(drill.video + '.setup.3')}
                                    </List.Content>
                                  </List.Item>
                                )}
                              </List>
                            )}
                        </List.Description>
                      </List.Content>
                    </List.Item>

                    <List.Item>
                      <List.Content>
                        <List.Header
                          className="bottom-5 no-capitalize"
                          style={{
                            fontSize: '1.0em'
                          }}
                        >
                          {polyglot.t('drillVideo.execution')}
                        </List.Header>
                        <List.Description>
                          <List>
                            {polyglot.t(drill.video + '.execution.1') && (
                              <List.Item>
                                <List.Icon name="dot circle outline" />
                                <List.Content>
                                  {polyglot.t(drill.video + '.execution.1')}
                                </List.Content>
                              </List.Item>
                            )}
                            {polyglot.t(drill.video + '.execution.2') && (
                              <List.Item>
                                <List.Icon name="dot circle outline" />
                                <List.Content>
                                  {polyglot.t(drill.video + '.execution.2')}
                                </List.Content>
                              </List.Item>
                            )}

                            {polyglot.t(drill.video + '.execution.3') && (
                              <List.Item>
                                <List.Icon name="dot circle outline" />
                                <List.Content>
                                  {polyglot.t(drill.video + '.execution.3')}
                                </List.Content>
                              </List.Item>
                            )}
                          </List>
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  </Container>
                </Transition>
                <Transition
                  visible={!trainingMode || this.state.showFocus}
                  animation="fade down"
                  duration={{ hide: 0, show: 200 }}
                >
                  <Container>
                    <List.Item>
                      <List.Content>
                        <List.Header
                          className="bottom-5 no-capitalize"
                          style={{
                            color: '#f53030',
                            fontSize: '1.em'
                          }}
                        >
                          {polyglot.t('drillVideo.focus')}
                        </List.Header>
                      </List.Content>
                      <List.Content>
                        <List.Description>
                          <List>
                            {polyglot.t(drill.video + '.focus.1') && (
                              <List.Item>
                                <List.Icon name="dot circle outline" />
                                <List.Content>
                                  {polyglot.t(drill.video + '.focus.1')}
                                </List.Content>
                              </List.Item>
                            )}
                            {polyglot.t(drill.video + '.focus.2') && (
                              <List.Item>
                                <List.Icon name="dot circle outline" />
                                <List.Content>
                                  {polyglot.t(drill.video + '.focus.2')}
                                </List.Content>
                              </List.Item>
                            )}

                            {polyglot.t(drill.video + '.focus.3') && (
                              <List.Item>
                                <List.Icon name="dot circle outline" />
                                <List.Content>
                                  {polyglot.t(drill.video + '.focus.3')}
                                </List.Content>
                              </List.Item>
                            )}
                          </List>
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  </Container>
                </Transition>
              </List>
            </Card.Description>
          </Card.Content>
          <Card.Content extra></Card.Content>
        </Card>
      </Fragment>
    )
  }
}

DrillVideo = withPolyglot()(DrillVideo)
export default DrillVideo
